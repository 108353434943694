import { useMemo } from "react";
import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";
import { GroupOption } from "../../../../components/Grouping/components/GroupOptionValue";
import { DataGroupsSources } from "../../../../components/Grouping/data";

function getDataOptions(data, key) {
  const results = { options: [], valueHash: {} };
  if (!data || !key || !data?.[key]) return results;
  results.valueHash = data[key];
  results.options = Object.keys(data[key]);

  return results;
}

export const useSelectOptionMenuOption = ({
  data,
  getOptionCheckedState,
  checkOptionMatchesSearchQuery,
  handleOnClickGroupValue,
  searchValue,
  optionFilter,
  isOpen,
  checkOptionIsDisabled,
}) => {
  const platformOptions = useMemo(() => {
    if (!isOpen)
      return {
        options: [],
        valueHash: {},
      };

    return getDataOptions(data, "platform");
  }, [data, isOpen]);

  const nodes = useMemo(() => {
    const nodes = [];
    const { options, valueHash } = platformOptions;
    options.forEach((option) => {
      const optionToStr = `${option}`;
      const optionValues = valueHash[optionToStr].values;
      const originationTypes = valueHash[optionToStr].types ?? [];
      const valueTypes = valueHash[optionToStr].value_types ?? [];
      const optionValueCount = optionValues.length;
      if (!optionValueCount) return;

      const label = `${optionToStr} (${optionValueCount})`;
      const searchOptionPayload = {
        value: optionToStr,
        originationTypes,
      };
      const matchesSearchQuery =
        checkOptionMatchesSearchQuery(searchOptionPayload);
      if (!matchesSearchQuery) {
        return;
      }
      const componentData = {
        label,
        value: optionToStr,
        source: DataGroupsSources.PLATFORM,
        valueTypes,
      };

      if (optionFilter && !optionFilter?.(componentData)) return;
      const id = createOptionId(componentData, "options");
      componentData.id = id;
      const checked = getOptionCheckedState(componentData);
      componentData.checked = checked;
      componentData.disabled = checkOptionIsDisabled?.(componentData) ?? false;

      nodes.push(
        <GroupOption
          key={id}
          data={componentData}
          handleOnClickGroupValue={handleOnClickGroupValue}
        />
      );
    });

    return nodes;
  }, [
    platformOptions,
    searchValue,
    data,
    handleOnClickGroupValue,
    getOptionCheckedState,
  ]);

  return nodes;
};
