import React from "react";
import { Box, Typography } from "@mui/material";
import IntegrationMethod from "../../../components/onboardingV2/awsIntegration/integrationMethod";
import oauthLogo from "../../../assets/images/oauth.svg";
import keyLogo from "../../../assets/images/key.svg";

export const JiraIntegrationMethodContent = ({ onSelect }) => {
  return (
    <Box sx={{ display: "flex", gap: "50px", paddingLeft: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography
          variant="body1"
          className="integrationMethodDesc"
          sx={{ mt: "21.5px" }}
        >
          In this step, we're requesting read-only access to your Jira account. 
          This means we'll be able to view your Jira data, but we won't be able to make any changes.
          We prioritize your privacy and security, so you can rest assured that your data will be handled safely and securely.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "21px",
          width: "1px",
          bgcolor: "#D9D9D9",
        }}
      />

      <Box className="integrationMethodWrapper">
        <IntegrationMethod
          logo={oauthLogo}
          name="OAuth"
          onClick={() => onSelect('oauth')}
        />
        <IntegrationMethod
          logo={keyLogo}
          name="API Key"
          onClick={() => onSelect('key')}
        />
      </Box>
    </Box>
  );
}; 