import { styled } from "@mui/material";

export const GraphTopMenusSection = styled("div")(({ loading }) => {
  const events = loading ? "none" : "all";
  const opacity = loading ? 0.5 : 1;

  return `
  position: absolute;
  top: 0;
  z-index: 2;
  margin: 12px 24px; 
  width: 100%;
  align-items: center;
  display: flex;
  column-gap: 16px;
  pointer-events: ${events};
  opacity: ${opacity};
  `;
});
