import React, { useEffect, useState } from "react";
import styles from "./GraphPage.module.css"; // Change the path according to your file structure
import { useAuth0 } from "@auth0/auth0-react";
import G6GraphComponent from "../../components/G6Graph";
import { Grid } from "@mui/material";
import SingleSelect from "../../components/G6Graph/SingleSelect";
import MapComponent from "../../components/G6Graph/MapComponent";
import { graphv2 } from "../../utils/api";
import { useLocation } from "react-router-dom";
import helperFunctions from "../../utils/helper";
import ButtonToggle from "../../components/G6Graph/ButtonToggle";
import { useHistory } from "react-router-dom";
import ButtonReset from "../../components/G6Graph/ButtonReset";
import { GraphTopMenusSection } from "./styles";
import { transformUrlFilterParams } from "./transformUrlFilterParams";
import { GraphGroupingTarget, graphLayoutOptions } from "./constants";
import { LoadingIndicator } from "./components/LoadingIndicator";
import ConditionComponent from "../../utils/ConditionComponent";
import { getDefaultGraphData } from "../../core/graph/defaultGrouping";
import { useAssetSchema } from "../../hooks/useAssetSchema";
import { useInitGraph } from "./hooks/useInitGraph";
import { Grouping } from "./components/GroupingView";
import { Filtering } from "./components/Filtering";
import { LoadingSection } from "../../components/LayoutFillers";

function GraphPage() {
  const { getAccessTokenSilently } = useAuth0();
  const { grouping: defaultGrouping } = getDefaultGraphData();
  const [data, setData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showAllEntities, setShowAllEntities] = useState(false);
  const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
  const [layoutType, setLayoutType] = useState("Combo");
  const [selectedLayout, setSelectedLayout] = useState("");
  const location = useLocation();
  const [resetTrigger, setResetTrigger] = useState(0);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [groupingTarget, setGroupingTarget] = useState(
    GraphGroupingTarget.VISIBLE_DATA
  );
  const [showRelatedNodes, setShowRelatedNodes] = useState(false);

  const [filters, setFilters] = useState([]);
  const [grouping, setGrouping] = useState(defaultGrouping);
  const { fetchAsync, data: assetSchema } = useAssetSchema();
  const actionsDisabled = !graphData || isLoadingGraph;

  // get params
  const params = new URLSearchParams(location.search);
  const param = params.get("bp");
  const urlFilterParams = params.get("filters");

  useEffect(() => {
    const transformedFilterParams = transformUrlFilterParams(urlFilterParams);
    setFilters(transformedFilterParams);
  }, [location]);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        setIsLoadingGraph(true);
        const auth0Token = await helperFunctions.getAuth0Token(
          getAccessTokenSilently
        );
        let [r, _] = await Promise.all([graphv2(auth0Token), fetchAsync()]);
        if (r) {
          if (param) {
            r = {
              nodes: r.nodes.filter(
                (node) =>
                  node.business_process &&
                  node.business_process
                    .toLocaleLowerCase()
                    .includes(param.toLocaleLowerCase())
              ),
              edges: r.edges,
            };
          } else {
            console.log("no param");
          }
          setIsLoadingGraph(false);
          setData(r);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchGraphData();
  }, [getAccessTokenSilently, resetTrigger]);
  useInitGraph({
    data,
    filters,
    grouping,
    groupingTarget,
    searchInput,
    setLayoutType,
    showAllEntities,
    showRelatedNodes,
    setGraphData,
  });

  useEffect(() => {
    let newLayoutType;
    if (grouping.length > 0) {
      newLayoutType = selectedLayout || "Combo";
    } else {
      newLayoutType = selectedLayout || "Default";
    }
    setLayoutType(newLayoutType);
  }, [grouping, selectedLayout]);

  const handleLayoutChange = (selected) => {
    setSelectedLayout(selected);
  };

  const handleReset = () => {
    setSearchInput("");
    setShowAllEntities(false);
    setGrouping([]);
    setSelectedLayout("Default");
    setLayoutType("Select Layout");
    setResetTrigger((prev) => prev + 1);
    setFilters([]);
  };

  const handleOnChangeGroupingTarget = (e) => {
    setGroupingTarget((p) => {
      if (p === GraphGroupingTarget.ALL)
        return GraphGroupingTarget.VISIBLE_DATA;
      return GraphGroupingTarget.ALL;
    });
  };

  const handleOnApplyFilters = (appliedFilters, showRelatedNodes) => {
    setFilters(appliedFilters);
    setShowRelatedNodes(showRelatedNodes);
    setShowAllEntities(true);
  };

  const handleOnChangeGrouping = (payload) => {
    setGrouping(payload);
  };

  const handleOnClearGrouping = () => {
    setGrouping([]);
  };

  const handleOnChangeRelatedNodes = () => {
    setShowRelatedNodes((p) => !p);
  };

  return (
    <div className={styles.graphPage} style={{ position: "relative" }}>
      <Grid
        container
        style={{ backgroundColor: "#F6F8FA", position: "relative" }}
      >
        <Grid item xs={12} style={{ height: "100vh" }}>
          <ConditionComponent
            condition={!isLoadingGraph && !!graphData}
            falsyNode={
              <div>
                <LoadingIndicator />
              </div>
            }
          >
            <G6GraphComponent
              key={layoutType}
              data={graphData}
              labelProp={"name"}
              layoutType={layoutType}
              plugins={[]}
              showSidebar={true}
              style={{ zIndex: 1, height: "100%" }}
            />
          </ConditionComponent>
          <GraphTopMenusSection loading={actionsDisabled}>
            <MapComponent
              numberOfServices={graphData ? graphData.nodes.length : 0}
            />
            <SingleSelect
              items={graphLayoutOptions}
              defaultSelected='Select Layout'
              onSelectedChange={handleLayoutChange}
            />
            <Grouping
              selectedValues={grouping}
              setSelectedValues={handleOnChangeGrouping}
              schema={assetSchema}
              onChangeTarget={handleOnChangeGroupingTarget}
              target={groupingTarget}
              onClearGrouping={handleOnClearGrouping}
              disabled={actionsDisabled}
            />
            <Filtering
              dataGroupOptions={assetSchema}
              filters={filters}
              onApplyFilters={handleOnApplyFilters}
              assetSchema={assetSchema}
              onChangeRelatedNodesVisibility={handleOnChangeRelatedNodes}
              showRelatedNodes={showRelatedNodes}
            />

            <ButtonToggle
              variant='contained'
              onClick={() => setShowAllEntities(!showAllEntities)}
              label={
                showAllEntities
                  ? "Hide Unrelated Entities"
                  : "Show All Entities"
              }
            />
            <ButtonReset onClick={handleReset} />
          </GraphTopMenusSection>
        </Grid>
      </Grid>
    </div>
  );
  // if (isLoadingGraph) {
  //   return <LoadingIndicator />;
  // }

  // return (
  // <div className={styles.graphPage} style={{ position: "relative" }}>
  //   <ConditionComponent condition={!!graphData}>
  //     <Grid
  //       container
  //       style={{ backgroundColor: "#F6F8FA", position: "relative" }}
  //     >
  //       <Grid item xs={12} style={{ height: "100vh" }}>
  // <G6GraphComponent
  //   key={layoutType}
  //   data={graphData}
  //   labelProp={"name"}
  //   layoutType={layoutType}
  //   plugins={[]}
  //   showSidebar={true}
  //   style={{ zIndex: 1, height: "100%" }}
  // />
  // <GraphTopMenusSection>
  //   <MapComponent
  //     numberOfServices={graphData ? graphData.nodes.length : 0}
  //   />
  //   <SingleSelect
  //     items={graphLayoutOptions}
  //     defaultSelected='Select Layout'
  //     onSelectedChange={handleLayoutChange}
  //   />
  //   <Grouping
  //     selectedValues={grouping}
  //     setSelectedValues={handleOnChangeGrouping}
  //     schema={assetSchema}
  //     onChangeTarget={handleOnChangeGroupingTarget}
  //     target={groupingTarget}
  //     onClearGrouping={handleOnClearGrouping}
  //   />
  //   <Filtering
  //     dataGroupOptions={assetSchema}
  //     filters={filters}
  //     onApplyFilters={handleOnApplyFilters}
  //     assetSchema={assetSchema}
  //     onChangeRelatedNodesVisibility={handleOnChangeRelatedNodes}
  //     showRelatedNodes={showRelatedNodes}
  //   />

  //   <ButtonToggle
  //     variant='contained'
  //     onClick={() => setShowAllEntities(!showAllEntities)}
  //     label={
  //       showAllEntities
  //         ? "Hide Unrelated Entities"
  //         : "Show All Entities"
  //     }
  //   />
  //   <ButtonReset onClick={handleReset} />
  // </GraphTopMenusSection>
  //       </Grid>
  //     </Grid>
  //   </ConditionComponent>
  // </div>
  // );
}

export default GraphPage;
