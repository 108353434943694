import React from "react";
import { Box, InputBase, Typography } from "@mui/material";
import styles from "../Jira.module.css";
import jiraLogo from "../../../assets/images/jira.svg";
import { muiStyles } from "./styles";
import { IntegrationLabel } from "../../../components/integrations-components/styled";
import { useIntegrationValueMutators } from "../hooks/useIntegrationValueMutators";
import { useIntegrationValues } from "../hooks/useIntegrationValues";

export const JiraIntegrationContent = () => {
  const { setEmail, setIntegrationName, setApiToken, setDomainUrl } =
    useIntegrationValueMutators();
  const { email, integrationName } = useIntegrationValues();

  const onChangeEmail = (ev) => {
    setEmail(ev.target.value);
  };

  const onChangeIntegrationName = (ev) => {
    setIntegrationName(ev.target.value);
  };

  const onChangeDomainUrl = (ev) => {
    setDomainUrl(ev.target.value);
  };
  const onChangeApiToken = (ev) => {
    setApiToken(ev.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "24px" }}>
      <Box>
        <Typography variant='body1'>
          To create a read-only access token in Jira, follow these steps:
        </Typography>
        <ol>
          <li className={styles["list-item"]}>
            Log into your <strong>Jira account</strong>.
          </li>
          <li className={styles["list-item"]}>
            Navigate to <strong> Security</strong> and then to{" "}
            <strong>API tokens</strong> section Or{" "}
            <a
              href='https://id.atlassian.com/manage-profile/security/api-tokens'
              target='_blank'
              rel='noopener noreferrer'
            >
              Atlassian account management
            </a>
            .
          </li>
          <li className={styles["list-item"]}>
            Click on <strong>'Create API token'</strong>.
          </li>
          <li className={styles["list-item"]}>
            Give your token a <strong>label</strong> for easy identification,
            such as <strong>"Read-Only Access for Pando Security".</strong>
          </li>
          <li className={styles["list-item"]}>
            Click <strong>'Create'</strong> to generate the token.
          </li>
          <li className={styles["list-item"]}>
            Once the token is generated, click{" "}
            <strong>'Copy to clipboard'</strong> to save your token. Make sure
            to store it securely as it won't be shown again.
          </li>
          <li className={styles["list-item"]}>
            Paste this token into the appropriate field in Jira to establish the
            integration.
          </li>
        </ol>
      </Box>
      <Box sx={muiStyles.inputsSection}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IntegrationLabel>Integration Name *</IntegrationLabel>
          <InputBase
            value={integrationName}
            onChange={onChangeIntegrationName}
            placeholder=''
            readOnly={false}
            classes={{ root: styles.jiraInput }}
            sx={muiStyles.input}
          />
        </Box>
      </Box>
      <Box sx={muiStyles.inputsSection}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IntegrationLabel>API Token *</IntegrationLabel>
          <InputBase
            id='apiToken'
            value={null}
            onChange={onChangeApiToken}
            placeholder=''
            readOnly={null}
            classes={{ root: styles.jiraInput }}
            sx={muiStyles.input}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IntegrationLabel>Domain URL</IntegrationLabel>

          <InputBase
            id='url'
            label='url'
            value={null}
            onChange={onChangeDomainUrl}
            placeholder='https://jira.domain'
            readOnly={null}
            classes={{ root: styles.jiraInput }}
            sx={muiStyles.input}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IntegrationLabel>Email</IntegrationLabel>
          <InputBase
            id='email'
            label='email'
            value={email}
            onChange={onChangeEmail}
            placeholder='example@domain.com'
            classes={{ root: styles.jiraInput }}
            sx={muiStyles.input}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const JiraIntegrationConfig = {
  logo: jiraLogo,
  headerTitle: "Setup Jira Integration",
};
