import { capitalize, Tooltip } from "@mui/material";
import {
  CellContainer,
  HeaderCellContainer,
  TruncatedCell,
} from "../../../../containers/inventory/AssetsTable/styled";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { StringUtils } from "../../../../utils/typeUtils/StringUtils";
import { TableCellTooltipContent } from "../../../../containers/inventory/AssetsTable/components/TableCellTooltip";
import { DataCount } from "../../../../components/DataCount";
import { JSONUtils } from "../../../../utils/typeUtils/JSONUtils";
import { isPrimitiveValue } from "../../../../utils/helperMethods/type-guards";
import { CoreDataTypes } from "../../../../core/data-types";
import { TypeAdapter } from "../../../../utils/typeUtils/type-adapter";
import { shortenColumnName } from "../../../../app/asset-management/shortenColumnName";

function getValueByPath(obj, path) {
  const pathArray = Array.isArray(path) ? path : path.split(".");

  return pathArray.reduce((current, key, arr) => {
    if (!current) return null;
    if (Array.isArray(current)) {
      return current.map((data) => data?.[key]);
    }
    if (typeof current === "object") {
      return current?.[key] ?? null;
    }
    return null;
  }, obj);
}

const ROOT_FIELD_PREFIX = "data.project.";

const checkIsRootField = (key) => {
  return key.startsWith(ROOT_FIELD_PREFIX);
};

const createNestedFieldHeader = (value) => {
  const prefix = value.split("data.");
  return StringUtils.capitalize(prefix?.[1] ?? "");
};

function createHeaderLabel(col) {
  const isRootField = checkIsRootField(col.value);
  if (isRootField) {
    const root = col.value.split(".")?.[2] ?? "";
    return root.toUpperCase();
  }

  return createNestedFieldHeader(col.value);
}

function createAccessorKey(col) {
  return "data";
}

function sanitizeFieldValues(values) {
  if (Array.isArray(values)) {
    return values.reduce((results, value) => {
      const valueDoesNotExist =
        value === null ||
        value === undefined ||
        value?.toLowerCase?.() === "none";
      if (valueDoesNotExist) return results;
      if (typeof value === "string") {
        results.push(value);
      } else {
        const valueAsStr = JSONUtils.stringify(value);
        results.push(valueAsStr);
      }
      return results;
    }, []);
  }

  if (!values) return [];
  if (isPrimitiveValue(values)) return [StringUtils.castToString(values)];
  return [JSONUtils.stringify(values)];
}

function getValues(cellValue, path) {
  const values = getValueByPath(cellValue, path);
  if (values) return sanitizeFieldValues(values);
  return [];
}

function createCell(col) {
  return function ({ cell }) {
    const cellValue = cell.getValue();
    const path = col.value.split(".").slice(1);
    const values = getValues(cellValue, path);
    const hasMultipleValues = values.length > 1;
    const remainder = values.length - 1;
    const isNumber = col.valueTypes?.some(
      (type) => TypeAdapter.castToJsType(type) === "number"
    );
    const emptyValue = isNumber ? 0 : CoreDataTypes.N_A;
    const displayLabel = values[0] ?? emptyValue;

    return (
      <CellContainer>
        <TruncatedCell>{displayLabel}</TruncatedCell>
        <ConditionComponent condition={hasMultipleValues}>
          <Tooltip
            placement='top'
            title={<TableCellTooltipContent data={values} />}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <div>
              <DataCount data={{ count: remainder }} />
            </div>
          </Tooltip>
        </ConditionComponent>
      </CellContainer>
    );
  };
}

function createColumnHeader(column) {
  return () => {
    const label = createHeaderLabel(column);
    const displayName = shortenColumnName(label);
    const fullLabel = capitalize(label);
    return (
      <HeaderCellContainer>
        <Tooltip
          placement='top'
          title={fullLabel}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
        >
          <div>
            <TruncatedCell>{displayName}</TruncatedCell>
          </div>
        </Tooltip>
      </HeaderCellContainer>
    );
  };
}

function columnTransformer(col) {
  const accessorKey = createAccessorKey(col) ?? ""; // @Todo think of a fallback mechanism
  const Cell = createCell(col);
  const Header = createColumnHeader(col);

  return {
    accessorKey,
    Header,
    Cell,
    id: col.id,
  };
}

export function createTableColumn(columns) {
  return columns.map(columnTransformer);
}
