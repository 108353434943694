export const Actions = Object.freeze({
  SET_SEARCH_INPUT: "set-search-input",
  SET_COLUMNS: "set-columns",
  SET_GROUPING: "set-grouping",
  SET_FILTERS: "set-filters",
  SET_GROUPING_OPTION: "set-grouping-option",
  RESET_GROUPING_TABLE: "reset-grouping-table",
  INIT_TABLE_STATES: "init-table-states",
  SET_PRIMARY_TABLE_PAGINATION: "set-primary-table-pagination",
  SET_GROUPING_TABLE_PAGINATION: "set-grouping-table-pagination",
  FETCH_PRIMARY_TABLE_DATA_PENDING: "primary-table-data-pending",
  FETCH_PRIMARY_TABLE_DATA_SUCCESS: "primary-table-data-success",
  FETCH_PRIMARY_TABLE_DATA_ERROR: "primary-table-data-error",
  FETCH_GROUPING_TABLE_DATA_PENDING: "grouping-table-data-pending",
  FETCH_GROUPING_TABLE_DATA_SUCCESS: "grouping-table-data-success",
  FETCH_GROUPING_TABLE_DATA_ERROR: "grouping-table-data-error",

  SHOW_CREATE_QUERY_VIEW: "show-create-query-view",
  CLOSE_CREATE_QUERY_VIEW: "close-create-query-view",
  CREATE_QUERY_PENDING: "create-query-pending",
  CREATE_QUERY_SUCCESS: "create-query-success",
  CREATE_QUERY_ERROR: "create-query-error",

  SET_SAVE_QUERY_PAYLOAD: "set-save-query-payload",
});
