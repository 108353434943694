import { useEffect, useReducer } from "react";
import { Reducer } from "../stateManagement/reducer";
import { InitialState } from "../stateManagement/initialState";
import {
  CMSTControllerContext,
  CMSTStateDispatchContext,
  ProjectsTableContext,
} from "./defs";
import { Actions } from "../stateManagement/actions";
import { CMSTService } from "../../../../services/CMSTService";
import { useInitPage } from "../hooks/useInitPage";

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const getProjects = async () => {
    try {
      dispatch({
        type: Actions.FETCH_PROJECTS_TABLE,
        payload: {},
      });
      const data = await CMSTService.getInstance().getProjects();
      dispatch({
        type: Actions.FETCH_PROJECTS_TABLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: Actions.FETCH_PROJECTS_TABLE_FAILED,
        payload: error,
      });
    }
  };

  useInitPage();

  return (
    <CMSTStateDispatchContext.Provider value={dispatch}>
      <CMSTControllerContext.Provider value={{ getProjects }}>
        <ProjectsTableContext.Provider value={state.projectsTableState}>
          {children}
        </ProjectsTableContext.Provider>
      </CMSTControllerContext.Provider>
    </CMSTStateDispatchContext.Provider>
  );
};
