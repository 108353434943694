export const CMSTIssuesPageInitialState = {
  schema: {},
  columns: [],
  filters: [],
  saveQueryPayload: null,
  isLoading: false,
  paginationConfig: {
    pageIndex: 0,
    pageSize: 20,
  },
  data: null,
  meta: {},
  isError: false,
  errorPayload: null,
  summary: null,
};
