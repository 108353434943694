export const InitialState = {
  projectsSummaryState: {
    isLoading: false,
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
    lastFetched: 0,
    apiConfig: {
      key: "",
      recentFetchTime: 0,
    },
  },
  projectsTableState: {
    options: {},
    columns: [],
    filters: [],
    isLoading: false,
    paginationConfig: {
      pageIndex: 0,
      pageSize: 20,
    },
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
    apiConfig: {
      key: "",
      recentFetchTime: 0,
    },
  },
  projectIssues: {
    isLoading: false,
    error: false,
    errorPayload: null,
    data: {},
  },
  issuesSummaryState: {
    isLoading: false,
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
  },

  allIssuesSummaryState: {
    isLoading: false,
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
  },
  allIssuesTableState: {
    options: {},
    columns: [],
    filters: [],
    saveQueryPayload: null,
    isLoading: false,
    paginationConfig: {
      pageIndex: 0,
      pageSize: 20,
    },
    data: [],
    meta: {},
    error: false,
    errorPayload: null,
  },

  issuesTableState: {
    options: {},
    columns: [],
    filters: [],
    isLoading: false,
    paginationConfig: {
      pageIndex: 0,
      pageSize: 20,
    },
    data: [],
    meta: {},
    error: false,
    errorPayload: null,
  },
  issueDetailState: {
    isLoading: false,
    data: {},
    meta: {},
    error: false,
    errorPayload: null,
  },
};
