export const SystemMode = {
  DEBUG: "debug",
  PROD: "production",
} as const;
export class SystemInsights {
  private static _mode: TypeofObjectValues<typeof SystemMode> = SystemMode.PROD;

  static log(...args: any[]) {
    if (this._mode === SystemMode.DEBUG) {
      this.show(...args);
    }
  }

  private static show(...args: any[]) {
    const now = new Date().getDate();
    console.log(`[GIST Web - ${now} --- `, ...args);
  }
}

/*
add only flag to only show logs for a specific insight block
*/
