import { createSlice } from "@reduxjs/toolkit";
import { CMSTIssuesPageInitialState } from "./initialPage";
import { getCMSTIssuesPageData } from "./thunks/getCMSTIssuesPageData";

const slice = createSlice({
  name: "cmst-issues",
  initialState: CMSTIssuesPageInitialState,
  reducers: {
    updateSaveQueryPayload(state, action) {
      state.saveQueryPayload = { ...state.saveQueryPayload, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(getCMSTIssuesPageData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.data = null;
      state.isError = false;
      state.errorPayload = null;
      state.saveQueryPayload = null;
    });

    builder.addCase(getCMSTIssuesPageData.fulfilled, (state, { payload }) => {
      const { schema, summary, saveQueryPayload } = payload;
      state.schema = schema;
      state.summary = summary;
      state.saveQueryPayload = saveQueryPayload;
      state.meta = payload.meta;
      state.isLoading = false;
      state.error = false;
      state.errorPayload = null;
    });

    builder.addCase(getCMSTIssuesPageData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.data = null;
      state.isError = true;
      state.errorPayload = payload;
    });
  },
});
const reducer = slice.reducer;

const CMSTIssuesPageActions = slice.actions;

export { reducer, CMSTIssuesPageActions };
