import { styled, Typography } from "@mui/material";
import { TruncatedCell } from "../../styled";

export const Container = styled("div")`
  height: calc(100vh - 150px);
  display: grid;
  grid-template-columns: 320px 1fr;
  column-gap: 12px;
  overflow-y: hidden;
`;

const SectionBase = styled("section")`
  border: 0.5px solid #c7c7c7;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 8px;
`;

export const GroupingSectionContainer = styled(SectionBase)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const SectionHeading = styled((props) => (
  <TruncatedCell clamp={1} {...props} />
))`
  height: 32px;
  border-bottom: 0.5px solid #c7c7c7;
  background-color: inherit;
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-weight: 500;
  overflow: hidden;
`;

export const DataTableSectionContent = styled("div")``;

export const GroupsSectionContent = styled(DataTableSectionContent)`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
  overflow-y: auto;
`;

export const GroupDataTableSectionContainer = styled(SectionBase)`
  background-color: #f3f6fd;
`;

export const GroupingCardSummaryContent = styled("div")(({ selected }) => {
  const bg = selected ? "#002733" : "#f3f6fd";
  const color = selected ? "#00FFB2" : "#000";
  return `
  display: flex;
  flex-direction: column;
  background-color: ${bg};
  color: ${color};
  padding: 8px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid transparent;

  :hover {
  border: 1px solid #cccccc70;
  }
`;
});

export const SummaryText = styled((props) => <div {...props} />)`
  && {
    flex: 1;
    word-break: break-all;
  }
`;

export const TenantAnalysisDivider = styled("div")`
  width: 1px;
  height: 100%;
  background-color: #cccccc70;
`;
export const AssetTenantContainer = styled("div")(({ selected }) => {
  return `
  display: flex;
  height: 30px;
  column-gap: 4px;
  margin-left: auto;
  width: fit-content;
`;
});

export const TenantAnalysisContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TenantAnalysisLabel = styled((props) => (
  <Typography variant='caption' {...props} />
))(({ selected }) => {
  const color = selected ? "#fff" : "#000";

  return `
  && {
    font-size: 10px;
    color: ${color} !important;
  }
`;
});

export const TenantAnalysisValue = styled((props) => (
  <Typography variant='body1' {...props} />
))(({ selected }) => {
  const color = selected ? "#fff" : "#000";
  return `
  && {
    font-size: 15px;
    color: ${color} !important;
    font-weight: 600;
  }
`;
});
