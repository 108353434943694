import { useMemo } from "react";
import { FilterGroup } from "../components/FilterGoup";
import { useFilterOperators } from "./useFilterOperators";
import { getOperatorsByValueType } from "../../../core/data-filtering/getOperatorsByValueType";

export const useFilterGroupsNodes = ({
  data,
  conjunctions,
  onDeleteFilterGroups,
  updateFilterGroup,
}) => {
  const operators = useFilterOperators();
  return useMemo(() => {
    return data.map((filterGroup, index) => {
      const withConjunction = index > 0;
      const operatorOptions = getOperatorOptions(filterGroup, operators);

      return (
        <FilterGroup
          key={filterGroup.id}
          withConjunction={withConjunction}
          data={filterGroup}
          operatorOptions={operatorOptions}
          conjunctionOptions={conjunctions}
          onDeleteFilterGroup={onDeleteFilterGroups}
          updateFilterGroup={updateFilterGroup}
        />
      );
    });
  }, [data, operators, conjunctions]);
};

function getOperatorOptions(group, operators) {
  if (group.field.value) {
    return getOperatorsByValueType(group.field.valueTypes);
  }
  return [];
}
