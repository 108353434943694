import { useMemo, useState } from "react";
import { OverviewLabel } from "../styled";
import { NewTask } from "./NewTask";
import {
  AddTaskButton,
  SecurityTasksListRoot,
  SecurityTasksRoot,
  TasksNewActionRoot,
} from "./styled";
import { Task } from "./Task";
import { SecurityTaskStatus } from "../../../../../../core/cmst/issues-page/data";
import AddIcon from "@mui/icons-material/Add";
import ConditionComponent from "../../../../../../utils/ConditionComponent";

export const SecurityTasks = ({ data = [] }) => {
  const [tasks, setTasks] = useState(() => {
    return Array.isArray(data) ? data : [];
  });
  const [showNewTask, setShowNewTask] = useState(false);

  const tasksNodes = useMemo(() => {
    return tasks.map((task) => <Task data={createTaskDataObject(task)} />);
  }, [tasks]);

  const handleOnClickNewTask = () => {
    if (!showNewTask) setShowNewTask(true);
  };

  const onSubmitNewTask = (task) => {
    setShowNewTask(false);
  };

  return (
    <SecurityTasksRoot>
      <OverviewLabel>Security Tasks</OverviewLabel>
      <SecurityTasksListRoot className='scrollable'>
        {tasksNodes}
      </SecurityTasksListRoot>

      <TasksNewActionRoot>
        <ConditionComponent condition={showNewTask}>
          <NewTask onSubmit={onSubmitNewTask} />
        </ConditionComponent>
        <AddTaskButton disabled onClick={handleOnClickNewTask}>
          <AddIcon />
          New Task
        </AddTaskButton>
      </TasksNewActionRoot>
    </SecurityTasksRoot>
  );
};

function createTaskDataObject(data) {
  const DO = {
    label: data.task,
    team: data.name_of_team,
    status: data.status
      ? SecurityTaskStatus.COMPLETED
      : SecurityTaskStatus.INCOMPLETE,
  };
  return DO;
}
