import { useHistory } from "react-router-dom";
import awsLogo from "../../../../../assets/images/AWSDark.svg";
import bitbucketLogo from "../../../../../assets/images/bitbucket.svg";
import jiraLogo from "../../../../../assets/images/jira.svg";
import azureLogo from "../../../../../assets/images/Azure.svg";
import wizLogo from "../../../../../assets/images/Wiz.png";
import hibobLogo from "../../../../../assets/images/hibob.jpg";
import githubLogo from "../../../../../assets/images/githubDark.svg";
import oktaLogo from "../../../../../assets/images/oktaDark.svg";
import gcpLogo from "../../../../../assets/images/gcp.svg";
import snykLogo from "../../../../../assets/images/snyk.svg";

export const useIntegrationsDataSources = () => {
  const history = useHistory();

  const data = [
    {
      id: "aws",
      name: "AWS",
      logo: awsLogo,
      onClick: () => history.push("/integrations/aws"),
    },
    {
      id: "github",
      name: "GitHub",
      logo: githubLogo,
      onClick: () => history.push("/integrations/github"),
    },
    {
      id: "bitbucket",
      name: "Bitbucket",
      logo: bitbucketLogo,
      onClick: () => history.push("/integrations/bitbucket"),
    },
    {
      id: "jira",
      name: "Jira",
      logo: jiraLogo,
      onClick: () => history.push("/integrations/jira"),
    },
    {
      id: "wiz",
      name: "Wiz",
      logo: wizLogo,
      onClick: () => history.push("/integrations/wiz"),
    },
    {
      id: "hibob",
      name: "Hibob",
      logo: hibobLogo,
      onClick: () => history.push("/integrations/hibob"),
    },
    {
      id: "gsuite",
      name: "Google Workspaces",
      logo: gcpLogo,
      onClick: () => history.push("/integrations/gsuite"),
    },
    {
      id: "azure",
      name: "Azure",
      logo: azureLogo,
      isComingSoon: true,
    },

    {
      id: "okta",
      name: "Okta",
      logo: oktaLogo,
      isComingSoon: true,
    },
    {
      id: "googlecloud",
      name: "Google Cloud",
      logo: gcpLogo,
      isComingSoon: true,
    },
    {
      id: "snyk",
      name: "Snyk",
      logo: snykLogo,
      isComingSoon: true,
    },
  ];

  return data;
};
