import { SelectColumns } from "../../../../containers/inventory/AssetsTable/components/SelectColumns";
import { Footer } from "./Footer";
import LayersIcon from "@mui/icons-material/Layers";

export const Grouping = (props) => {
  return (
    <SelectColumns
      title={"Grouping"}
      tooltipProps={{ title: "Apply Grouping", show: true }}
      viewConfig={{
        showCurrentGroupings: true,
        showFooter: true,
      }}
      buttonProps={{
        icon: <LayersIcon />,
      }}
      components={{
        Footer: (
          <Footer
            target={props.target}
            onClear={props.onClearGrouping}
            onChangeTarget={props.onChangeTarget}
          />
        ),
      }}
      optionFilter={undefined}
      {...props}
    />
  );
};
