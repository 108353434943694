import { styled } from "@mui/system";
import { PageLayout } from "../../ui/shared-component/PageLayout";

export const Root = styled(PageLayout)(
  () => `
`
);

export const IntegrationCardContentRoot = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  min-width: 600px;
  width: 100%;
  background-color: gray;
  border: double 1px transparent;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(white, white),
    linear-gradient(180deg, #03ffb2, #ff7596);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  `;
});

export const IntegrationsContainer = styled("div")(() => {
  return `
  display: flex;
  flex: 1;
  height: 100%; 
  width: 100%;
  `;
});

export const StepsRoot = styled("div")(() => {
  return `
  
  `;
});

export const ContentRoot = styled("div")(() => {
  return `
  height: 100px;
  width: 100%;
  padding: 24px;
  `;
});

export const IntegrationHeaderRoot = styled("header")(() => {
  return `
height: 78px;
display: flex;
 justify-content: flex-start;
  border-bottom: 1px solid #eae9e9;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  column-gap: 24px;
  `;
});

export const IntegrationFooterRoot = styled("div")(() => {
  return `
height: 56px;
display: flex;
  justify-content: flex-end; /* Aligns items to the far ends */
  align-items: center;
  gap: 8.88px;
  border-top: 1px solid rgba(48, 32, 32, 0.1) !important;
  box-sizing: border-box;
  `;
});

export const IntegrationBodyRoot = styled("div")(() => {
  return `
  flex: 1;
  `;
});
