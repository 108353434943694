import { styled, Typography } from "@mui/material";

export const EmptyTableRoot = styled("div")(() => {
  return `
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  `;
});

export const ErrorLabel = styled((props) => (
  <Typography variant='body1' color='error' {...props} />
))(() => {
  return `
  
  `;
});

export const EmptyDataLabel = styled((props) => (
  <Typography variant='body2' {...props} />
))(() => {
  return `
  color: gray;
  `;
});
