import { useState } from "react";
import {
  IntegrationValueMutatorsContext,
  IntegrationValuesStateContext,
} from "./contexts";
import { generateUid } from "../stepOne/generateUID";

export const IntegrationsProvider = ({ children, manual }) => {
  const [account, setAccount] = useState("");
  const [integrationName, setIntegrationName] = useState("");
  const [role, setRole] = useState("");
  const [externalId, setExternalId] = useState(() => generateUid());
  const [selfRole, setSelfRole] = useState("");
  const [arnRoles, setArnRoles] = useState([]);
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [regions, setRegions] = useState([]);

  return (
    <IntegrationValueMutatorsContext.Provider
      value={{
        setAccount,
        setIntegrationName,
        setRole,
        setExternalId,
        setSelfRole,
        setArnRoles,
        setAccessKeyId,
        setSecretAccessKey,
        setRegions,
      }}
    >
      <IntegrationValuesStateContext.Provider
        value={{
          account,
          integrationName,
          role,
          externalId,
          selfRole,
          isManual: manual,
          arnRoles,
          accessKeyId,
          secretAccessKey,
          regions,
        }}
      >
        {children}
      </IntegrationValuesStateContext.Provider>
    </IntegrationValueMutatorsContext.Provider>
  );
};
