import { Fragment, useMemo, useRef, useState } from "react";
import { checkValueExists } from "../../../../utils/helperMethods/type-guards";
import {
  EmptyValueOperatorsMap,
  MultipleValuesFilterOperators,
} from "../../../../core/asset-inventory/filterOperators";
import {
  DeleteButtonContainer,
  FieldSelectorContainer,
  FilterActionButtonsRoot,
  FilterActionsContainer,
  FilterGroupConjunctionContainer,
  FilterGroupContainer,
  FilterGroupContentContainer,
  OperatorSelectorContainer,
  SelectorLabel,
  SelectorSection,
  ValueSelectorContainer,
  WarningContainer,
} from "../../../../components/Filtering/styled";
import ConditionComponent from "../../../../utils/ConditionComponent";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { SelectOptionsMenu } from "../../SelectOptionsMenu";
import { SelectorButtonRef } from "../refs";
import { SelectFieldAsColumn } from "../../../../components/Filtering/components/SelectFieldAsColumn";
import { matchSorter } from "match-sorter";

const createValueOptions = (selectedField, dataGroupOptions) => {
  const { value: field, source } = selectedField;
  const fieldValues = dataGroupOptions[source]?.[field].values ?? [];
  const fieldValueOptions = [];
  fieldValues.forEach((option) => {
    if (!checkValueExists(option)) return;
    const value = String(option);
    const fieldValueOptionPayload = {
      value,
      label: value,
      source,
      parentPayload: {
        field: value,
        source,
      },
    };
    fieldValueOptions.push(fieldValueOptionPayload);
  });
  return fieldValueOptions;
};

export const FilterGroup = ({
  withConjunction,
  data: filterGroup,
  operatorOptions = [],
  conjunctionOptions = [],
  onDeleteFilterGroup,
  updateFilterGroup,
  schema,
  getOperatorOptionsByFilterGroup,
}) => {
  const [fieldOptionsMenuOpen, setFieldOptionsMenuOpen] = useState(false);
  const menuTwoWayBindingRef = useRef();

  const [valueOptionsInputKeyword, setValueOptionsSearchKeyword] = useState("");

  const valueOptions = useMemo(() => {
    if (!filterGroup.field) return [];
    return createValueOptions(filterGroup.field, schema);
  }, [schema, filterGroup]);

  const sortedValueOptions = useMemo(() => {
    const input = valueOptionsInputKeyword ?? "";
    if (valueOptions.length) {
      return matchSorter(valueOptions, input, { keys: ["value"] });
    }
    return [];
  }, [valueOptions, valueOptionsInputKeyword]);

  const operators = useMemo(() => {
    if (getOperatorOptionsByFilterGroup) {
      return getOperatorOptionsByFilterGroup(filterGroup);
    } else return operatorOptions;
  }, [getOperatorOptionsByFilterGroup, operatorOptions]);

  const dataGroupValueDTO = useMemo(
    () => mapFilterToDataGrouping(filterGroup),
    [filterGroup]
  );

  const selectedFieldLabel = useMemo(() => {
    const { field } = filterGroup;
    if (!field.value) return "";

    return `${field.source}.${field.value}`;
  }, [filterGroup]);

  const handleOnClickFieldSelector = () => {
    setFieldOptionsMenuOpen(true);
    menuTwoWayBindingRef.current?.onOpenMenu();
  };

  const handleOnClose = () => {
    menuTwoWayBindingRef.current?.onClose();
    setFieldOptionsMenuOpen(false);
  };

  const onSelectValue = (values) => {
    handleOnClose();
    if (!values.length) return;
    const selectedField = values[0];
    const fieldPayload = {
      value: selectedField.value,
      label: selectedField.label,
      source: selectedField.source,
      valueTypes: selectedField.valueTypes,
    };
    const updatedFilterGroup = {
      ...filterGroup,
      field: fieldPayload,
      operator: {},
      values: [],
    };
    // setFieldOptions(updatedFilterGroup);
    updateFilterGroup(updatedFilterGroup);
  };

  const valueSelectorIsDisabled = useMemo(() => {
    const isEmptyOperator =
      !!EmptyValueOperatorsMap[filterGroup.operator.value];
    return isEmptyOperator;
  }, [filterGroup]);

  const handleOnChangeOperator = (selectedOperator) => {
    const updatedFilterGroup = {
      ...filterGroup,
      operator: selectedOperator,
      values: [],
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeValues = (values) => {
    const selectedValues = Array.isArray(values) ? values : [values];
    const updatedFilterGroup = {
      ...filterGroup,
      values: selectedValues,
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnChangeConjunction = (selectedConjunction) => {
    const updatedFilterGroup = {
      ...filterGroup,
      conjunction: selectedConjunction,
    };
    updateFilterGroup(updatedFilterGroup);
  };
  const checkIsMultiState = () => {
    if (!filterGroup.operator.value) return false;
    return !!MultipleValuesFilterOperators[filterGroup.operator.value];
  };

  const handleOnChangeAddToColumns = () => {
    const updatedFilterGroup = {
      ...filterGroup,
      addToColumns: !filterGroup.addToColumns,
    };
    updateFilterGroup(updatedFilterGroup);
  };

  const handleOnInputChange = (keyword) => {
    setValueOptionsSearchKeyword(keyword);
  };

  return (
    <Fragment>
      <FilterGroupContainer>
        <ConditionComponent condition={withConjunction}>
          <SelectorSection sx={{ marginBottom: "12px" }}>
            <SelectorLabel>Conjunction</SelectorLabel>
            <FilterGroupConjunctionContainer
              placeholder='CONJ'
              options={conjunctionOptions}
              onChange={handleOnChangeConjunction}
              value={filterGroup.conjunction}
            />
          </SelectorSection>
        </ConditionComponent>
        <FilterGroupContentContainer>
          <SelectorSection>
            <SelectorLabel>Field</SelectorLabel>
            <FieldSelectorContainer onClick={handleOnClickFieldSelector}>
              {selectedFieldLabel}
            </FieldSelectorContainer>
          </SelectorSection>

          <SelectorSection>
            <SelectorLabel>Operator</SelectorLabel>
            <OperatorSelectorContainer
              value={filterGroup.operator}
              options={operators}
              onChange={handleOnChangeOperator}
            />
          </SelectorSection>

          <SelectorSection>
            <SelectorLabel>Values</SelectorLabel>
            <ValueSelectorContainer
              onInputChange={handleOnInputChange}
              isDisabled={valueSelectorIsDisabled}
              onChange={handleOnChangeValues}
              options={sortedValueOptions}
              value={filterGroup.values}
              isSearchable
              isClearable
              isMulti={checkIsMultiState()}
            />
          </SelectorSection>
        </FilterGroupContentContainer>
        <FilterActionsContainer></FilterActionsContainer>
        <ConditionComponent condition={filterGroup.warning}>
          <WarningContainer>
            <InfoOutlinedIcon sx={{ fontSize: "18px" }} />
            <Typography variant='caption' fontWeight={600}>
              {filterGroup.warning}
            </Typography>
          </WarningContainer>
        </ConditionComponent>
        <FilterActionButtonsRoot>
          <SelectFieldAsColumn
            checked={filterGroup.addToColumns}
            onChange={handleOnChangeAddToColumns}
          />
          <DeleteButtonContainer
            onClick={() => onDeleteFilterGroup(filterGroup)}
          >
            <DeleteRoundedIcon sx={{ fontSize: "18px", color: "#fff" }} />
          </DeleteButtonContainer>
        </FilterActionButtonsRoot>
      </FilterGroupContainer>
      <SelectOptionsMenu
        data={schema}
        opened={fieldOptionsMenuOpen}
        anchorEl={SelectorButtonRef.current}
        onClose={handleOnClose}
        isMulti={false}
        twoWayBindingRef={menuTwoWayBindingRef}
        elements={{
          selectedValues: false,
          footer: true,
          heading: false,
        }}
        values={dataGroupValueDTO}
        setValues={onSelectValue}
      />
    </Fragment>
  );
};

function mapFilterToDataGrouping(filterGroup) {
  const fieldExists = Object.keys(filterGroup.field).length > 0;
  if (fieldExists) {
    const { label, source, value } = filterGroup.field;
    const fieldPayload = {
      label: label,
      source: source,
      value: value,
    };
    return [fieldPayload];
  }
  return [];
}
