import { createContext } from "react";

export const IntegrationValuesStateContext = createContext({
  account: "",
  integrationName: "",
  role: "",
  externalId: "",
  selfRole: "",
  arnRoles: [],
  accessKeyId: "",
  secretAccessKey: "",
  regions: [],
});

export const IntegrationValueMutatorsContext = createContext({
  setAccount: () => null,
  setIntegrationName: () => null,
  setRole: () => null,
  setExternalId: () => null,
  setSelfRole: () => null,
  setArnRoles: () => null,
  setAccessKeyId: () => null,
  setSecretAccessKey: () => null,
  setRegions: () => null,
});
