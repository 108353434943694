export function useRunQuery() {
  const openEncodedQuery = (table, encoding) => {
    const { origin } = window.location;
    const root = `${origin}/${table}`;
    const path = `${root}?view=runSavedQuery&query=${encoding}`;
    window.open(path, "_blank");
  };

  const run = (_queryPayload) => {
    const { table_name, query_uuid } = _queryPayload;
    openEncodedQuery(table_name, query_uuid);
  };

  return run;
}
