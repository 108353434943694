import React from "react";
import { Box, InputBase, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import styles from "../GoogleWorkspace.module.css";
import googleWorkspaceLogo from "../../../assets/images/google-workspace.svg";

export const GoogleWorkspaceIntegrationContent = ({
  handleLabelNameChange,
  handleAdminEmailChange,
  handleFileChange,
  jsonFile,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/json": [".json"],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        try {
          const text = await file.text();
          const jsonContent = JSON.parse(text);
          handleFileChange(jsonContent);
        } catch (error) {
          console.error("Error reading JSON file:", error);
        }
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "50px",
        padding: "14px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff3e0",
            padding: "16px 20px",
            borderRadius: "8px",
            border: "1px solid #ffe0b2",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color: "#ed6c02",
              fontWeight: 500,
              letterSpacing: "0.1px",
            }}
          >
            Note: The following steps require Google Workspace administrator
            privileges to complete.
          </Typography>
        </Box>

        <Box
          sx={{
            padding: "24px",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            marginBottom: "24px",
            width: "100%",
            backgroundColor: "#ffffff",
          }}
        >
          <Typography
            variant='h6'
            sx={{ marginBottom: "16px", fontWeight: 500 }}
          >
            Required OAuth Scopes
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              maxHeight: "400px",
              overflowY: "auto",
              pr: "8px",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(0,0,0,0.05)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
              },
            }}
          >
            <Box>
              <Typography
                variant='subtitle1'
                sx={{ color: "#1976d2", marginBottom: "8px", fontWeight: 500 }}
              >
                Core Requirements
              </Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  lineHeight: "1.8",
                  listStyleType: "none",
                }}
              >
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.user.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.device.mobile.readonly
                </li>
              </ul>
            </Box>

            <Box>
              <Typography
                variant='subtitle1'
                sx={{ color: "#1976d2", marginBottom: "8px", fontWeight: 500 }}
              >
                Browser & Security Management
              </Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  lineHeight: "1.8",
                  listStyleType: "none",
                }}
              >
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.device.chromebrowsers.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.user.security
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/cloud-identity.devices.readonly
                </li>
              </ul>
            </Box>

            <Box>
              <Typography
                variant='subtitle1'
                sx={{ color: "#1976d2", marginBottom: "8px", fontWeight: 500 }}
              >
                Groups & Roles
              </Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  lineHeight: "1.8",
                  listStyleType: "none",
                }}
              >
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.group.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  • https://www.googleapis.com/auth/apps.groups.settings
                </li>
              </ul>
            </Box>

            <Box>
              <Typography
                variant='subtitle1'
                sx={{ color: "#1976d2", marginBottom: "8px", fontWeight: 500 }}
              >
                Usage & Audit
              </Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  lineHeight: "1.8",
                  listStyleType: "none",
                }}
              >
                <li style={{ marginBottom: "8px" }}>
                  • https://www.googleapis.com/auth/admin.reports.usage.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  • https://www.googleapis.com/auth/admin.reports.audit.readonly
                </li>
              </ul>
            </Box>

            <Box>
              <Typography
                variant='subtitle1'
                sx={{ color: "#1976d2", marginBottom: "8px", fontWeight: 500 }}
              >
                Domain & Licensing
              </Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: "20px",
                  lineHeight: "1.8",
                  listStyleType: "none",
                }}
              >
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.domain.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  •
                  https://www.googleapis.com/auth/admin.directory.customer.readonly
                </li>
                <li style={{ marginBottom: "8px" }}>
                  • https://www.googleapis.com/auth/apps.licensing
                </li>
              </ul>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", marginBottom: "32px" }}>
          <Typography variant='h6' sx={{ marginBottom: "16px" }}>
            Integration Setup Instructions
          </Typography>

          <ol
            style={{
              margin: 0,
              paddingLeft: "24px",
              "& li": {
                marginBottom: "16px",
                lineHeight: "1.6",
              },
            }}
          >
            <li>Go to the Google Cloud Console</li>
            <li>Create a new project or select an existing one</li>
            <li>Enable the Admin SDK API</li>
            <li>
              Create a Service Account and download the JSON credentials file
            </li>
            <li>
              Grant necessary permissions to the service account in Google
              Workspace Admin Console
            </li>
          </ol>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "32px",
            maxWidth: "600px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant='subtitle1' className={styles.inputText}>
                Label Name *
              </Typography>
              <InputBase
                id='labelName'
                onChange={handleLabelNameChange}
                placeholder='Enter label name'
                className={styles.input}
                sx={{
                  width: "100%",
                  maxWidth: "none",
                  "& input": {
                    width: "100%",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant='subtitle1' className={styles.inputText}>
                Admin Email Address *
              </Typography>
              <InputBase
                id='adminEmail'
                onChange={handleAdminEmailChange}
                placeholder='admin@domain.com'
                className={styles.input}
                sx={{
                  width: "100%",
                  maxWidth: "none",
                  "& input": {
                    width: "100%",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "24px", width: "100%" }}>
            <Typography variant='subtitle1' className={styles.inputText}>
              Service Account JSON File *
            </Typography>
            <div
              {...getRootProps()}
              className={styles.dropzone}
              style={{ maxWidth: "none" }}
            >
              <input {...getInputProps()} />
              <div className={styles.dropzoneContent}>
                {jsonFile ? (
                  <>
                    <InsertDriveFileIcon className={styles.fileIcon} />
                    <Typography className={styles.fileName}>
                      Service account credentials loaded
                    </Typography>
                    <Typography className={styles.dropzoneText}>
                      Click or drag to replace
                    </Typography>
                  </>
                ) : (
                  <>
                    <CloudUploadIcon className={styles.uploadIcon} />
                    <Typography className={styles.dropzoneText}>
                      {isDragActive
                        ? "Drop the JSON file here"
                        : "Drag and drop your JSON file here, or click to select"}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const GoogleWorkspaceIntegrationConfig = {
  logo: googleWorkspaceLogo,
  headerTitle: "Setup Google Workspace Integration",
};
