import { RenderWithContext } from "../../../utils/hocs/RenderWithContext";
import { Table } from "./component/Table";
import { TableActions } from "./component/TableActions";
import { Root } from "./component/styled";
import { Provider } from "./context/provider";
import { useAssetTableState } from "./hooks/useAssetTableState";
import { usePrimaryTableState } from "./hooks/usePrimaryTableState";
import { useApplyFilters } from "./hooks/useApplyFilters";
import React from "react";
import { useInitTable } from "./hooks/useInitTable";

const AssetTableRoot = () => {
  const { schema, columns, grouping, searchKeyword } = useAssetTableState();
  const primaryTableState = usePrimaryTableState();
  const applyFilters = useApplyFilters();
  const actionsState = {
    columns,
    searchKeyword,
    grouping,
    filters: primaryTableState.filters,
  };

  const handleOnClickSearchResultColumn = () => {};

  useInitTable();

  return (
    <Root>
      <TableActions
        state={actionsState}
        searchResultsColumnSelectorProps={{
          onClick: handleOnClickSearchResultColumn,
        }}
        onApplyFilters={applyFilters}
        schema={schema}
      />
      <Table />
    </Root>
  );
};

export default RenderWithContext(React.memo(AssetTableRoot), Provider);
