import { useCallback, useMemo } from "react";
import { GroupingSectionContainer, GroupsSectionContent } from "../../styled";
import { GroupingItem } from "../../../../../../../../../containers/inventory/AssetsTable/components/Grouping/GroupingItem";
import { useStateDispatch } from "../../../../../../hooks/useStateDispatch";
import { Actions } from "../../../../../../stateManagement/actions";
import { useAssetTableState } from "../../../../../../hooks/useAssetTableState";
import { useGroupingTableState } from "../../../../../../hooks/useGroupingTableState";
import { useFetchGroupingTableData } from "../../../../../../hooks/useFetchGrouingTableData";

export const GroupOptionsView = () => {
  const dispatch = useStateDispatch();
  const {
    currentGroupingOption,
    currentGroupingOptions,
    grouping,
    searchKeyword,
    filters,
  } = useAssetTableState();
  const fetchGroupingTableData = useFetchGroupingTableData();
  const { paginationConfig: pagination } = useGroupingTableState();

  const checkOptionIsSelected = useCallback(
    (option) => {
      return (
        option.value === currentGroupingOption?.value &&
        option.label === currentGroupingOption?.label
      );
    },
    [currentGroupingOption]
  );

  const setGroupingOption = useCallback((payload) => {
    dispatch({
      type: Actions.SET_GROUPING_OPTION,
      payload,
    });
  }, []);

  const handleOnClickGroupOption = useCallback(
    (ev, option) => {
      const group = grouping[0];
      const payload = {
        filters,
        search: searchKeyword,
        grouping: {
          group,
          option,
        },
        pagination,
      };
      fetchGroupingTableData(payload);
      setGroupingOption(option);
    },
    [
      fetchGroupingTableData,
      filters,
      searchKeyword,
      setGroupingOption,
      grouping,
      pagination,
    ]
  );

  const optionNodes = useMemo(() => {
    return currentGroupingOptions.map((option, index) => {
      return (
        <GroupingItem
          key={`data-group-${index}`}
          selected={checkOptionIsSelected(option)}
          onClick={(ev) => handleOnClickGroupOption(ev, option)}
        >
          {option.label}
        </GroupingItem>
      );
    });
  }, [currentGroupingOptions, handleOnClickGroupOption, checkOptionIsSelected]);

  return (
    <GroupingSectionContainer>
      <GroupsSectionContent>{optionNodes}</GroupsSectionContent>
    </GroupingSectionContainer>
  );
};
