import { useMaterialReactTable } from "material-react-table";

export function useUserManagementTable(props) {
  return useMaterialReactTable({
    paginationDisplayMode: "pages",
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableBottomToolbar: true,
    layoutMode: "grid",
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [20],
    },
    muiTopToolbarProps: {
      hidden: true,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiTableContainerProps: {
      sx: {
        height: "calc(100% - 50px)",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        height: "40px",
      },
    },
    muiTableBodyRowProps: {
      sx: {
        height: "50px",
        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
      },
    },
    ...props,
  });
}
