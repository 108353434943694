import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import createButtonConfig from "../../shared/functions/buttons";
import { addIntegration } from "../../redux/actions/integrationActions";
import BannerAlert from "../../components/banners/ErrorAlert";
import {
  GoogleWorkspaceIntegrationConfig,
  GoogleWorkspaceIntegrationContent,
} from "./googleWorkspaceIntegrationContent";
import {
  GoogleWorkspaceIntegrationCompleteConfig,
  GoogleWorkspaceIntegrationCompleteContent,
} from "./googleWorkspaceIntegrationComplete";
import { sendGoogleWorkspaceOnboarding } from "../../utils/api";
import { Root } from "./styled";

const GoogleWorkspaceIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [sidebarStep, setSidebarStep] = React.useState(1);
  const [errors, setErrors] = React.useState([]);

  const [labelName, setLabelName] = React.useState(null);
  const [adminEmail, setAdminEmail] = React.useState(null);
  const [serviceAccountJson, setServiceAccountJson] = React.useState(null);

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const handleLabelNameChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setLabelName(value);
  };

  const handleAdminEmailChange = (event) => {
    const value = event.target.value === "" ? null : event.target.value;
    setAdminEmail(value);
  };

  const handleFileChange = (jsonContent) => {
    setServiceAccountJson(jsonContent);
  };

  const sidebarSteps = [
    {
      title: "Setup Integration",
      description: "Setup Google Workspace Integration",
    },
    { title: "Completion", description: "" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const onContinue = async () => {
    setErrors([]);

    if (sidebarStep === 1) {
      try {
        if (!labelName || !adminEmail || !serviceAccountJson) {
          setErrors(["Please fill in all required fields"]);
          return;
        }

        const auth0Token = await getAccessTokenSilently();

        const data = {
          name: labelName,
          admin_email: adminEmail,
          service_account_json: serviceAccountJson,
        };

        await sendGoogleWorkspaceOnboarding(data, auth0Token);
        setSidebarStep(2);
      } catch (error) {
        let newErrors = [];
        if (typeof error.response?.data?.detail === "string") {
          newErrors.push(`${error.response.data.detail}`);
        } else if (error.response?.data?.detail) {
          newErrors = error.response.data.detail.map((detail) => {
            const errorLocation =
              detail.loc.length > 1
                ? detail.loc.slice(1).join(".")
                : "unknown location";
            return `${errorLocation}: ${detail.msg || "An error occurred"}`;
          });
        } else {
          newErrors = ["Failed to onboard Google Workspace. Please try again."];
        }
        setErrors((currentErrors) => [...currentErrors, ...newErrors]);
      }
    } else if (sidebarStep === 2) {
      dispatch(addIntegration("gsuite"));
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      false
    ),
  ];

  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false
    ),
  ];

  const pageSteps = [
    {
      headerLogo: GoogleWorkspaceIntegrationConfig.logo,
      headerTitle: GoogleWorkspaceIntegrationConfig.headerTitle,
      content: (
        <GoogleWorkspaceIntegrationContent
          handleLabelNameChange={handleLabelNameChange}
          handleAdminEmailChange={handleAdminEmailChange}
          handleFileChange={handleFileChange}
          jsonFile={serviceAccountJson}
        />
      ),
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: GoogleWorkspaceIntegrationCompleteConfig.logo,
      headerTitle: GoogleWorkspaceIntegrationCompleteConfig.headerTitle,
      content: <GoogleWorkspaceIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];

  return (
    <Root className='scrollable'>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20}
          />
        ))}

      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </Root>
  );
};

export default GoogleWorkspaceIntegration;
