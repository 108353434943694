import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Grid } from '@mui/material';

export const JiraOAuthContent = () => {
  const [integrationName, setIntegrationName] = useState('');
  const [domain, setDomain] = useState('');
  const [domainError, setDomainError] = useState('');

  const validateDomain = (value) => {
    if (!value) {
      setDomainError('Domain is required');
      return false;
    }
    const urlPattern = /^(https?:\/\/)/i;
    if (!urlPattern.test(value)) {
      setDomainError('Domain must start with http:// or https://');
      return false;
    }
    setDomainError('');
    return true;
  };

  const handleDomainChange = (e) => {
    const value = e.target.value;
    setDomain(value);
    validateDomain(value);
  };

  const handleOAuthClick = () => {
    if (!validateDomain(domain)) return;

    const clientId = process.env.REACT_APP_JIRA_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_FRONTEND_URL + "/jira_auth");
    // const redirectUri = encodeURIComponent("http://localhost:3000/jira_auth");
    const scope = encodeURIComponent("offline_access read:jira-work read:jira-user");
    const stateParam = encodeURIComponent(`${integrationName || "Jira oAuth Integration"};${domain}`);
    
    const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${stateParam}&response_type=code&prompt=consent`;
    
    console.log('Redirecting to:', authUrl); // For debugging
    window.location.href = authUrl;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Box sx={{ pr: 2 }}>
          <Typography variant="h6" gutterBottom>
            Jira OAuth Integration
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Connect your Jira account using OAuth for secure access. This integration allows:
          </Typography>
          <Box component="ul" sx={{ pl: 2, mt: 1 }}>
            <Typography component="li" variant="body2" color="text.secondary">
              Secure authentication without storing credentials
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary">
              Access to Jira issues and projects
            </Typography>
            <Typography component="li" variant="body2" color="text.secondary">
              Automatic token refresh handling
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box sx={{ textAlign: 'center', p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Connect with Jira OAuth
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Click the button below to authorize access to your Jira account
          </Typography>
          <TextField
            label="Integration Name"
            variant="outlined"
            fullWidth
            value={integrationName}
            onChange={(e) => setIntegrationName(e.target.value)}
            sx={{ mb: 3 }}
            placeholder="Jira oAuth Integration"
          />
          <TextField
            label="Jira Domain"
            variant="outlined"
            fullWidth
            value={domain}
            onChange={handleDomainChange}
            error={!!domainError}
            helperText={domainError}
            sx={{ mb: 3 }}
            placeholder="https://your-domain.atlassian.net"
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleOAuthClick}
            disabled={!domain || !!domainError}
          >
            Connect with Jira
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}; 