import { InfoIconRoot, SummaryCardRoot } from "./styled";
import ConditionComponent from "../../../../../../utils/ConditionComponent";
import { Tooltip } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
export const FieldCard = ({
  children,
  label,
  labelProps,
  fieldInfo = "",
  ...props
}) => {
  return (
    <SummaryCardRoot {...props}>
      {children}
      <ConditionComponent condition={fieldInfo}>
        <Tooltip placement='top' title={fieldInfo}>
          <InfoIconRoot>
            <TipsAndUpdatesIcon />
          </InfoIconRoot>
        </Tooltip>
      </ConditionComponent>
    </SummaryCardRoot>
  );
};
