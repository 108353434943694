import { Typography } from "@mui/material";
import { Header, PageRoot } from "./component/styled";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { useCMSTProjectsState } from "../hooks/useCMSTProjectsState";
import { LoadingSection } from "../../../../components/LayoutFillers";
import { ErrorSection } from "../../../../components/LayoutFillers/ErrorSection";
import { EntryView } from "./EntryView";

const PageEntry = () => {
  const { isLoading, isError, summary, schema } = useCMSTProjectsState();

  const dataFetched = Boolean(!isError && !isLoading && summary && schema);

  return (
    <PageRoot>
      <Header>
        <Typography variant='pageHeading'>
          Change Management Security Tracker / Projects
        </Typography>
      </Header>
      <ConditionComponent condition={isLoading}>
        <LoadingSection />
      </ConditionComponent>
      <ConditionComponent condition={isError}>
        <ErrorSection errorMessage={"Failed to Load Data"} />
      </ConditionComponent>
      <ConditionComponent condition={dataFetched}>
        <EntryView />
      </ConditionComponent>
    </PageRoot>
  );
};

export default PageEntry;
