import React, { useMemo } from "react";
import { sendJiraOnboarding } from "../../utils/api";

import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import createButtonConfig from "../../shared/functions/buttons";
import { addIntegration } from "../../redux/actions/integrationActions";
import { useAuth0 } from "@auth0/auth0-react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {
  JiraIntegrationCompleteConfig,
  JiraIntegrationCompleteContent,
} from "./jiraIntegrationComplete";
import {
  JiraIntegrationConfig,
  JiraIntegrationContent,
} from "./jiraIntegrationContent";
import { JiraIntegrationMethodContent } from "./jiraIntegrationMethod";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import BannerAlert from "../../components/banners/ErrorAlert";
import { IntegrationsProvider } from "./context/Provider";
import { useIntegrationValues } from "./hooks/useIntegrationValues";
import { Root } from "../googleWorkspaceIntegration/styled";
import {JiraOAuthContent} from "./jiraOAuthContent/index"

const JiraIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarStep, setSidebarStep] = React.useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = React.useState([]);
  const [integrationType, setIntegrationType] = React.useState(null); // 'oauth' or 'key'

  const { apiToken, domainUrl, email, integrationName } =
    useIntegrationValues();

  const canContinue = useMemo(() => {
    return !!integrationName && !!apiToken && domainUrl;
  }, [apiToken, domainUrl, email, integrationName]);

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const sidebarSteps = [
    { title: "Integration Method", description: "Choose Jira Integration Method" },
    { title: "Setup Integration", description: "Setup Jira Integration" },
    { title: "Completion", description: "" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const onContinue = async () => {
    setErrors([]);

    if (sidebarStep === 1) {
      console.log('Selected integration type:', integrationType);
      if (integrationType) {
        setSidebarStep(2);
        return;
      }
      setErrors(["Please select an integration method"]);
      return;
    }

    if (sidebarStep === 2) {
      if (integrationType === 'key') {
        try {
          const auth0Token = await getAccessTokenSilently();
          await sendJiraOnboarding(
            apiToken,
            domainUrl,
            email,
            integrationName,
            auth0Token
          );
          setSidebarStep(3);
          return;
        } catch (error) {
          let newErrors = [];
          if (typeof error.response?.data?.detail === "string") {
            newErrors.push(`${error.response.data.detail}`);
          } else if (error.response?.data?.detail) {
            newErrors = error.response.data.detail.map((detail) => {
              const errorLocation =
                detail.loc.length > 1
                  ? detail.loc.slice(1).join(".")
                  : "unknown location";
              return `${errorLocation}: ${detail.msg || "An error occurred"}`;
            });
          } else {
            newErrors = ["Failed to connect Jira. Please try again."];
          }

          // Update the errors state with new errors
          setErrors((currentErrors) => [...currentErrors, ...newErrors]);
          return; // Ensure function execution stops here after handling the error
        }
      } else {
        // Handle OAuth flow
        // Will be implemented in the OAuth component
        setSidebarStep(3);
      }
    } else if (sidebarStep === 3) {
      dispatch(addIntegration("jira"));
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel"),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      !integrationType
    ),
  ];

  const footerButtonsStepTwo = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel"),
    createButtonConfig(
      "Continue",
      "right",
      onContinue,
      <KeyboardArrowRightOutlinedIcon />,
      "right",
      integrationType === 'key' ? !canContinue : false
    ),
  ];

  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false
    ),
  ];

  const handleIntegrationTypeSelect = (type) => {
    setIntegrationType(type);
    setSidebarStep(2);
  };

  const pageSteps = [
    {
      headerLogo: JiraIntegrationConfig.logo,
      headerTitle: "Choose Integration Method",
      content: <JiraIntegrationMethodContent onSelect={handleIntegrationTypeSelect} />,
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: JiraIntegrationConfig.logo,
      headerTitle: JiraIntegrationConfig.headerTitle,
      content: integrationType === 'key' ? 
        <JiraIntegrationContent /> : 
        <JiraOAuthContent />,
      footerButtons: footerButtonsStepTwo,
    },
    {
      headerLogo: JiraIntegrationCompleteConfig.logo,
      headerTitle: JiraIntegrationCompleteConfig.headerTitle,
      content: <JiraIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];
  return (
    <Root>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <BannerAlert
            key={index}
            message={error}
            topOffset={index * 80 + 20}
          />
        ))}

      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </Root>
  );
};

const Component = (props) => {
  return (
    <IntegrationsProvider>
      <JiraIntegration {...props} />
    </IntegrationsProvider>
  );
};

export default Component;
