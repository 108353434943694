export const APICMSTTableVariants = {
  ISSUE: "cmst_tickets",
  PROJECT: "cmst_projects",
} as const;

export const CMSTTableVariants = {
  ISSUE: "cmst/issues",
  PROJECT: "cmst/projects",
} as const;

export type APICMSTTableType = TypeofObjectValues<typeof APICMSTTableVariants>;
export type CMSTTableType = TypeofObjectValues<typeof APICMSTTableVariants>;
