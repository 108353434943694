import styles from "./GraphPage.module.css";
import { createCombosFromNodes } from "./utils/createCombosFromNodes";
import { LoadingIndicator } from "../../../../views/G6Graph/components/LoadingIndicator";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { GraphTopMenusSection } from "../../../../views/G6Graph/styles";
import MapComponent from "../../../../components/G6Graph/MapComponent";
import SingleSelect from "../../../../components/G6Graph/SingleSelect";
import {
  GraphGroupingTarget,
  graphLayoutOptions,
} from "../../../../views/G6Graph/constants";
import GraphGrouping from "../../../../components/G6Graph/GraphGrouping";
import FilterSelector from "../../../../views/G6Graph/components/FilterSelector";
import ButtonToggle from "../../../../components/G6Graph/ButtonToggle";
import ButtonReset from "../../../../components/G6Graph/ButtonReset";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useTagOptions } from "../../../../views/G6Graph/hooks/useTagOptions";
import { transformUrlFilterParams } from "../../../../views/G6Graph/transformUrlFilterParams";
import helperFunctions from "../../../../utils/helper";
import { graphv2 } from "../../../../utils/api";
import { Grid } from "@mui/material";
import G6GraphComponent from "../../../../components/G6Graph";
import { getIconURL } from "../../../../views/G6Graph/utils/getIconURL";
import { applyGraphDataFiltering } from "./utils/applyGraphDataFiltering";
import { AssetPropertyType } from "../../../../components/G6Graph/GraphGrouping/data";

function GraphV1() {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showAllEntities, setShowAllEntities] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [layoutType, setLayoutType] = useState("Combo");
  const [properties, setProperties] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [resetTrigger, setResetTrigger] = useState(0);
  const [isLoadingGraph, setIsLoadingGraph] = useState(true);
  const [groupingTarget, setGroupingTarget] = useState(
    GraphGroupingTarget.VISIBLE_DATA
  );
  const [showRelatedNodes, setShowRelatedNodes] = useState(false);

  const [filters, setFilters] = useState([]);
  const [tagsKeys, setTagsKeys] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const allTagOptions = useTagOptions(tagsKeys);

  // get params
  const params = new URLSearchParams(location.search);
  const param = params.get("bp");
  const urlFilterParams = params.get("filters");

  useEffect(() => {
    const transformedFilterParams = transformUrlFilterParams(urlFilterParams);
    setFilters(transformedFilterParams);
  }, [location]);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const auth0Token = await helperFunctions.getAuth0Token(
          getAccessTokenSilently
        );
        let r = await graphv2(auth0Token);
        if (r) {
          if (param) {
            r = {
              nodes: r.nodes.filter(
                (node) =>
                  node.business_process &&
                  node.business_process
                    .toLocaleLowerCase()
                    .includes(param.toLocaleLowerCase())
              ),
              edges: r.edges,
            };
          } else {
            console.log("no param");
          }
          setIsLoadingGraph(false);
          setData(r);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchGraphData();
  }, [getAccessTokenSilently, resetTrigger]);

  useEffect(() => {
    if (data && data.nodes) {
      let tempTagsKeys = new Set();
      data.nodes.forEach((node) => {
        if (node.tags) {
          Object.keys(node.tags).forEach((tag) => {
            tempTagsKeys.add(tag);
          });
        }
      });

      tempTagsKeys = Array.from(tempTagsKeys.values());

      setTagsKeys(
        tempTagsKeys.map((tag) => ({
          name: tag,
          checked: false,
          source: AssetPropertyType.USER,
        }))
      );
      const updatedNodes = data.nodes.map((node) => {
        const deploymentType = node.deployment || node.category;
        return {
          ...node,
          iconURL: getIconURL(deploymentType),
        };
      });

      let filteredNodes = updatedNodes;

      let nodes = filteredNodes,
        edges = data.edges;

      if (filters.length) {
        const { edges: filteredEdges, nodes: filteredNodes } =
          applyGraphDataFiltering(
            { nodes: updatedNodes, edges: data.edges },
            {
              search: searchInput,
              filterGroups: filters,
            },
            showRelatedNodes
          );
        edges = filteredEdges;
        nodes = filteredNodes;
      }

      if (!showAllEntities) {
        const connectedNodeIds = new Set(
          data.edges.flatMap((edge) => [edge.source, edge.target])
        );
        nodes = nodes.filter((node) => connectedNodeIds.has(node.id));
      }

      if (groupingTarget === GraphGroupingTarget.ALL) {
        nodes = updatedNodes;
        edges = data.edges;
      }

      let newData;
      if (properties && properties.length > 0) {
        const combos = createCombosFromNodes(nodes, properties);
        newData = {
          nodes,
          edges,
          combos,
        };
        setLayoutType("Combo");
      } else {
        newData = {
          nodes,
          edges,
        };
        setLayoutType("Default");
      }
      setGraphData(newData);
    }
  }, [
    data,
    properties,
    showAllEntities,
    filters,
    groupingTarget,
    searchInput,
    showRelatedNodes,
  ]);

  useEffect(() => {
    let newLayoutType;
    if (properties.length > 0) {
      newLayoutType = selectedLayout || "Combo";
    } else {
      newLayoutType = selectedLayout || "Default";
    }
    setLayoutType(newLayoutType);
  }, [properties, selectedLayout]);

  const handleSelectionChange = (selectedItems) => {
    if (selectedItems.length > 0) {
      setProperties(selectedItems);
    } else {
      setProperties([]);
    }
  };

  const handleLayoutChange = (selected) => {
    setSelectedLayout(selected);
  };

  const handleReset = () => {
    setSearchInput("");
    setShowAllEntities(false);
    setProperties([]);
    setSelectedLayout("Default");
    setLayoutType("Select Layout");
    setResetTrigger((prev) => prev + 1);
    setFilters([]);
    setSelectedTags([]);

    history.push("/graph");
  };

  const handleOnChangeGroupingTarget = () => {
    setGroupingTarget((p) => {
      if (p === GraphGroupingTarget.ALL)
        return GraphGroupingTarget.VISIBLE_DATA;
      return GraphGroupingTarget.ALL;
    });
  };

  const handleOnApplyFilters = (appliedFilters, showRelatedNodes) => {
    setFilters(appliedFilters);
    setShowRelatedNodes(showRelatedNodes);
    setShowAllEntities(true);
  };

  if (isLoadingGraph) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.graphPage} style={{ position: "relative" }}>
      <ConditionComponent condition={!!graphData}>
        <Grid
          container
          style={{ backgroundColor: "#F6F8FA", position: "relative" }}
        >
          <Grid item xs={12} style={{ height: "100vh" }}>
            <G6GraphComponent
              key={layoutType}
              data={graphData}
              labelProp={"name"}
              layoutType={layoutType}
              plugins={[]}
              showSidebar={true}
              style={{ zIndex: 1, height: "100%" }}
            />
            <GraphTopMenusSection>
              <MapComponent
                numberOfServices={graphData ? graphData.nodes.length : 0}
              />
              <SingleSelect
                items={graphLayoutOptions}
                defaultSelected='Select Layout'
                onSelectedChange={handleLayoutChange}
              />
              <GraphGrouping
                userOptions={tagsKeys}
                allOptions={allTagOptions}
                onSelectionChange={handleSelectionChange}
                selectedValues={selectedTags}
                setSelectedValues={setSelectedTags}
                graphData={data}
                onChangeCurrentGroupingSource={handleOnChangeGroupingTarget}
                target={groupingTarget}
              />
              <FilterSelector
                onApplyFilters={handleOnApplyFilters}
                filters={filters}
                graphData={data}
                graphDataTagKeys={tagsKeys}
                showRelatedNodesParentState={showRelatedNodes}
              />
              <ButtonToggle
                variant='contained'
                onClick={() => setShowAllEntities(!showAllEntities)}
                label={
                  showAllEntities
                    ? "Hide Unrelated Entities"
                    : "Show All Entities"
                }
              />
              <ButtonReset onClick={handleReset} />
            </GraphTopMenusSection>
          </Grid>
        </Grid>
      </ConditionComponent>
    </div>
  );
}

export default GraphV1;
