import { AppEnv } from "../AppEnv";
import { DataTypeUtils } from "./DataTypeUtils";

function getPathArr(path, config = { split: true }) {
  if (!path) return [];
  if (typeof path === "string") {
    if (config.split) {
      const DEFAULT_SEPARATOR = ".";
      const paths = path.split(config?.separator ?? DEFAULT_SEPARATOR);
      return paths;
    } else return [path];
  }
  return path;
}

export function getObjectValueByPath(object, path, config = { split: true }) {
  const invalidParams = !path || !object;
  if (invalidParams) return null;
  const pathArr = getPathArr(path, config);
  if (typeof object === "object") {
    const pathStack = pathArr;
    let value = object;
    while (pathStack.length) {
      const currentPath = pathStack.shift();
      if (typeof value === "object" && !Array.isArray(value)) {
        const currentValue = value[currentPath];
        value = currentValue;
        continue;
      }
      if (Array.isArray(value)) {
        let values = [];
        value.forEach((entry) => {
          const result = entry[currentPath];
          if (!DataTypeUtils.exists(result)) return;
          if (Array.isArray(result)) {
            if (!result.length) return;
            values = values.concat(result);
          }
          values.push(result);
        });

        value = values;
        continue;
      }
    }
    return value ?? null;
  }
}

if (AppEnv.env === "test") {
  module.exports = {
    getPathArr,
    getObjectValueByPath,
  };
}
