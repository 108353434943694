import { MaterialReactTable } from "material-react-table";
import { useDataTable } from "../../hooks/useDataTable";
import { useMemo } from "react";
import { useAssetsTableMethods } from "../../hooks/useAssetsTableMethods";
import { useGroupingTableState } from "../../hooks/useGroupingTableState";
import { useAssetInventoryActionDispatch } from "../../hooks/useAssetInventoryActionDispatch";
import { ActionType } from "../../state-management/actions";
import { checkTableIPaginationIsFirstRender } from "../../utils/checkTableIPaginationIsFirstRender";
import { usePageData } from "../../hooks/usePageData";
import { createGraphColumnValuesFromData } from "../../utils/getGraphColumnValuesFromData";

export const DataTable = () => {
  const { fetchGroupingTableData } = useAssetsTableMethods();
  const dispatch = useAssetInventoryActionDispatch();
  const { filters, currentGroupingValue, searchKeyword, primaryTableColumns } =
    usePageData();

  const { data, isLoading, meta, paginationConfig } = useGroupingTableState();

  const columns = useMemo(() => {
    return createGraphColumnValuesFromData(primaryTableColumns);
  }, [primaryTableColumns]);

  function onPaginationChange(updater) {
    const newPaginationConfig = updater(paginationConfig);
    const isFirstRender = checkTableIPaginationIsFirstRender(
      paginationConfig,
      newPaginationConfig
    );
    if (isFirstRender) return;

    dispatch({
      type: ActionType.SET_GROUPING_TABLE_PAGINATION_CONFIG,
      payload: newPaginationConfig,
    });
    const payload = {
      page: newPaginationConfig.pageIndex,
      limit: newPaginationConfig.pageSize,
      filters,
      group: currentGroupingValue,
      searchKeyword,
    };
    fetchGroupingTableData(undefined, payload);
  }

  const { table } = useDataTable({
    data,
    isLoading,
    searchKeyword: "",
    pagination: paginationConfig,
    rowCount: meta?.total ?? 0,
    reactTableProps: {
      columns,
      onPaginationChange,
      muiPaginationProps: {
        rowsPerPageOptions: [20],
      },
    },
  });

  return (
    <MaterialReactTable
      key={`${meta.total}`}
      table={table}
      isLoading={isLoading}
    />
  );
};
