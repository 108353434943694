import { OverviewSection } from "./styled";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { Tooltip } from "@mui/material";
import { InfoIconRoot } from "./SummaryCard/styled";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export const SectionCard = ({
  children,
  containerProps = {},
  fieldInfo = "",
}) => {
  return (
    <OverviewSection {...containerProps}>
      {children}
      <ConditionComponent condition={fieldInfo}>
        <Tooltip placement='top' title={fieldInfo}>
          <InfoIconRoot>
            <TipsAndUpdatesIcon />
          </InfoIconRoot>
        </Tooltip>
      </ConditionComponent>
    </OverviewSection>
  );
};
