import { getValueByPath } from "../../../containers/inventory/AssetsTable/utils/getGraphColumnValuesFromData";

function getPropertyLabel(prop) {
  if (prop.startsWith("adapters.")) return prop.split("adapters.")[1];
  return prop;
}

const defaultComboLabel = "not-applicable";

const checkValueExists = (val) => ![undefined, null].includes(val);

export function createCombosFromNodes(nodes, properties) {
  if (!properties) {
    return nodes;
  }
  const comboMap = new Map();
  nodes.forEach((node, nodeIndex) => {
    let currentMap = comboMap;
    let parentId;
    properties.forEach((property, propertyIndex) => {
      let value = getValueByPath(node, property);
      if (!value?.length || !value) value = defaultComboLabel;
      if (Array.isArray(value)) {
        value.forEach((val) => {
          let comboValue = val;
          if (!checkValueExists(comboValue)) comboValue = defaultComboLabel;
          if (!currentMap.has(comboValue)) {
            const id = `combo${nodeIndex}_${propertyIndex}`;
            let labelProperty = getPropertyLabel(property);
            const newCombo = {
              id,
              label: [comboValue + " " + "(" + labelProperty + ")"],
              children: new Map(),
            };
            if (parentId !== undefined) {
              newCombo.parentId = parentId;
            }
            currentMap.set(comboValue, newCombo);
          }
          const combo = currentMap.get(comboValue);
          parentId = combo.id;
          currentMap = combo.children;
          if (propertyIndex === properties.length - 1) {
            node.comboId = combo.id;
          }
        });
      } else {
        if (!currentMap.has(value)) {
          const id = `combo${nodeIndex}_${propertyIndex}`;
          let labelProperty = getPropertyLabel(property);
          const newCombo = {
            id,
            label: [value + " " + "(" + labelProperty + ")"],
            children: new Map(),
          };
          if (parentId !== undefined) {
            newCombo.parentId = parentId;
          }
          currentMap.set(value, newCombo);
        }
        const combo = currentMap.get(value);
        parentId = combo.id;
        currentMap = combo.children;
        if (propertyIndex === properties.length - 1) {
          node.comboId = combo.id;
        }
      }
    });
  });

  const combos = [];
  const addCombosToArray = (map) => {
    map.forEach((combo) => {
      combos.push(combo);
      addCombosToArray(combo.children);
    });
  };
  addCombosToArray(comboMap);
  return combos;
}
