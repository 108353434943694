import { SystemInsights } from "../../../../utils/logger/SystemInsights";
import { useAssetTableState } from "./useAssetTableState";
import { useGroupingTableState } from "./useGroupingTableState";
import { useAssetsTableMethods } from "./useTableMethods";

export function useFetchGroupingTableData() {
  const { fetchGroupingTableData } = useAssetsTableMethods();
  const {
    searchKeyword,
    filters,
    currentGroupingOption,
    grouping,
    defaultPayload = {},
  } = useAssetTableState();
  const { paginationConfig: pagination } = useGroupingTableState();

  const func = (_payload, options = {}) => {
    const payload = {
      ...defaultPayload,
      search: searchKeyword,
      filters,
      pagination,
    };
    SystemInsights.log("hook useFetchGroupingTableData ", {
      _payload,
      options,
    });
    const group = grouping[0];
    if (group && currentGroupingOption) {
      payload.grouping = { group, option: currentGroupingOption };
    }

    fetchGroupingTableData(
      { ...payload, ..._payload },
      { saveView: true, ...options }
    );
  };
  return func;
}
