import React from "react";
import { Box, Typography } from "@mui/material";
import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import yourLogo from "../../assets/images/AWSDark.svg";
import IntegrationMethod from "../../components/onboardingV2/awsIntegration/integrationMethod";
import cloudFormationLogo from "../../assets/images/cloudFormation.svg";
import terraformLogo from "../../assets/images/terraform.svg";
import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import createButtonConfig from "../../shared/functions/buttons";
import SettingsIcon from "@mui/icons-material/Settings";
import { Root } from "../googleWorkspaceIntegration/styled";

const AwsIntegration = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const headerLogo = yourLogo;
  const headerTitle = "Choose Your Preferred Integration Method";
  const [integrationMethodPickerOpen, setIntegrationMethodPickerOpen] =
    React.useState(false);
  const [integrationOpen, setIntegrationOpen] = React.useState(false);
  const [sidebarStep, setSidebarStep] = React.useState(1);

  const handleIntegrationOpen = () => {
    setIntegrationMethodPickerOpen(false);
    setIntegrationOpen(true);
  };

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  // Sidebar
  const steps = [
    {
      title: "Integration Method",
      description:
        "Select the preferred method for integrating your AWS account",
    },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={steps} currentStep={sidebarStep} />
  );

  const content = (
    <Box sx={{ display: "flex", gap: "50px", paddingLeft: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography
          variant='body1'
          className='integrationMethodDesc'
          sx={{ mt: "21.5px" }}
        >
          In this step, we're requesting ReadOnlyAccess access to your AWS
          account.
        </Typography>
        <Typography variant='body1' className='integrationMethodDesc'>
          Please enter the AWS details for integration with our platform or use
          CloudFormation automation.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "21px",
          width: "1px",
          bgcolor: "#D9D9D9",
        }}
      />

      <Box className='integrationMethodWrapper'>
        <IntegrationMethod
          logo={cloudFormationLogo}
          name='CloudFormation'
          onClick={() => history.push("/integrations/aws/cloudformation")}
        />
        <IntegrationMethod
          logo={<SettingsIcon />}
          name='Manual'
          onClick={() => history.push("/integrations/aws/manual")}
        />
        <IntegrationMethod
          logo={terraformLogo}
          name='Terraform'
          onClick={handleIntegrationOpen}
          disabled
        />
      </Box>
    </Box>
  );

  const history = useHistory();
  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
  };

  const footerButtons = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
  ];

  return (
    <Root>
      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={headerLogo}
        headerTitle={headerTitle}
        content={content}
        footerButtons={footerButtons}
      />
    </Root>
  );
};

export default AwsIntegration;
