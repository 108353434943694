import { useUserInfo } from "../../../hooks/useAppUser";
import GraphPage from "../../../views/G6Graph/G6Graph";
import GraphV1 from "./v1";

const Graph = (props = {}) => {
  const { graphVersion } = useUserInfo();

  if (graphVersion === 1) return <GraphV1 {...props} />;
  return <GraphPage {...props} />;
};

export default Graph;
