import { CoreDataTypes } from "../../../../core/data-types";
import { format } from "date-fns";
import { checkValueExists } from "../../../../utils/helperMethods/type-guards";

function transformAssigneesFromInfo(assignees = []) {
  return assignees.map((info) => info.name);
}

function parseDate(dateStr) {
  try {
    const formatted = format(dateStr, "EEEE, do LLL yyyy");
    return formatted ?? CoreDataTypes.N_A;
  } catch (error) {
    return dateStr ?? CoreDataTypes.N_A;
  }
}

const getFieldValues = (value, transformValue) => {
  const exist = checkValueExists(value) && value !== "None";
  const payload = {
    value: exist ? value : CoreDataTypes.N_A,
    reason: "",
  };
  if (Array.isArray(value)) {
    let firstEntry = value[0] ?? CoreDataTypes.N_A;
    if (firstEntry === "") firstEntry = CoreDataTypes.N_A;
    const reason = value[1]?.reason;
    const reasonDoesNotExist =
      !reason || reason?.toLowerCase() === "no reason provided";
    let payloadValue = firstEntry;
    if (transformValue) {
      payloadValue = transformValue(payloadValue);
    }
    payload.value = payloadValue;
    payload.reason = reasonDoesNotExist ? null : reason;
  }

  return payload;
};

function bool(value, trueValue, falseValue) {
  if (value) return trueValue;
  return falseValue;
}

const withRecommendation = (val) => {
  return bool(val, "Recommended", "Not Recommended");
};
const withYesNo = (val) => bool(val, "Yes", "No");

export function createAssetInfo(payload) {
  const {
    assignees_info = [],
    description,
    summary,
    issue_id,
    platform_summary = {},
    reporter_profile = {},
    status,
    comments,
  } = payload;

  const issueProfile = {
    id: issue_id,
    projectName: "",
    securityApprovalRequired: getFieldValues(
      platform_summary?.security_approval_required,
      withYesNo
    ),
    securityReviewRequired: getFieldValues(
      platform_summary?.security_review_required,
      withYesNo
    ),
    workSuspensionRequired: getFieldValues(
      platform_summary?.work_suspension_required,
      withRecommendation
    ),
    securityRelevanceScore: getFieldValues(
      platform_summary?.security_relevance_score
    ),
    urgencyScore: getFieldValues(platform_summary?.urgency_score),
    dataType: getFieldValues(platform_summary?.data_type),
    generalOverview: getFieldValues(platform_summary?.general_overview),
    securityRationale: getFieldValues(platform_summary?.security_rationale),
    relatedAssets: getFieldValues(platform_summary?.related_assets),
    securityTasks: getFieldValues(platform_summary?.security_tasks),
    securityRisks: getFieldValues(platform_summary?.security_risks),
    changeType: getFieldValues(platform_summary?.change_classification),
    jiraTicket: {
      reporter: reporter_profile?.name || CoreDataTypes.N_A,
      summary: summary || CoreDataTypes.N_A,
      assignees: transformAssigneesFromInfo(assignees_info),
      description: description?.trim("") || CoreDataTypes.N_A,
      tags: [],
      status: status?.name ?? CoreDataTypes.N_A,
      comments: comments,
    },
    createdAt: parseDate(payload?.created_at),
    updatedAt: parseDate(payload?.updated_at),
  };

  return issueProfile;
}
