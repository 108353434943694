import { useMemo } from "react";
import {
  AvatarSection,
  DateText,
  DetailsFooterRoot,
  DetailsRoot,
  Label,
  MetaRoot,
  NameText,
  TicketRoot,
  TicketText,
} from "./styled";

export const TicketComment = ({ data }) => {
  const { body, author, update_author, created, updated } = data;

  const avatar = useMemo(() => {
    return getInitials(author.display_name);
  }, []);

  return (
    <TicketRoot>
      <AvatarSection>{avatar}</AvatarSection>
      <DetailsRoot>
        <MetaRoot>
          <NameText>{author.display_name}</NameText>
          <DateText>{created}</DateText>
        </MetaRoot>
        <TicketText>{body}</TicketText>
        <DetailsFooterRoot>
          <Label>Updated on</Label>
          <DateText>{updated}</DateText>
          <Label>, On</Label> <NameText>{update_author?.display_name}</NameText>
        </DetailsFooterRoot>
      </DetailsRoot>
    </TicketRoot>
  );
};

function getInitials(name) {
  if (name.startsWith("Automation")) {
    return "Jira";
  }
  const asArr = name.split(" ");
  return asArr.reduce((cum, curr) => {
    const firstChar = curr.charAt(0);
    return cum + firstChar;
  }, "");
}
