import { createRef, Fragment, useRef, useState } from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { AssetInventoryTableFixedColumns } from "../../../../../core/asset-inventory/data";
import { AssetInventoryValidators } from "../../../../../core/asset-inventory/utils/AssetInventoryDataValidator";
import { InventoryTableActionButton } from "../../../../../ui/pages/inventory/components/ActionButton";
import { DataGroupingMenu } from "../../../../../components/Grouping/components/Menu";

const SelectColumnsInventorySelector = createRef();

export const SelectColumns = ({
  selectedValues,
  setSelectedValues,
  schema,
  tooltipProps = {},
  title,
  buttonProps = {
    icon: <ViewColumnIcon />,
    disabled: false,
  },
  ...props
}) => {
  const menuContainerRef = useRef(null);
  const [selectorOpened, setSelectorOpened] = useState(false);

  const handleCloseMenu = () => setSelectorOpened(false);

  const handleOpenSelector = () => {
    menuContainerRef.current.onOpenMenu?.();
    setSelectorOpened(true);
  };

  const optionFilter = (option) => {
    return !AssetInventoryTableFixedColumns.find(
      (col) => col.value === option.value && option.source === col.source
    );
  };

  const onSelectValue = (values) => {
    const isWithinLimit =
      AssetInventoryValidators.validateSelectedColumnsAgainstMaxLimit(
        values.length
      );
    console.log({ isWithinLimit, values });
    if (isWithinLimit) {
      setSelectedValues(values);
    } else {
      // show maximum column limit exceed warning
    }
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        ref={SelectColumnsInventorySelector}
        actionCount={selectedValues.length}
        tooltipProps={tooltipProps}
        onClick={handleOpenSelector}
        icon={buttonProps.icon}
        disabled={buttonProps.disabled}
      />
      <DataGroupingMenu
        title={title}
        optionFilter={optionFilter}
        bindings={menuContainerRef}
        data={schema}
        handleCloseMenu={handleCloseMenu}
        menuOpened={selectorOpened}
        onSelectValue={onSelectValue}
        onClearGrouping={handleCloseMenu}
        selectOption={null}
        value={selectedValues}
        setValue={onSelectValue}
        viewConfig={{ showCurrentGroupings: true }}
        anchorEl={SelectColumnsInventorySelector.current}
        {...props}
      />
    </Fragment>
  );
};
