import { Button, styled, Typography } from "@mui/material";
import { PageLayout } from "../../../../shared-component/PageLayout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CoreDataTypes } from "../../../../../core/data-types";

export const InsightsSection = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 24px 0;
  min-height: 120px;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 24px;

  padding: 0;
`;

export const InsightsBlocksRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 24px;
`;

export const PageRoot = styled(PageLayout)(({ theme }) => {
  return `
  display: flex;
  flex-direction: column;
  position: relative;
`;
});

export const PageContent = styled("div")`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  flex-shrink: 0;
  margin-top: 24px;
  box-sizing: border-box;
  row-gap: 20px;
  column-gap: 20px;
  padding-right: 8px;
  box-sizing: border-box;
  padding-bottom: 80px;
`;

const Section = styled("div")(({ theme }) => {
  return `
border-radius: 12px;
box-shadow: ${theme.shadows[1]};
background-color: #fff;
padding: 12px;
`;
});

export const IssueInfoContainer = styled(Section)``;
export const RequestorSummaryInfo = styled(Section)``;
export const SummaryRoot = styled(Section)`
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  min-height: 100px;
`;
export const RequestorUserInfo = styled(Section)``;
export const AssetSummary = styled(Section)`
  display: flex;
  flex-direction: column;
`;
export const SecurityAssessment = styled(Section)`
  min-height: 200px;
`;
export const JiraRequestInfo = styled(Section)`
  min-height: 200px;
`;
export const PotentialRisksSection = styled(Section)``;
export const AiAnalysisSection = styled(Section)`
  min-height: 120px;
`;
export const FooterContainer = styled("div")(({ theme }) => {
  return `
  margin-top: 24px;
  height: 80px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 24px);
  background-color: #fff;
  border-top:1px solid ${theme.palette.grey["400"]};
  display: flex;
  align-items:center;
  column-gap: 12px;
`;
});

export const Col = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Row = styled("div")`
  display: flex;
`;

export const InfoLabel = styled(({ children, ...props }) => (
  <Typography variant='h6' {...props}>
    {children}
  </Typography>
))`
  font-weight: 600;
  margin-right: 8px;
  height: 24px;
  white-space: nowrap;
`;

export const ContentRoot = styled("div")`
  margin-top: 12px;
`;
export const RequestorProfile = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 12px;
  column-gap: 16px;
`;

export const UserDetailsRoot = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const UserAvatar = styled(AccountCircleIcon)`
  width: 40px;
  height: 40px;
  border-radius: 22px;
`;

export const DescriptionText = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))`
  margin-top: 8px;
`;

export const IssuesSummaryContainer = styled("div")(({ theme }) => {
  const bg = theme.palette.background.paper;
  return `
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    row-gap: 18px;
    column-gap: 18px;
    border-radius: 10px;
    background-color: ${bg};
  `;
});

export const SummaryCardRoot = styled("div")(
  ({ theme, bg: _bg, borderColor }) => {
    const bg = _bg ?? "#fff";
    return `
  border-radius: 8px;
  padding: 12px;
  min-width: 230px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  background-color: ${bg};
  min-height: 120px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-color: ${borderColor};
    `;
  }
);

export const SummaryCardLabel = styled(({ children, ...props }) => (
  <Typography variant='h6' {...props}>
    {children}
  </Typography>
))`
  font-weight: 500;
`;

export const SummaryCardText = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))``;

export const PageOverviewSection = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 24px;
  row-gap: 24px;
`;

export const OverviewSection = styled("div")(({ theme }) => {
  const bg = theme.palette.background.paper;

  return `
  background-color: ${bg};
  width: calc(50% - 48px);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-height: 500px;
  padding: 18px;
  border-radius: 8px;
  position: relative;
  `;
});

export const LongTextContainer = styled("div")(() => {
  return `
  max-height: 480px;
  `;
});

export const OverviewLabel = styled(({ children, ...props }) => (
  <Typography variant='h5' {...props}>
    {children}
  </Typography>
))`
  font-weight: 500;
`;

export const OverviewText = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))(({ children, theme }) => {
  if (children === CoreDataTypes.N_A) {
    return `
    color: ${theme.palette.grey[500]};
`;
  }
  return ``;
});

export const OverviewChipsSection = styled("div")`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
`;

export const DateSection = styled(OverviewSection)(() => {
  return `
  flex-direction: row;
  column-gap: 24px
  `;
});

export const DateContainer = styled("div")`
  width: fit-content;
`;

export const JiraTicketSection = styled(OverviewSection)(({ theme }) => {
  return `
  width: 100%;
  max-height: 700px;
  box-sizing: border-box;
  `;
});

export const JiraTagsSection = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const OverviewSubHeading = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))`
  font-weight: 500;
`;

export const TicketInfoSectionRoot = styled("div")(() => {
  return `
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 12px;
  justify-content: space-between;
  `;
});

export const TicketOverviewSectionRoot = styled("div")(() => {
  return `
  display: grid; 
  grid-template-columns: 276px calc(100% - 300px);
  max-height: 600px;
  column-gap: 24px;
  margin-top: 24px;
  `;
});

export const TicketInfoSection = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-width: 150px;
  `;
});
export const TicketDescriptionRoot = styled(TicketInfoSection)(() => {
  return `
  width: 100%;
  `;
});

export const TicketDescriptionSection = styled("div")(() => {
  return `
max-height: 400px;
width: 100%;
box-sizing: border-box;
line-break: anywhere;
padding: 4px;
  `;
});

export const ActionsContainer = styled(JiraTicketSection)(() => {
  return `
  margin: 24px 0;
  
  `;
});

export const ActionButtonsRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
`;

export const ActionButton = styled(Button)(({ bgColor }) => {
  return `
  min-width: 150px;
  background-color: ${bgColor};
  color: #fff;
  `;
});

export const Heading = styled((props) => <Typography {...props} />)(
  ({ num = 1 }) => {
    let size = 18;
    if (num > 1) {
      size -= num * 2;
    }
    return `
  font-weight: 500;
  color: #000;
  font-size:${size}px;
  `;
  }
);

export const TagsContainer = styled("div")(() => {
  return `
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  `;
});

export const CommentsSection = styled(OverviewSection)(() => {
  return `
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 60%;
    height: auto;
    max-height: 700px;
    box-sizing: border-box;
  `;
});

export const CommentsListRoot = styled(CommentsSection)(() => {
  return `
margin-top: 12px;
max-width: 100%;
  `;
});
