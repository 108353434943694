import { capitalize, Tooltip } from "@mui/material";
import { DataGroupsSources } from "../../../../components/Grouping/data";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { StringUtils } from "../../../../utils/typeUtils/StringUtils";
import { CellContainer, HeaderCellContainer, TruncatedCell } from "../styled";
import { TableCellTooltipContent } from "../components/TableCellTooltip";
import { DataCount } from "../../../../components/DataCount";
import { NonAvailableValue } from "../../../../core/data";
import { JSONUtils } from "../../../../utils/typeUtils/JSONUtils";
import {
  checkValueExists,
  isPrimitiveValue,
} from "../../../../utils/helperMethods/type-guards";
import { shortenColumnName } from "../../../../app/asset-management/shortenColumnName";
import { getObjectValueByPath } from "../../../../utils/helperMethods/getObjectValueByPath";

const rowDataAdaptersFieldName = "adapters";

const isNestedField = (val) => val.includes(".");

const getNestedFieldAccessorKey = (field) => {
  return field.split(".")[0];
};

export function getValueByPath(obj, path) {
  const pathArray = Array.isArray(path) ? path : path.split(".");

  return pathArray.reduce((current, key, arr) => {
    if (!current) return null;
    if (Array.isArray(current)) {
      return current.map((data) => data?.[key]);
    }
    if (typeof current === "object") {
      return current?.[key] ?? null;
    }
    return null;
  }, obj);
}

function createColumnAccessorKey(column) {
  const { source, value } = column;
  if (source === DataGroupsSources.PLATFORM) {
    if (isNestedField(value)) {
      return getNestedFieldAccessorKey(value);
    } else {
      return value;
    }
  } else return rowDataAdaptersFieldName;
}

function createDataAccessorPath(value) {
  return value.split(".");
}

function createPlatformValuePath(value) {
  return value.split(".").slice(1).join("");
}

/**
 *
 * @param {*} value
 * @returns
 * @description slicing from 1 since index 0 sets the accessor key
 */
function getPlatformValues(column, accessorValue) {
  const rawDataPath = createDataAccessorPath(column.value);
  const dataPath = rawDataPath?.slice(1); // to remove accessor key path
  if (!dataPath.length) return accessorValue;
  const path = createPlatformValuePath(column.value);
  return getObjectValueByPath(accessorValue, path);
}

function getAdapterValues(column, accessorValue) {
  const { source, value } = column;
  const dataPath = createDataAccessorPath(`${source}.${value}`);
  if (!dataPath.length) return accessorValue;
  const path = `${source}.${value}`;

  const res = getObjectValueByPath(accessorValue, path);
  return res;
}

function sanitizeFieldValues(values) {
  if (Array.isArray(values)) {
    return values.reduce((results, value) => {
      const valueDoesNotExist =
        value === null ||
        value === undefined ||
        value?.toLowerCase?.() === "none";
      if (valueDoesNotExist) return results;
      if (typeof value === "string") {
        results.push(value);
      } else {
        const valueAsStr = JSONUtils.stringify(value);
        results.push(valueAsStr);
      }
      return results;
    }, []);
  }

  if (isPrimitiveValue(values)) return [StringUtils.castToString(values)];
  return [];
}

function getCellValues(column, accessorValue) {
  const { source } = column;
  let values;
  if (source === DataGroupsSources.PLATFORM) {
    values = getPlatformValues(column, accessorValue);
  } else values = getAdapterValues(column, accessorValue);
  if (column.value.includes("Cidr")) {
    console.log({ values });
  }
  if (checkValueExists(values)) {
    return sanitizeFieldValues(values);
  }
  return [];
}

function createColumnCell(column) {
  return function ({ cell }) {
    const accessorValue = cell.getValue();
    const cellValues = getCellValues(column, accessorValue);
    const hasMultipleValues = cellValues.length > 1;
    const remainder = cellValues.length - 1;
    const displayLabel = cellValues[0] ?? NonAvailableValue;
    return (
      <CellContainer>
        <TruncatedCell>{displayLabel}</TruncatedCell>
        <ConditionComponent condition={hasMultipleValues}>
          <Tooltip
            placement='top'
            title={<TableCellTooltipContent data={cellValues} />}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <div>
              <DataCount data={{ count: remainder }} />
            </div>
          </Tooltip>
        </ConditionComponent>
      </CellContainer>
    );
  };
}

const createColumnHeading = (column) => {
  const { source, value } = column;
  let label = StringUtils.capitalize(value);
  if (source !== DataGroupsSources.PLATFORM) return `${source}.${label}`;
  return label;
};

function createColumnHeader(column) {
  return () => {
    const displayName = shortenColumnName(column.value);
    const fullLabel = capitalize(column.value);
    return (
      <HeaderCellContainer>
        <Tooltip
          placement='top'
          title={fullLabel}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
        >
          <div>
            <TruncatedCell>{displayName}</TruncatedCell>
          </div>
        </Tooltip>
      </HeaderCellContainer>
    );
  };
}

function createColumn(column) {
  const header = createColumnHeading(column);
  const accessorKey = createColumnAccessorKey(column);
  const Cell = createColumnCell(column);
  const Header = createColumnHeader(column);
  return {
    header,
    id: column.id,
    Header,
    accessorKey,
    Cell,
  };
}

export function createGraphColumnValuesFromData(columns) {
  return columns.map((column) => {
    return createColumn(column);
  });
}
