import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { StrictMode } from "react";
import StateManagementProvider from "./state-management";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  skipRedirectCallback: window.location.pathname === "/jira_auth", // Add this line
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const appContainerNode = document.getElementById("root");
if (appContainerNode) {
  const appRoot = createRoot(appContainerNode);
  appRoot.render(
    <StrictMode>
      <Auth0Provider {...providerConfig}>
        <StateManagementProvider>
          <App />
        </StateManagementProvider>
      </Auth0Provider>
    </StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
