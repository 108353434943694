import React from "react";
import ConditionComponent from "../../../utils/ConditionComponent";
import { Grouping } from "./components/Grouping";
import { InventoryTable } from "./components/InventoryTable/index";
import { useIsGrouping } from "./hooks/useIsGrouping";

export const AssetTableContent = () => {
  const isGrouping = useIsGrouping();

  return (
    <div>
      <ConditionComponent condition={isGrouping} falsyNode={<InventoryTable />}>
        <Grouping />
      </ConditionComponent>
    </div>
  );
};
