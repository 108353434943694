import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Drawer,
  InputBase,
  styled,
  Typography,
} from "@mui/material";

export const AssetInfoContainer = styled(Drawer)`
  & .MuiPaper-root {
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    width: 750px;
    display: flex;
    flex-direction: column;
  }
`;

export const FieldLabel = styled((props) => (
  <Typography variant='subtitle2' {...props} />
))`
  text-transform: none;
`;

export const SubPageHeading = styled((props) => (
  <Typography variant='h6' {...props} />
))`
  text-transform: none;
`;

export const SubPageSubHeading = styled((props) => (
  <Typography variant='subtitle1' {...props} />
))`
  && {
    text-transform: none;
    font-weight: 600;
  }
`;

export const ValueText = styled((props) => (
  <Typography variant='body2' {...props} />
))(({ isna }) => {
  const color = isna ? "#a9a9a9" : "#000";
  return `
  color: ${color};
  `;
});

export const NanValueText = styled((props) => (
  <Typography variant='body2' {...props} />
))`
  color: #a9a9a9;
`;

export const ActiveRouteLabel = styled((props) => (
  <Typography variant='body1' {...props} />
))``;

export const InactiveRouteLabel = styled((props) => (
  <Typography variant='body1' {...props} />
))`
  color: rgb(107, 114, 128);
`;

export const ValueContainer = styled("div")`
  padding: 8px;
  padding-left: 16px;
`;

export const MarkdownContainer = styled("div")`
  background-color: rgb(249, 250, 251);
  border: 1px solid rgb(229, 231, 235);
  padding: 8px !important;
  padding-right: 12px !important;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto !important;
`;

export const FieldDataContainer = styled("div")`
  border: 1px solid rgb(229, 231, 235);
  border-radius: 4px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  align-items: stretch;
  background-color: rgb(249, 250, 251);
`;

export const SourceFlag = styled("div")`
  background-color: #fff;
  position: absolute;
  left: 8px;
  top: -8px;
  font-size: 10px;
  z-index: 10;
  padding: 0 4px;
  font-weight: 500;
  color: #00000080;
`;

export const NavContainer = styled("nav")`
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 4px 8px;
  align-items: center;
  margin-top: 12px;
`;

export const NavItemContainer = styled("li")(({ active }) => {
  const bgColor = active ? "#fff" : "transparent";
  const color = active ? "#000" : "rgb(107, 114, 128)";
  return `
  background-color: ${bgColor};
  text-align:center;
  font-size: 12px;
  color: ${color};
  justify-content: center;
  border-radius: 8px;
  list-style-type: none;

  :hover{
  cursor: pointer;
  background-color: ${bgColor};

  }
  `;
});

export const CopyButtonContainer = styled(ButtonBase)(({ copied }) => {
  const bgColor = copied ? "#00A372" : "#fff";
  const color = copied ? "#fff" : "#000";
  return `
  && {
    &.MuiButtonBase-root {
      background-color: ${bgColor};
      margin-left: auto;
      width: 32px;
      color: ${color};
      &:focus{ 
      outline: none;
      }
      & > svg {
        height: 14px;
      }
    }
  }
`;
});

export const DrawerHeader = styled("header")`
  padding-left: 24px;
  padding-right: 15px;
  height: 36px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 22px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #c7c7c7;
  font-weight: 700;
  line-height: 27px;
  background-color: #002733;
`;

export const CloseButton = styled(ButtonBase)`
  margin-left: auto !important;
  :active {
    outline: none;
  }
`;

export const DrawerBody = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  row-gap: 12px;
  overflow: hidden;
  background-color: #fff;
`;

export const DataSection = styled("div")`
  display: flex;
  flex-direction: column;
  & > :nth-child(2) {
    margin-top: 8px;
  }
`;

export const RowContainer = styled("div")(({ gap, wrap }) => {
  const _gap = gap ?? "24px";
  const row_gap = wrap ? "12px" : "0";
  const flexWrap = wrap ? "wrap" : "0";
  return `
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: ${_gap};
  row-gap: ${row_gap}
  flex-wrap: ${flexWrap};
`;
});

export const SubPageContainer = styled("div")`
  flex: 1;
  overflow: hidden;
  display: grid;
  row-gap: 12px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`;

export const SubPageContentContainer = styled("div")`
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 8px;
`;

export const BusinessPurposeSection = styled("div")`
  height: 200px;
`;

export const TextField = styled("textarea")(({ isNa }) => {
  const color = isNa ? "#a9a9a9" : "#000";

  return `
  width: 100%;
  border-radius: 4px;
  height: 54px;
  background-color: rgb(249, 250, 251);
  border: 1px solid rgb(229, 231, 235);
  font-size: 0.875rem;
  font-weight 400;
  padding: 6px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: ${color};
  :focus {
    outline: none;
  }
`;
});

export const CommandJsonContainer = styled(Accordion)(() => {
  return `
  &.MuiAccordion-root {
  border: 1px solid rgb(229, 231, 235);
  box-shadow: none;
  flex:1;
  border-radius: 8px;
  display: flex;
  width: 100%;
  margin: 0;
  ::before {
    height: 0; 
  }
  }
  `;
});

export const CommandJsonSummary = styled(AccordionSummary)(() => {
  return `
  &.MuiAccordionSummary-root {
  }
  `;
});

export const CommandJsonContent = styled(AccordionDetails)(() => {
  return `
  &.MuiAccordionDetails-root {
  border: none;
  padding: 0 !important;
  
  }
  `;
});

export const SearchInput = styled(InputBase)`
  width: 100%;
  height: 100%;
  font-size: 14px;
`;

export const JsonViewerContainer = styled("div")`
  background-color: rgb(42, 47, 58);
  padding: 4px 12px 0;
`;

export const JsonUtilsContainer = styled("div")`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  padding: 0 24px;
`;

export const SearchContainer = styled("div")`
  margin-left: auto;
  display: flex;
  color: #a9a9a9 !important;
  align-items: center;
  padding-left: 4px;
  padding-right: 8px;
  height: 32px;
  min-width: 250px;

  border-radius: 4px;
  border: 1px solid #ccc;
  background: #f6f7f7;

  margin-right: 12px;
`;

export const UnorderedList = styled("ul")``;

export const ListItem = styled("li")`
  list-style-type: circle;
  list-style-position: inside;
  display: list-item;
  :hover {
    background-color: inherit;
  }
`;

export const ListSection = styled("div")``;

export const ShortTableContainer = styled("div")``;

export const TicketSummaryContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DateSection = styled("div")`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  column-gap: 8px;
  height: 24px;
`;
export const Circle = styled("div")(({ size, bgColor }) => {
  const _size = size ?? "16px;";
  const color = bgColor ?? "#a9a9a9";

  return `
  width: ${_size};
  height: ${_size};
  background-color: ${color};
  border-radius: 999999px;
  flex-shrink: 0;`;
});

export const DateLabel = styled((props) => (
  <Typography variant='caption' {...props} />
))`
  color: #a9a9a9;
`;

export const Divider = styled("div")(() => {
  return `
  // display: flex;
  // width: 0.5px;
  // background-color: #d9d9d9;
  // position: relative;
  // top: 24px;
  width: 2px;
  height: 100%;
  background-color: red;
  `;
});

export const TicketDetailsSection = styled("div")(({ opened }) => {
  return `
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  row-gap: 2px;
  position: relative;
  padding: 2px;
  border-radius: 4px;
  :hover {
    background-color: #d9d9d940;
    cursor: pointer;
  }
`;
});

export const ViewFullDescriptionLink = styled(Button)`
  && {
    margin-left: auto;
    color: blue;
    font-weight: 300;
    font-size: 12px;
  }
`;
