import { useUserInfo } from "../../../hooks/useAppUser";
import { PageLayout } from "../../shared-component/PageLayout";
import { AppFeatures } from "../../../core/features";
import ConditionComponent from "../../../utils/ConditionComponent";
import {
  BusinessProcessesManagementSection,
  IntegrationsSection as IntegrationsRoot,
  UserManagementSection,
} from "./styled";
import BPSettingSection from "../../../components/settings/BPSection";
import UserManagementSettingSection from "../../../components/settings/UserManagement";
import IntegrationsSection from "../../../components/settings/IntegrationsSection";

const Settings = () => {
  const userInfo = useUserInfo();

  const showUserManagement = userInfo.privileges[AppFeatures.USER_MGMT];
  const showBusinessProcessManagement =
    userInfo.privileges[AppFeatures.BP_SETTINGS];

  return (
    <PageLayout className='scrollable'>
      <ConditionComponent condition={showBusinessProcessManagement}>
        <BusinessProcessesManagementSection>
          <BPSettingSection />
        </BusinessProcessesManagementSection>
      </ConditionComponent>
      <ConditionComponent condition={showUserManagement}>
        <UserManagementSection>
          <UserManagementSettingSection />
        </UserManagementSection>
      </ConditionComponent>

      <IntegrationsRoot>
        <IntegrationsSection />
      </IntegrationsRoot>
    </PageLayout>
  );
};

export default Settings;
