import { Typography } from "@mui/material";
import {
  InsightsSection,
  PageContent,
} from "../../../shared-component/CMSTLayout";
import { InsightsBlocksRoot } from "./component/styled";
import { CMSTInsightCard } from "../../../shared-component/CMSTInsightCard";
import AssetTable from "../../../shared-component/AssetTable";
import { DefaultCMSTIssuesTableColumns } from "../../../../core/cmst/issues-table/defaultColumns";
import { CMSTService } from "../../../../services/CMSTService";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";
import { useCMSTIssuesState } from "../hooks/useCMSTIssuesState";

export const EntryView = () => {
  const { push } = useAppNavigator();
  const { summary, schema, saveQueryPayload } = useCMSTIssuesState();

  const TableService = {
    getTableData: CMSTService.getInstance().getAllIssues,
    saveQuery: CMSTService.getInstance().saveQuery,
  };

  const handleOnClickAsset = (asset) => {
    const id = asset.data?.id;
    if (!id) {
      /**
       * @todo show warning alert to user and throw log.
       * Consider an advanced error logging system that will alert devs of engineering error
       */
      return;
    }
    const projectId = asset.data.project.id;
    const path = `/cmst/projects/${projectId}/issues/${id}`;
    push(path, asset);
  };

  const saveQueryActions = {
    save: CMSTService.getInstance().saveQuery,
    edit: CMSTService.getInstance().editQuery,
  };

  return (
    <PageContent className='scrollable'>
      <InsightsSection>
        <Typography variant='h4'>Tickets Profile</Typography>
        <InsightsBlocksRoot>
          <CMSTInsightCard
            label='Tickets with security concerns'
            value={summary?.issues_with_security_concerns}
            variant='error'
          />
          <CMSTInsightCard
            label='Total Tickets'
            value={summary?.issues}
            variant='default'
          />
          <CMSTInsightCard
            label='Stake holders involved Tickets'
            value={summary?.stakeholders}
            variant='success'
          />
        </InsightsBlocksRoot>
      </InsightsSection>
      <AssetTable
        key='cmst_tickets'
        table='cmst_tickets'
        defaultColumns={DefaultCMSTIssuesTableColumns}
        schema={schema}
        service={TableService}
        onClickAsset={handleOnClickAsset}
        saveQueryActions={saveQueryActions}
        saveQueryPayload={saveQueryPayload}
      />
    </PageContent>
  );
};
