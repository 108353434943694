import awsLogo from "../../../assets/images/AWSDark.svg";
import React, { Fragment } from "react";
import IntegrationMethodDescription from "./IntegrationMethodDescription";
import AccountInput from "./AccountInput";
import MakeSureSection from "./MakeSureSection";
import AwsErrorBox from "./AwsErrorBox";
import { useAwsCloudFormationIntegrationValues } from "../hooks/useAwsCloudFormationIntegrationValues";

export const AwsCloudformationStepOneContent = ({
  account,
  handleAccountChange,
  isGenerated,
  awsError,
  role,
  externalId,
  selfRole,
  handleRoleChange,
  handleExternalIdChange,
  handleSelfRoleChange,
}) => {
  const { isManual } = useAwsCloudFormationIntegrationValues();

  return (
    <Fragment>
      <IntegrationMethodDescription />
      <MakeSureSection />
      <AccountInput
        account={account}
        handleAccountChange={handleAccountChange}
        isGenerated={isGenerated}
        role={role}
        externalId={externalId}
        selfRole={selfRole}
        handleRoleChange={handleRoleChange}
        handleExternalIdChange={handleExternalIdChange}
        handleSelfRoleChange={handleSelfRoleChange}
      />
      <AwsErrorBox awsError={awsError} />
    </Fragment>
  );
};

export const stepOneConfig = {
  awsLogo: awsLogo,
  getHeaderTitle: (isManual) => 
    isManual ? "Integrating AWS using Manual" : "Integrating AWS using CloudFormation",
};
