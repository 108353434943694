import { Button, Checkbox, FormGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ComboSection, Label } from "../FilterSelector/styled";
import {
  ButtonGroupContainer,
  DataFilteringFooterContainer,
} from "../../../../components/Filtering/styled";

export const DataFilteringFooter = ({
  onClickAddFilter,
  onClickApplyFilters,
  onClickResetFilters,
  onChangeRelatedNodes,
  showRelatedNodes,
}) => {
  return (
    <DataFilteringFooterContainer>
      <ButtonGroupContainer>
        <Button variant='contained' onClick={onClickAddFilter}>
          <AddIcon sx={{ fontSize: "14px" }} />
          Add Filter
        </Button>
        <FormGroup>
          <ComboSection
            onChange={onChangeRelatedNodes}
            control={<Checkbox checked={showRelatedNodes} />}
            label={<Label>Show related nodes</Label>}
          />
        </FormGroup>
      </ButtonGroupContainer>

      <ButtonGroupContainer>
        <Button variant='outlined' onClick={onClickResetFilters}>
          Reset
        </Button>

        <Button variant='contained' onClick={onClickApplyFilters}>
          <AddIcon sx={{ fontSize: "14px" }} />
          Apply Filters
        </Button>
      </ButtonGroupContainer>
    </DataFilteringFooterContainer>
  );
};
