import IntegrationLayout from "../../components/onboardingV2/integrationLayout";
import { RenderWithContext } from "../../utils/hocs/RenderWithContext";
import { WizIntegrationProvider } from "./context/Provider";
import { useIntegrationMultiStepSidebar } from "./hooks/useIntegrationMultiStepSidebar";
import logo from "../../assets/images/Wiz.png";
import { useIntegrationView } from "./hooks/useIntegrationView";
import { useIntegrationButtons } from "./hooks/useIntegrationFooterButtons";
import ConditionComponent from "../../utils/ConditionComponent";
import BannerAlert from "../../components/banners/ErrorAlert";
import { useGetWizIntegrationState } from "./hooks/useIntegrationState";
import { Root } from "../googleWorkspaceIntegration/styled";

const Component = () => {
  const MultiStepSidebar = useIntegrationMultiStepSidebar();
  const FooterButtons = useIntegrationButtons();
  const View = useIntegrationView();
  const { error } = useGetWizIntegrationState();

  return (
    <Root>
      <IntegrationLayout
        multisteps={MultiStepSidebar}
        headerLogo={logo}
        headerTitle={"Wiz Integration"}
        footerButtons={FooterButtons}
        content={View}
      />
      <ConditionComponent condition={error}>
        <BannerAlert message={error} />
      </ConditionComponent>
    </Root>
  );
};

export default RenderWithContext(Component, WizIntegrationProvider);
