import { useEffect } from "react";
import { getCMSTIssuesPageData } from "../../../../state-management/CMST/issues/thunks/getCMSTIssuesPageData";
import { useDispatch } from "react-redux";
import { useSaveTableView } from "../../../shared-component/AssetTable/hooks/useSaveTableView";

export function useInitPage() {
  const dispatch = useDispatch();
  const { getRunQueryParams } = useSaveTableView();

  const fetchInitialData = async () => {
    const { queryId, table } = getRunQueryParams();
    let saveQuery;
    if (table && queryId) {
      saveQuery = {
        table,
        id: queryId,
      };
    }

    const payload = {
      saveQuery,
    };
    dispatch(getCMSTIssuesPageData(payload));
  };

  useEffect(() => {
    fetchInitialData();
  }, []);
}
