import { GlobalStateContext } from "./contexts";

export const StateProvider = (props) => {
  return (
    <GlobalStateContext.Provider
      value={{
        assetSchema: props.assetSchema,
        features: { addToColumns: true, ...props.features },
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};
