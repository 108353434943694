import { DataFilterConjunctions } from "../../../../core/asset-inventory/filterConjunctions";
import { DataFilterOperators } from "../../../../core/asset-inventory/filterOperators";

function getOptionByValue(value, sourceOptions) {
  return Object.values(sourceOptions).find((val) => val.value === value.value);
}

function decodeFilter({ operator, conjunction, field, values, id }, index) {
  const filter = {
    operator: getOptionByValue(operator, DataFilterOperators),
    field,
    values,
    id,
  };
  if (index > 0 && conjunction) {
    filter.conjunction = getOptionByValue(conjunction, DataFilterConjunctions);
  }
  return filter;
}

export function decodeEncodedFiltersFromUrl(filters) {
  return filters.map(decodeFilter);
}
