import { useHistory } from "react-router-dom";
import { InventoryFilterUrlParser } from "../ui/pages/inventory/utils/filterToUrlModule";

export const TABLE_VIEW_ENCODING_KEY = "currentViewPayload";

export function useSyncPayloadWithUrl() {
  const history = useHistory();

  const func = (payload) => {
    const encodedViewState =
      InventoryFilterUrlParser.encodeFiltersToUrl(payload);
    const searchParams = new URLSearchParams(window.location.search);
    const hasViewEncoding = searchParams.get(TABLE_VIEW_ENCODING_KEY);
    if (hasViewEncoding)
      searchParams.set(TABLE_VIEW_ENCODING_KEY, encodedViewState);
    else searchParams.append(TABLE_VIEW_ENCODING_KEY, encodedViewState);

    const path = `${window.location.pathname}?${searchParams}`;
    history.push(path);
  };

  return func;
}
