import { Typography } from "@mui/material";
import { EmptyDataSection } from "./styled";
import ErrorIcon from "@mui/icons-material/Error";

export const ErrorSection = ({ errorMessage }) => {
  return (
    <EmptyDataSection>
      <ErrorIcon color='error.main' />
      <Typography variant='h6' color='error.main'>
        Ooops!!!
      </Typography>

      <Typography variant='body2' color='error.main'>
        {errorMessage}
      </Typography>
    </EmptyDataSection>
  );
};
