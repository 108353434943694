import {
  APICMSTTableVariants,
  CMSTTableType,
  CMSTTableVariants,
} from "../../../../app/data/constants";
import { APICMSTQuery } from "../../../../app/data/models/cmst/types";

export function getQueryTableFromQuery(
  query: APICMSTQuery
): Nullable<CMSTTableType> {
  const convertor = {
    [APICMSTTableVariants.ISSUE]: CMSTTableVariants.ISSUE,
    [APICMSTTableVariants.PROJECT]: CMSTTableVariants.PROJECT,
  };
  return (convertor[query.table_name] ?? null) as Nullable<CMSTTableType>;
}
