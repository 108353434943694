import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GitHubOAuthCallback = () => {
  const query = useQuery();
  const history = useHistory(); // Add this
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const exchangeCodeForToken = async () => {
      const code = query.get("code");
      try {
        // Get Auth0 token
        const auth0Token = await getAccessTokenSilently();

        // Send the GitHub code and Auth0 token to your backend
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/connectors/github/oauth/callback`,
          {
            code,
          },
          {
            headers: {
              Authorization: `Bearer ${auth0Token}`, // Also send Auth0 token in headers for authentication
            },
          },
        );

        // Do something with the response

        // Redirect to /connectors
        history.push("/connectors");
      } catch (error) {
        // Handle error
        console.log("Error");
        history.push("/connectors");
      }
    };

    exchangeCodeForToken();
  }, []);

  return <div>Processing connectors...</div>;
};

export default GitHubOAuthCallback;
