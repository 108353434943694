import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const JiraOAuthCallback = () => {
  const query = useQuery();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const exchangeCodeForToken = async () => {
      const code = query.get("code");
      const state = query.get("state");
      
      if (!code) {
        toast.error("No authorization code received");
        history.push("/integrations");
        return;
      }

      if (!state) {
        toast.error("No state received");
        history.push("/integrations");
        return;
      }

      try {
        // Get Auth0 token
        const auth0Token = await getAccessTokenSilently();

        // Send the Jira code and Auth0 token to your backend
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/connectors/jira/oauth/callback`,
          {
            code,
            state,
          },
          {
            headers: {
              Authorization: `Bearer ${auth0Token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Successfully connected to Jira");
          history.push("/integrations");
        } else {
          toast.error("Failed to connect to Jira");
          history.push("/integrations");
        }

      } catch (error) {
        console.error("Error during Jira OAuth callback:", error);
        toast.error(error.response?.data?.message || "Failed to connect to Jira");
        history.push("/integrations");
      }
    };

    exchangeCodeForToken();
  }, []);

  return <div>Processing Jira integration...</div>;
};

export default JiraOAuthCallback; 