import { Typography } from "@mui/material";
import { InsightsSection } from "../../../shared-component/CMSTLayout";
import { InsightsBlocksRoot, PageContent } from "./component/styled";
import { CMSTInsightCard } from "../../../shared-component/CMSTInsightCard";
import { useCMSTProjectsState } from "../hooks/useCMSTProjectsState";
import AssetTable from "../../../shared-component/AssetTable";
import { DefaultCMSTProjectsTableColumns } from "../../../../core/cmst/projects-table/defaultColumns";
import { LocalStorage } from "../../../../utils/browserUtils/LocalStorage";
import { CMSTService } from "../../../../services/CMSTService";
import { useAppNavigator } from "../../../../hooks/useAppNavigator";

export const EntryView = () => {
  const { summary, schema, saveQueryPayload } = useCMSTProjectsState();
  const { push } = useAppNavigator();

  const createCurrentUrlWithoutOrigin = () => {
    const fromBase = window.location.href;
    return fromBase.replace(window.location.origin, "");
  };

  const handleOnClickAsset = (asset) => {
    const id = asset.data?.project?.id;
    if (!id) {
      /**
       * @todo show warning alert to user and throw log.
       * Consider an advanced error logging system that will alert devs of engineering error
       */
      return;
    }
    const fromUrl = createCurrentUrlWithoutOrigin();
    const path = `/cmst/projects/${id}/issues`;
    LocalStorage.setItem("cmst-projects-config", { fromUrl });
    push(path);
  };

  const saveQueryActions = {
    save: CMSTService.getInstance().saveQuery,
    edit: CMSTService.getInstance().editQuery,
  };

  const TableService = {
    getTableData: CMSTService.getInstance().getProjects,
    saveQuery: CMSTService.getInstance().saveQuery,
  };

  return (
    <PageContent className='scrollable'>
      <InsightsSection>
        <Typography variant='h4'>Monitored Projects</Typography>
        <InsightsBlocksRoot>
          <CMSTInsightCard
            label='Issues with security concerns'
            value={summary?.projects_with_security_concerns}
            variant='error'
          />
          <CMSTInsightCard
            label='Total JIRA projects'
            value={summary?.total_jira_projects}
            variant='default'
          />
          <CMSTInsightCard
            label='Stake holders involved Projects'
            value={summary?.stakeholders_involved}
            variant='success'
          />
        </InsightsBlocksRoot>
      </InsightsSection>
      <AssetTable
        key='cmst_projects'
        table='cmst_projects'
        defaultColumns={DefaultCMSTProjectsTableColumns}
        schema={schema}
        service={TableService}
        onClickAsset={handleOnClickAsset}
        saveQueryActions={saveQueryActions}
        saveQueryPayload={saveQueryPayload}
      />
    </PageContent>
  );
};
