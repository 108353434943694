const createOperatorPayload = (operator) => {
  return operator.value;
};

const createFilterValuesPayload = (values) => {
  if (!values.length) return "";
  return values.map((value) => value.value).join("|");
};

const getFilterName = (asset) => {
  return `${asset.source}.${asset.value}`;
};

export function createFiltersPayload(filters) {
  return filters.map((filter) => {
    const name = getFilterName(filter.field);
    const operator = createOperatorPayload(filter.operator);
    const value = createFilterValuesPayload(filter.values);
    if (!value) return `${name},${operator}`;
    return `${name},${operator},${value}`;
  });
}
