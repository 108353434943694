import React, { Fragment } from "react";
import { InventoryTableActionButton } from "../../../../pages/inventory/components/ActionButton";
import { SaveQueryButton } from "../../../../../containers/inventory/AssetsTable/styled";
import { useUserInfo } from "../../../../../hooks/useAppUser";
import { AppFeatures } from "../../../../../core/features";
import { SaveQueryButtonRef } from "../../../../shared-component/AssetTable/refs";
import { SaveQueryView } from "../../../../shared-component/AssetTable/component/SavePopover";
import { SaveQueryActionTypes } from "../../../../../app/asset-management/config";
import { useSaveQuery } from "../../../../shared-component/AssetTable/hooks/useSaveQuery";

const Component = ({ title, ...props }) => {
  const { openView, closeView, open } = useSaveQuery();
  return (
    <Fragment>
      <InventoryTableActionButton
        ref={SaveQueryButtonRef}
        showActionCount={false}
        tooltipProps={{
          show: true,
          title: "Save new query",
        }}
      >
        <SaveQueryButton onClick={openView} {...props?.saveButtonProps}>
          {title}
        </SaveQueryButton>
      </InventoryTableActionButton>
      <SaveQueryView
        open={open}
        anchorEl={SaveQueryButtonRef.current}
        actionType={SaveQueryActionTypes.CREATE}
        onClose={closeView}
        {...props}
      />
    </Fragment>
  );
};

export const SaveQuery = (props) => {
  const { privileges } = useUserInfo();

  if (!privileges[AppFeatures.SAVED_QUERIES]) return;

  return <Component {...props} />;
};
