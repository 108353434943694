import React from "react";
import { authenticateGithub } from "../../utils/api";

import IntegrationLayout from "../../components/onboardingV2/integrationLayout";

import MultiStepSidebar from "../../components/onboardingV2/MultiStepSideBar";
import { setOnboardingStep } from "../../redux/actions/onboardingActions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import createButtonConfig from "../../shared/functions/buttons";
import { addIntegration } from "../../redux/actions/integrationActions";
import { useAuth0 } from "@auth0/auth0-react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {
  GithubIntegrationCompleteConfig,
  GithubIntegrationCompleteContent,
} from "./githubIntegrationComplete";
import {
  GithubIntegrationConfig,
  GithubIntegrationContent,
} from "./githubIntegrationContent";
import { IntegrationsProvider } from "./context/Provider";
import { Root } from "../googleWorkspaceIntegration/styled";

// W gc: 
// const GITHUB_CLIENT_ID = "Ov23liIN0VIvoKjXCDPa";
// Pando:
const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID || "7fb75e3b108747c19873";
const GITHUB_REDIRECT_URI =
  process.env.REACT_APP_FRONTEND_URL + "/github_auth/";

const GithubIntegration = ({ cancelUrl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const githubLoginRef = React.useRef();

  const [sidebarStep, setSidebarStep] = React.useState(1);
  const { getAccessTokenSilently } = useAuth0();

  if (!cancelUrl) {
    cancelUrl = "/";
  }

  const handleSuccessGithub = async (response) => {
    try {
      const code = response.code;
      const auth0Token = await getAccessTokenSilently();
      await authenticateGithub(auth0Token, code);
      dispatch(addIntegration("github"));
      if (sidebarStep < sidebarSteps.length) {
        setSidebarStep(sidebarStep + 1);
      }
    } catch (error) {
      console.log("handleSuccessGithub Error");
    }
  };

  const handleFailureGithub = (response) => {
    console.log("handleFailureGithub Error");
  };

  const handleOAuthClick = () => {
    if (githubLoginRef.current) {
      githubLoginRef.current.initiateLogin();
    }
  };

  // Sidebar
  const sidebarSteps = [
    {
      title: "Integration Method",
      description:
        "Select the preferred method for integrating your Github account",
    },
    { title: "Completion" },
  ];

  const multiSteps = (
    <MultiStepSidebar steps={sidebarSteps} currentStep={sidebarStep} />
  );

  const onCancel = () => {
    history.push(cancelUrl);
    dispatch(setOnboardingStep(2));
    console.log("Cancel button clicked");
    // Add any additional logic you need for the cancel action
  };

  const onContinue = () => {
    if (sidebarStep === 2) {
      history.push(cancelUrl);
    }
  };

  const footerButtonsStepOne = [
    createButtonConfig("Cancel", "left", onCancel, null, "cancel", false),
  ];
  const footerButtonsComplete = [
    createButtonConfig(
      "Done",
      "right",
      onContinue,
      <DoneOutlinedIcon />,
      "left",
      false
    ),
  ];

  const pageSteps = [
    {
      headerLogo: GithubIntegrationConfig.logo,
      headerTitle: GithubIntegrationConfig.headerTitle,
      content: (
        <GithubIntegrationContent
          githubLoginRef={githubLoginRef}
          GITHUB_CLIENT_ID={GITHUB_CLIENT_ID}
          GITHUB_REDIRECT_URI={GITHUB_REDIRECT_URI}
          handleSuccessGithub={handleSuccessGithub}
          handleFailureGithub={handleFailureGithub}
          handleOAuthClick={handleOAuthClick}
        />
      ),
      footerButtons: footerButtonsStepOne,
    },
    {
      headerLogo: GithubIntegrationCompleteConfig.logo,
      headerTitle: GithubIntegrationCompleteConfig.headerTitle,
      content: <GithubIntegrationCompleteContent />,
      footerButtons: footerButtonsComplete,
    },
  ];
  return (
    <Root>
      <IntegrationLayout
        multisteps={multiSteps}
        headerLogo={pageSteps[sidebarStep - 1]?.headerLogo}
        headerTitle={pageSteps[sidebarStep - 1]?.headerTitle}
        content={pageSteps[sidebarStep - 1]?.content}
        footerButtons={pageSteps[sidebarStep - 1]?.footerButtons}
      />
    </Root>
  );
};

export default GithubIntegration;
