import { styled } from "@mui/material";
import { TruncatedCell } from "../../../../containers/inventory/AssetsTable/styled";

export const InventoryTableActionsRoot = styled("div")(({ theme }) => {
  return `
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  padding-right: 4px;
  padding: 24px 12px;
  padding-top: 32px;
  border-radius: 2px;
  margin-top: 6px;
  background-color: ${theme.palette.grey[100]};
  position: relative;
`;
});

export const PostActionsRoot = styled("div")(() => {
  return `
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  column-gap: 10px;
  `;
});

export const PreActionsRoot = styled("div")(() => {
  return `
  display: flex;
  align-items: center;
  column-gap: 12px;
  `;
});

export const PageTableSection = styled("div")`
  overflow: hidden;
  margin-top: 24px;
`;

export const QueryDisplayRoot = styled("div")(() => {
  return `
  max-width: 300px;
  max-height: 32px;
  overflow: hidden;
  `;
});

export const QueryLabelText = styled((props) => (
  <TruncatedCell clamp={1} {...props} />
))`
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;
