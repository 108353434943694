import { styled } from "@mui/material";

export const UserManagementSection = styled("section")(() => {
  return `
  max-height: 1200px;
  min-height: 500px;
  display: flex;
  `;
});

export const BusinessProcessesManagementSection = styled("section")(() => {
  return `
  max-height: 700px;
  min-height: 300px;
  `;
});

export const IntegrationsSection = styled("section")(() => {
  return `
margin-top: 24px;
  `;
});
