import { BaseSaveQueryPayloadAdapter } from "../../utils/BaseSaveQueryPayloadAdapter";

export class InventorySaveQueryPayloadAdapter extends BaseSaveQueryPayloadAdapter {
  private static _instance: InventorySaveQueryPayloadAdapter;

  private constructor() {
    super();
  }

  public static get instance() {
    if (!this._instance)
      this._instance = new InventorySaveQueryPayloadAdapter();
    return this._instance;
  }
}
