import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMSTService } from "../../../../services/CMSTService";

export const getCMSTProjectsPageData = createAsyncThunk(
  "get-cmst-projects-page-data",
  async (_payload = {}, thunkApi) => {
    try {
      const { saveQuery: saveQueryRequestPayload, ...payload } = _payload;
      const promises = [
        CMSTService.getInstance().getProjectTableOptions(payload),
        CMSTService.getInstance().getProjectsSummary(),
      ];
      if (saveQueryRequestPayload) {
        promises.push(
          CMSTService.getInstance().getQuery(saveQueryRequestPayload)
        );
      }

      const responses = await Promise.all(promises);
      const options = responses[0]?.data;
      const summary = responses[1]?.data;
      const saveQueryPayload = responses?.[2] ?? null;

      return thunkApi.fulfillWithValue({
        schema: options,
        summary,
        saveQueryPayload,
      });
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);
