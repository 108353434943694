import { filtersToQueryParamTransformer } from "../../infrastructure/api/utils/transformers/filtersToQueryParamTransformer";

export function Service(_apiClient) {
  let apiClient = _apiClient;

  this.getProjects = async (payload) => {
    const { pagination, filters, search, grouping } = payload;
    const page = pagination.pageIndex + 1;

    let path = `/cmst/projects/table?page=${page}&page_size=${pagination.pageSize}`;
    if (filters && filters.length) {
      const filtersParam = filtersToQueryParamTransformer(filters);
      path += `&${filtersParam}`;
    }
    if (search) {
      path += `&search=${search}`;
    }
    if (grouping) {
      const { group, option } = grouping;
      path += `&group=${group.value},${option.value}`;
    }
    const response = await apiClient.get(path, { isProtected: true });
    return response.data;
  };

  this.getAllIssues = async (payload) => {
    const { pagination, filters, search, grouping } = payload;
    const page = pagination.pageIndex + 1;

    let path = `/cmst/issues/table?page=${page}&limit=${pagination.pageSize}`;
    if (filters && filters.length) {
      const filtersParam = filtersToQueryParamTransformer(filters);
      path += `&${filtersParam}`;
    }
    if (search) {
      path += `&search=${search}`;
    }
    if (grouping) {
      const { group, option } = grouping;
      path += `&group=${group.value},${option.value}`;
    }
    const response = await apiClient.get(path, { isProtected: true });
    return response.data;
  };

  this.getProjectsSummary = async () => {
    const path = "/cmst/projects-summary";
    const response = await apiClient.get(path, { isProtected: true });
    return { data: response.data, meta: {} };
  };

  this.getProjectTableOptions = async () => {
    const path = "/cmst/projects/table/schema";
    const response = await apiClient.get(path, { isProtected: true });
    return { data: response.data.data };
  };

  this.getAllIssuesOptions = async () => {
    const path = `/cmst/issues/table/schema`;

    const response = await apiClient.get(path, { isProtected: true });
    return { data: response.data.data };
  };

  this.getIssuesSummary = async ({ projectId }) => {
    const path = `/cmst/issues/stats?filter=data.project.id,is,${projectId}`;
    const response = await apiClient.get(path, { isProtected: true });
    return { data: response.data };
  };

  this.getAllIssuesSummary = async () => {
    const path = `/cmst/issues/stats`;
    const response = await apiClient.get(path, { isProtected: true });
    return { data: response.data };
  };

  this.getIssues = async (projectId) => {
    const path = `/cmst/issues/${projectId}`;
    const response = await apiClient.get(path, { isProtected: true });
    return {
      data: response.data,
      meta: {},
    };
  };

  this.getProjectIssues = async (payload) => {
    const { pagination, filters, projectId, search, grouping } = payload;
    const page = pagination.pageIndex + 1;
    const basePath = `/cmst/issues/table`;
    const queryParams = `page=${page}&limit=${pagination.pageSize}`;

    const projectFilterParam = `filter=data.project.id,is,${projectId}`;

    let fullPath = `${basePath}?${queryParams}&${projectFilterParam}`;
    if (filters && filters.length) {
      const filtersParam = filtersToQueryParamTransformer(filters);
      fullPath += `&${filtersParam}`;
    }
    if (search) {
      fullPath += `&search=${search}`;
    }
    if (grouping) {
      const { group, option } = grouping;
      fullPath += `&group=${group.value},${encodeURIComponent(option.value)}`;
    }
    const response = await apiClient.get(fullPath, { isProtected: true });
    return response.data;
  };

  this.getIssueDetails = async (payload) => {
    const path = `/cmst/issues/issue/${payload.id}`;
    const response = await apiClient.get(path, { isProtected: true });
    return {
      data: response.data,
      meta: {},
    };
  };

  this.saveQuery = async (payload) => {
    const path = `/center/cmst/queries`;
    await apiClient.post(path, payload, { isProtected: true });
  };

  this.getQueries = async (payload) => {
    const { pagination } = payload;

    const path = `/center/cmst/queries?page=${
      pagination.pageIndex + 1
    }&page_size=${pagination.pageSize}`;

    const { data } = await apiClient.get(path, { isProtected: true });
    const { queries, total, limit, page } = data;
    return { data: queries, meta: { total, limit, page } };
  };

  this.getQuery = async (payload) => {
    const { id, table } = payload;
    const path = `/center/cmst/queries/${id}?table_name=${table}`;
    const res = await apiClient.get(path, { isProtected: true });
    return res.data;
  };

  this.editQuery = async (payload) => {
    const { table_name, query_uuid } = payload;
    const path = `/center/cmst/queries/${query_uuid}?table_name=${table_name}`;
    delete payload.table_name;
    delete payload.query_uuid;
    delete payload.created_by;
    await apiClient.put(path, payload, { isProtected: true });
  };

  this.getActions = async (payload) => {
    const { pagination } = payload;

    const path = `/center/cmst/rules?page=${pagination.pageIndex + 1}&limit=${
      pagination.pageSize
    }`;

    const { data } = await apiClient.get(path, { isProtected: true });
    const { rules, total, limit, page } = data;
    return { data: rules, meta: { total, limit, page } };
  };

  this.deleteAction = async (payload) => {
    const path = `/center/cmst/rules/${payload.ruleUuid}`;
    await apiClient.delete(path, { isProtected: true });
  };

  this.editAction = async (payload) => {
    const path = `/center/cmst/rules/${payload.ruleUuid}`;
    await apiClient.put(path, payload, { isProtected: true });
  };

  this.deleteQuery = async (payload) => {
    const { table_name, query_uuid } = payload;

    const path = `/center/cmst/queries/${query_uuid}?table_name=${table_name}`;
    await apiClient.delete(path, { isProtected: true });
  };

  this.createQueryAlert = async (payload) => {
    const path = `/center/cmst/rules`;
    await apiClient.post(path, payload, { isProtected: true });
  };

  return this;
}
