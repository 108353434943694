import { Checkbox, FormGroup } from "@mui/material";
import {
  ComboSection,
  FilterGroupButton,
  Label,
} from "../FilterSelector/styled";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import { GraphGroupingTarget } from "../../constants";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { MenuFooter } from "../../../../components/Grouping/styled";

export const Footer = ({ onClear, target, onChangeTarget }) => {
  return (
    <MenuFooter>
      <ConditionComponent condition={!!target}>
        <FormGroup>
          <ComboSection
            onChange={onChangeTarget}
            control={
              <Checkbox checked={target === GraphGroupingTarget.VISIBLE_DATA} />
            }
            label={<Label>Show grouping for only visible data</Label>}
          />
        </FormGroup>{" "}
      </ConditionComponent>
      <FilterGroupButton
        variant='text'
        border='1px solid #dee0e4'
        disableRipple
        bgColor='#F7F9FE'
        onClick={onClear}
      >
        <DeleteSweepOutlinedIcon />
        <Label>Clear All Groupings</Label>
      </FilterGroupButton>
    </MenuFooter>
  );
};
