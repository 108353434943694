import { validateRunQueryUUID } from "../../../../app/data/validators/validateRunQueryUUID";
import {
  APICMSTTableVariants,
  CMSTTableVariants,
} from "../../../../app/data/constants";
import {
  TABLE_VIEW_ENCODING_KEY,
  useSyncPayloadWithUrl,
} from "../../../../hooks/useSyncPayloadWithUrl";

export function useSaveTableView() {
  const syncPayloadWithUrl = useSyncPayloadWithUrl();

  const saveView = (payload) => {
    syncPayloadWithUrl(payload);
  };

  const getParam = () => {
    const url = window.location.search;
    return new URLSearchParams(url);
  };

  const getViewEncodedPayload = () => {
    const params = getParam();
    return params.get(TABLE_VIEW_ENCODING_KEY);
  };

  const getTableType = () => {
    const table = window.location.pathname.replace("/", "");

    const adapter = {
      [CMSTTableVariants.ISSUE]: APICMSTTableVariants.ISSUE,
      [CMSTTableVariants.PROJECT]: APICMSTTableVariants.PROJECT,
    };

    return adapter[table] ?? null;
  };

  const getIsRunQuery = () => {
    const { queryId, viewType, table } = getRunQueryParams();
    const isRunQuery =
      viewType === "runSavedQuery" && validateRunQueryUUID(queryId) && !!table;

    return Boolean(isRunQuery);
  };

  const getRunQueryParams = () => {
    const params = getParam();
    const viewType = params.get("view");
    const queryId = params.get("query");
    const table = getTableType();
    return {
      viewType,
      queryId,
      table,
    };
  };

  const getIsSharedView = () => {
    return Boolean(getIsRunQuery() || getViewEncodedPayload());
  };

  return {
    saveView,
    getIsRunQuery,
    getViewEncodedPayload,
    getIsSharedView,
    getRunQueryParams,
  };
}
