export const AssetManagementConfig = {
  DEFAULT_PAGE_SIZE: 20,
};

export const SaveQueryActionTypes = Object.freeze({
  CREATE: "create",
  EDIT: "edit",
});

export const SaveQueryViewTypes = Object.freeze({
  SAVE: "save",
  SAVE_AS: "save-as",
});
