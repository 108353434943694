import { useCallback, useState } from "react";
import { DataFiltering } from "../../../../components/Filtering";
import { DataFilteringFooter } from "./Footer";

export const Filtering = (props) => {
  const [showRelatedNodes, setShowRelatedNodes] = useState(false);

  const onChangeRelatedNodes = () => {
    setShowRelatedNodes((p) => !p);
  };

  const handleOnOpen = () => {
    setShowRelatedNodes(props.showRelatedNodes);
  };

  const Footer = useCallback(
    (internalProps) => {
      const handleOnApplyFilters = () => {
        const filters = internalProps.onClickApplyFilters();
        if (filters) {
          props.onApplyFilters(filters, showRelatedNodes);
        }
      };

      return (
        <DataFilteringFooter
          onClickAddFilter={internalProps.onClickAddFilter}
          onClickResetFilters={internalProps.onClickResetFilters}
          onClickApplyFilters={handleOnApplyFilters}
          showRelatedNodes={showRelatedNodes}
          onChangeRelatedNodes={onChangeRelatedNodes}
        />
      );
    },
    [showRelatedNodes, onChangeRelatedNodes]
  );

  return (
    <DataFiltering
      {...props}
      onOpen={handleOnOpen}
      components={{
        Footer,
      }}
      features={{ addToColumns: false }}
    />
  );
};
