import configJson from "./auth_config.json";
import { DotEnv } from "./infrastructure/env-manager/dotenv";

export function getConfig() {
  // Check if we should use config file or environment variables

  if (DotEnv.useConfigFile) {
    // Use existing config file logic
    const audience =
      configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
        ? configJson.audience
        : null;

    return {
      domain: configJson.domain,
      clientId: configJson.clientId,
      ...(audience ? { audience } : null),
    };
  } else {
    // Load from environment variables
    const audience =
      process.env.REACT_APP_AUTH0_AUDIENCE &&
      process.env.REACT_APP_AUTH0_AUDIENCE !== "YOUR_API_IDENTIFIER"
        ? process.env.REACT_APP_AUTH0_AUDIENCE
        : null;

    return {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      ...(audience ? { audience } : null),
    };
  }
}
