import React, { useState, useRef, useEffect } from "react";
import styles from "./SingleSelect.module.css"; // updated import
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import { Button } from "@mui/material";

const SingleSelect = ({
  items,
  defaultSelected,
  onSelectedChange,
  buttonProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelected);
  const containerRef = useRef(null); // Reference for the component container

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    onSelectedChange(item);
  };

  // Close the dropdown if clicking outside of the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Add click event listener when the dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount or when the dropdown closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Effect runs when isOpen changes

  // Effect to update selected item when defaultSelected changes
  useEffect(() => {
    setSelectedItem(defaultSelected);
  }, [defaultSelected]); // This effect runs when defaultSelected changes

  return (
    <div className={styles.singleSelectContainer} ref={containerRef}>
      <Button
        className={styles.selectBox}
        onClick={() => setIsOpen(!isOpen)}
        {...buttonProps}
      >
        <HubOutlinedIcon className={styles.icon} />
        {selectedItem}
        {isOpen ? (
          <ArrowDropUpOutlinedIcon className={styles.icon} />
        ) : (
          <ArrowDropDownOutlinedIcon className={styles.icon} />
        )}
      </Button>
      {isOpen && (
        <div className={styles.dropdown}>
          <ul className={styles.itemList}>
            {items.map((item, index) => (
              <li key={index} onClick={() => handleItemClick(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SingleSelect;
