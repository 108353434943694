import { useMemo } from "react";
import { EmptyDataLabel, EmptyTableRoot, ErrorLabel } from "./styled";
import ErrorIcon from "@mui/icons-material/Error";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ConditionComponent from "../../../../../utils/ConditionComponent";

export const EmptyTableDataError = ({ isError, label = "No Data" }) => {
  const icon = useMemo(() => {
    if (isError) return <ErrorIcon color='error' />;
    return <AssignmentLateIcon color='primary' />;
  }, [isError]);

  return (
    <EmptyTableRoot>
      {icon}
      <ConditionComponent
        condition={isError}
        falsyNode={<EmptyDataLabel>{label}</EmptyDataLabel>}
      >
        <ErrorLabel>{label}</ErrorLabel>
      </ConditionComponent>
    </EmptyTableRoot>
  );
};
