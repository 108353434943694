export const getPageInitialEncodedViewConfig = () => {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  return params.get("currentViewPayload");
};

export const getIsViewSource = () => {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  return params.get("view-src") === "saved-query";
};

export const checkIsRunSavedQueryView = () => {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  return params.get("view") === "runSavedQuery";
};

export function getRunSavedQueryViewParams() {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  const query = params.get("query");
  return { query };
}
