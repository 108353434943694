export function createGroupingOptionsFromSchema(
  grouping,
  schema,
  schemaValuesAccessor
) {
  const currentGrouping = grouping[0];
  let values = [];
  if (schemaValuesAccessor) {
    values = schemaValuesAccessor(grouping, schema);
  } else {
    values = schema.platform?.[currentGrouping.value]?.values;
  }
  if (!Array.isArray(values)) return [];
  return values?.map((value) => ({
    label: `${value}`,
    value: `${value}`,
  }));
}
