import { Fragment, useMemo } from "react";
import { useDataTable } from "../../hooks/useDataTable";
import { TableContent, TableWrapper } from "../../styled";
import { MaterialReactTable } from "material-react-table";
import { createGraphColumnValuesFromData } from "../../utils/getGraphColumnValuesFromData";
import { usePrimaryTableState } from "../../hooks/usePrimaryTableState";
import { useAssetInventoryActionDispatch } from "../../hooks/useAssetInventoryActionDispatch";
import { ActionType } from "../../state-management/actions";
import { useIsGrouping } from "../../hooks/useIsGrouping";
import { useAssetsTableMethods } from "../../hooks/useAssetsTableMethods";
import { usePageData } from "../../hooks/usePageData";
import { checkTableIPaginationIsFirstRender } from "../../utils/checkTableIPaginationIsFirstRender";
import { usePrimaryTableColumnState } from "../../hooks/usePrimaryTableColumnState";

export const InventoryTable = () => {
  const { isLoading, data, meta, error, paginationConfig } =
    usePrimaryTableState();
  const dispatch = useAssetInventoryActionDispatch();
  const isGrouping = useIsGrouping();
  const { filters, searchKeyword, currentGroupingValue } = usePageData();
  const { fetchPrimaryTableData, fetchGroupingTableData } =
    useAssetsTableMethods();
  const [primaryTableColumns] = usePrimaryTableColumnState();

  const setPagination = (pagination) => {
    dispatch({
      type: ActionType.SET_PRIMARY_TABLE_PAGINATION_CONFIG,
      payload: pagination,
    });
  };

  const columns = useMemo(() => {
    return createGraphColumnValuesFromData(primaryTableColumns);
  }, [primaryTableColumns]);

  const handleOnPaginationChange = (newPagination) => {
    if (isGrouping) {
      fetchGroupingTableData(newPagination);
    } else {
      const payload = {
        page: newPagination.pageIndex,
        limit: newPagination.pageSize,
        filters,
        group: currentGroupingValue,
        searchKeyword,
      };
      fetchPrimaryTableData(undefined, payload);
    }
  };

  const { table } = useDataTable({
    data,
    isLoading,
    error,
    pagination: paginationConfig,
    rowCount: meta?.total ?? 0,
    reactTableProps: {
      columns,
      muiPaginationProps: { rowsPerPageOptions: [20] },
      onPaginationChange(updater) {
        const newPagination = updater(paginationConfig);
        const isFirstRender = checkTableIPaginationIsFirstRender(
          paginationConfig,
          newPagination
        );
        if (isFirstRender) return;
        setPagination(newPagination);
        handleOnPaginationChange(newPagination);
        return newPagination;
      },
      muiTableContainerProps: {
        sx: {
          height: "calc(100vh - 210px)",
          boxShadow: "none",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#cccccc50",
            borderRadius: "12px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#ccc",
            borderRadius: "5px",
            "&:hover": {
              background: "#555",
            },
          },
        },
      },
    },
  });

  return (
    <Fragment>
      <TableWrapper>
        <TableContent>
          <MaterialReactTable table={table} isLoading={isLoading} />
        </TableContent>
      </TableWrapper>
    </Fragment>
  );
};
