import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserService from "../../services/usersService";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialReactTable } from "material-react-table";
import DOMPurify from "dompurify";
import { useForm } from "react-hook-form";
import BannerAlert from "../banners/ErrorAlert";
import {
  Header,
  HeaderText,
  TableActionsContainer,
  TableContainer,
  TableRoot,
} from "./styled";
import { useUserManagementTable } from "./hooks/useUserManagementTable";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 2,
};

const UserManagementSettingSection = () => {
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const [backendErrors, setBackendErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const sanitizedEmail = DOMPurify.sanitize(user.email);
  const [userRole, setUserRole] = useState("user");

  const handleResetPassword = async (row) => {
    const email = row.original.email;

    try {
      const token = await getAccessTokenSilently();
      const response = await UserService.resetPassword(token, email);

      if (response.status === 200) {
        setSuccessMessages((currentMessages) => [
          ...currentMessages,
          `Password reset email sent to ${email}.`,
        ]);
      } else {
        throw new Error(
          `Failed to send password reset email to ${email}. Status code: ${response.status}`
        );
      }
    } catch (error) {
      let newErrors;
      if (error.response && error.response.data) {
        newErrors = [
          error.response.data.detail ||
            "An unexpected error occurred. Please try again.",
        ];
      } else {
        newErrors = [
          error.message || "An unexpected error occurred. Please try again.",
        ];
      }
      setBackendErrors(newErrors);
    }
  };

  const columns = [
    {
      accessorKey: "email",
      header: "Email",
      size: 270,
    },
    {
      accessorFn: (row) => row.role,
      id: "role",
      header: "Role",
      size: 60,
    },

    {
      accessorKey: "first_name",
      header: "First Name",
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
    },

    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant='outlined'
            color='secondary'
            hidden={userRole !== "admin"}
            disabled={row.original.email === sanitizedEmail}
            onClick={() => handleDelete(row)}
          >
            Delete
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => handleResetPassword(row)}
          >
            Reset Password
          </Button>
        </Box>
      ),
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = await getAccessTokenSilently();
      const data = await UserService.getUsers(token);
      setUsers(data);
    };

    const fetchUserRole = async () => {
      const token = await getAccessTokenSilently();
      const data = await UserService.getUserRole(token);
      setUserRole(data["role"]);
    };

    fetchUserRole();

    fetchUsers();
  }, [getAccessTokenSilently]);

  const handleOpen = () => {
    setOpenModal(true);
    setBackendErrors([]); // Clear any existing backend errors
  };
  const handleClose = () => setOpenModal(false);

  const onSubmit = async (data) => {
    setBackendErrors([]); // Clear previous errors
    setSuccessMessages([]); // Clear previous success messages
    try {
      const token = await getAccessTokenSilently();
      const response = await UserService.inviteUser(
        token,
        data.email,
        data.firstName,
        data.lastName,
        data.role
      );

      // Check if the status code indicates a successful operation
      if (response.status === 200 || response.status === 201) {
        const updatedUsers = await UserService.getUsers(token);
        setUsers(updatedUsers);
        // Append the success message
        setSuccessMessages((currentMessages) => [
          ...currentMessages,
          `User successfully invited. Status code: ${response.status}`,
        ]);
      } else {
        // Handle non-success status codes by throwing an error
        throw new Error(
          `Failed to invite user. Status code: ${response.status}`
        );
      }
    } catch (error) {
      // Prepare new error messages based on the error details
      let newErrors;
      if (error.response && error.response.data) {
        newErrors = [
          error.response.data.detail ||
            "An unexpected error occurred. Please try again.",
        ];
      } else {
        newErrors = [
          error.message || "An unexpected error occurred. Please try again.",
        ];
      }
      setBackendErrors(newErrors);
    } finally {
      // Ensure these actions happen no matter the outcome
      reset(); // Reset form fields
      handleClose(); // Close the modal
    }
  };

  const handleDelete = async (row) => {
    setBackendErrors([]);
    setSuccessMessages([]);

    try {
      const token = await getAccessTokenSilently();
      const response = await UserService.deleteUserByEmail(
        token,
        row.original.email
      );

      if (response && (response.status === 200 || response.status === 204)) {
        // Operation was successful
        setSuccessMessages((currentMessages) => [
          ...currentMessages,
          `User successfully deleted.`,
        ]);

        // Fetch the updated list of users to refresh the UI
        const updatedUsers = await UserService.getUsers(token);
        setUsers(updatedUsers);
      } else {
        // Directly handle non-success status codes without throwing
        const errorMessage = `Failed to delete user. Status code: ${
          response ? response.status : "unknown"
        }`;
        setBackendErrors((currentErrors) => [...currentErrors, errorMessage]);
      }
    } catch (error) {
      // Handle unexpected errors
      const errorMessage =
        error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : error.message || "An unexpected error occurred. Please try again.";
      setBackendErrors((currentErrors) => [...currentErrors, errorMessage]);
    }
  };
  const table = useUserManagementTable({
    data: users,
    columns,
  });

  return (
    <>
      {backendErrors.length > 0 &&
        backendErrors.map((error, index) => (
          <BannerAlert
            key={`error-${index}`}
            message={error}
            severity='error'
            topOffset={index * 80 + 20}
          />
        ))}

      {successMessages.length > 0 &&
        successMessages.map((message, index) => (
          <BannerAlert
            key={`success-${index}`}
            message={message}
            severity='success'
            topOffset={index * 80 + backendErrors.length * 80 + 20} // Adjust offset based on the number of error messages
          />
        ))}
      <TableContainer>
        <Header>
          <HeaderText>Users Management</HeaderText>
          <TableActionsContainer>
            <Button variant='contained' color='primary' onClick={handleOpen}>
              Invite
            </Button>
          </TableActionsContainer>
        </Header>
        <TableRoot>
          <MaterialReactTable table={table} />
        </TableRoot>
      </TableContainer>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={styleModal} component='form' onSubmit={handleSubmit(onSubmit)}>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Invite User
          </Typography>
          <TextField
            label='Email Address'
            variant='outlined'
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Entered value does not match email format",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
            margin='dense'
            fullWidth
          />
          <TextField
            label='Name'
            variant='outlined'
            {...register("firstName", {
              required: "First name is required",
            })}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            margin='dense'
            fullWidth
          />
          <TextField
            label='Last Name'
            variant='outlined'
            {...register("lastName", { required: "Last name is required" })}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            margin='dense'
            fullWidth
          />
          <FormControl fullWidth margin='dense' error={!!errors.role}>
            <InputLabel id='role-select-label'>Role</InputLabel>
            <Select
              labelId='role-select-label'
              id='role-select'
              label='Role'
              {...register("role", { required: "Role is required" })}
            >
              <MenuItem value='admin'>Admin</MenuItem>
              <MenuItem value='user'>User</MenuItem>
            </Select>
            {errors.role && (
              <Typography color='error' variant='caption'>
                {errors.role.message}
              </Typography>
            )}
          </FormControl>
          <Button type='submit' variant='contained' sx={{ mt: 2 }}>
            Send Invite
          </Button>
        </Box>
      </Modal>
      {/* <Card sx={{ width: "100%", overflow: "visible" }}>
        <CardContent>
          <Typography variant='h6' component='h2' gutterBottom>
            Users Management
          </Typography>
          <Box display='flex' justifyContent='flex-end' marginBottom={2}>
            <Button variant='contained' color='primary' onClick={handleOpen}>
              Invite
            </Button>
          </Box>
          <MaterialReactTable
            columns={columns}
            data={[...users, ...users, ...users, ...users]}

            // muiTableBodyRowProps={{
            //   sx: {
            //     height: "100px",
            //     "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            //   },
            // }}
            // muiTableBodyCellProps={{
            //   sx: {
            //     fontSize: "16px",
            //   },
            // }}
          />
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={styleModal}
              component='form'
              onSubmit={handleSubmit(onSubmit)}
            >
              <IconButton
                aria-label='close'
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Invite User
              </Typography>
              <TextField
                label='Email Address'
                variant='outlined'
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "Entered value does not match email format",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
                margin='dense'
                fullWidth
              />
              <TextField
                label='Name'
                variant='outlined'
                {...register("firstName", {
                  required: "First name is required",
                })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                margin='dense'
                fullWidth
              />
              <TextField
                label='Last Name'
                variant='outlined'
                {...register("lastName", { required: "Last name is required" })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                margin='dense'
                fullWidth
              />
              <FormControl fullWidth margin='dense' error={!!errors.role}>
                <InputLabel id='role-select-label'>Role</InputLabel>
                <Select
                  labelId='role-select-label'
                  id='role-select'
                  label='Role'
                  {...register("role", { required: "Role is required" })}
                >
                  <MenuItem value='admin'>Admin</MenuItem>
                  <MenuItem value='user'>User</MenuItem>
                </Select>
                {errors.role && (
                  <Typography color='error' variant='caption'>
                    {errors.role.message}
                  </Typography>
                )}
              </FormControl>
              <Button type='submit' variant='contained' sx={{ mt: 2 }}>
                Send Invite
              </Button>
            </Box>
          </Modal>
        </CardContent>
      </Card> */}
    </>
  );
};

export default UserManagementSettingSection;
