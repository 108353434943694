function transformToAction(method) {
  let payload = {
    action_type: method.type,
    ...method.payload,
  };
  if (method.type === "email") {
    const emails = method.payload.to.split(",").map((email) => email.trim());
    payload.to = emails;
  }
  return payload;
}

export function editActionPayloadTransformer(_payload, previousData) {
  const { notificationMethods, ruleUuid, ...otherFields } = _payload;
  const payload = {
    ...previousData,
    actions: notificationMethods.map((method) => transformToAction(method)),
    rule_uuid: ruleUuid,
    ruleUuid,
    ...otherFields,
  };

  return payload;
}
