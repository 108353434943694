import { getObjectValueByPath } from "../../../../utils/helperMethods/getObjectValueByPath";
import { BaseSaveQueryPayloadAdapter } from "../../utils/BaseSaveQueryPayloadAdapter";

export class CMSTSaveQueryPayloadAdapter extends BaseSaveQueryPayloadAdapter {
  private static _instance: CMSTSaveQueryPayloadAdapter;

  private constructor() {
    super();
  }

  public static get instance() {
    if (!this._instance) this._instance = new CMSTSaveQueryPayloadAdapter();
    return this._instance;
  }

  protected getAssetPathByFieldStr = (fieldStr: string) => {
    let field = fieldStr;
    if (fieldStr.startsWith("platform"))
      field = fieldStr.replace("platform.", "");
    return field;
  };

  protected getObjectsValue = (...args: any[]) => {
    const vl = getObjectValueByPath(...args, { split: false });
    return vl;
  };

  protected getFilterParts = (filter: any) => {
    const parts = filter.split(",");

    // Get the required parts
    const field = parts[0];
    const operator = parts.slice(1, 2).join(",");
    const values = parts.slice(2).join(",") ?? "";

    return { field, operator, values };
  };
  protected transformField = (field: any) => {
    field.value = this.getAssetPathByFieldStr(field.value);
    return field;
  };
}
