import { useDispatch, useSelector } from "react-redux";
import { getAssetOptions } from "../state-management/Inventory/thunks/getAssetOptions";

export function useAssetSchema() {
  const data = useSelector((state) => state.inventory.assetOptions.data);

  const dispatch = useDispatch();

  const fetchSync = () => {
    dispatch(getAssetOptions());
  };

  const fetchAsync = async () => {
    await dispatch(getAssetOptions()).unwrap();
  };

  return {
    data,
    fetchSync,
    fetchAsync,
  };
}
