import { usePageData } from "./usePageData";

export function useQueryActionsLabel() {
  const { saveQueryPayload } = usePageData();

  if (saveQueryPayload) {
    return saveQueryPayload.name;
  }
  return "New Query";
}
