import { SaveQueryViewTypes } from "../../../app/asset-management/config";

const getIsSaveQuery = () => {
  const url = window.location.search;
  const params = new URLSearchParams(url);
  return params.get("view") === "runSavedQuery";
};

export function useSaveQueryViewType() {
  const isSaveQuery = getIsSaveQuery();

  return isSaveQuery ? SaveQueryViewTypes.SAVE_AS : SaveQueryViewTypes.SAVE;
}
