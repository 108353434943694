import { useTheme } from "@mui/material";
import { CMST_Chip_Variants } from "../ui/component/Chip/data";

export function useIssueTheme() {
  const theme = useTheme();
  const {
    palette: { success, grey, error, warning },
  } = theme;

  const background = {
    [CMST_Chip_Variants.DEFAULT]: grey[300],
    [CMST_Chip_Variants.SECONDARY]: "#BFDBFE",
    [CMST_Chip_Variants.SUCCESS]: success.light,
    [CMST_Chip_Variants.WARN]: warning.light,

    [CMST_Chip_Variants.ERROR]: error.light,
  };
  const text = {
    [CMST_Chip_Variants.ERROR]: error.main,
    [CMST_Chip_Variants.SECONDARY]: "#1E40AF",
    [CMST_Chip_Variants.WARN]: warning.main,
    [CMST_Chip_Variants.SUCCESS]: success.dark,
    [CMST_Chip_Variants.DEFAULT]: grey[800],
  };

  return {
    palette: {
      background,
      text,
    },
  };
}
