import { Button, styled } from "@mui/material";
import { CoreDataTypes } from "../../../core/data-types";

export const FiltersRowSection = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchContainer = styled("div")`
  height: 100%;
`;

export const SearchInputContainer = styled("div")`
  margin-left: auto;
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
`;

export const PrimaryFiltersContainer = styled("div")`
  display: flex;
  column-gap: 12px;
`;

export const TableWrapper = styled("div")`
  flex: 1;
`;

export const TableContent = styled("div")`
  flex: 1;
  overflow-y: hidden;
  border: 0.5px solid #c7c7c7;
  border-radius: 8px;
`;

export const TableTopSection = styled("div")`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
`;

export const AssetsPageContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const TruncatedCell = styled("div")(({ showAll, clamp, children }) => {
  const _clamp = clamp ?? 3;
  const clampValue = showAll ? undefined : _clamp;
  const isNa = children === CoreDataTypes.N_A;
  const color = isNa ? "#a5a5a5" : "#000";

  return `
  display: -webkit-box;
  color: ${color};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${clampValue};
  overflow: hidden;
  line-break: anywhere;
`;
});

export const CellContainer = styled("div")`
  display: flex;
  column-gap: 4px;
`;

export const TableTooltipContentWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;

export const ColumnSelectorTrigger = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 4px;
  background-color: #002733;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #002733;
    svg > path {
      color: #00ffb2;
    }
  }

  svg > path {
    color: #fff;
  }
`;

export const TableActionsContainer = styled("div")`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
`;

export const SaveQueryButton = styled(Button)`
  &.MuiButton-root {
    background-color: #002733;
    color: #00ffb2;
    min-width: 0;
    min-height: 0;
    padding: 2px 8px;
    font-size: 12px;
    height: 30px;
    cursor: pointer !important;
    &:hover {
      background-color: #00273390;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const HeaderCellContainer = styled("div")(() => {
  return `
    width: 100%;
    overflow: hidden;
  `;
});
