import { IconButton, styled, Typography } from "@mui/material";

export const SummaryCardRoot = styled("div")(
  ({ theme, bg: _bg, borderColor }) => {
    const bg = _bg ?? "#fff";
    return `
  border-radius: 8px;
  padding: 12px;
  min-width: 230px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  background-color: ${bg};
  min-height: 120px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-color: ${borderColor};
  position: relative;
    `;
  }
);

export const Label = styled(({ children, ...props }) => (
  <Typography variant='h6' {...props}>
    {children}
  </Typography>
))``;

export const SummaryText = styled(({ children, ...props }) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))``;

export const InfoIconRoot = styled(IconButton)(() => {
  return `
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  &:active {
  outline: none;
  }
  border-radius: 12px;
  padding: 2px;
  background-color:  #E5E7EB;

  & > svg {
  height: 16px;
  width: 18px;
  }
  &:hover{ 
  background-color: #E5E7EB;
  }
  `;
});
