import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const TicketRoot = styled("div")(() => {
  return `
  display: flex;
  column-gap: 20px;
  padding: 6px;
  border-bottom: 1px solid #cccccc70;
  width: 100%;
  `;
});

export const AvatarSection = styled("div")(({ theme }) => {
  const bg = theme.palette.primary.main;
  return `
  padding: 6px;
  height: min-content;
  width: min-content;
  display: flex;
  align-items:center;
  justify-content: center;
  background-color: ${bg};
  border-radius: 2000px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  `;
});

export const DetailsRoot = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  `;
});

export const MetaRoot = styled("div")(() => {
  return `
  display: flex;
  align-items:center;
  column-gap: 16px;
  margin-bottom: 6px;
  `;
});

export const NameText = styled((props) => (
  <Typography variant='caption' {...props} />
))(() => {
  return `
  
  `;
});

export const DateText = styled((props) => (
  <Typography variant='caption' {...props} />
))(() => {
  return `
  
  `;
});

export const TicketText = styled((props) => (
  <Typography variant='body2' {...props} />
))(() => {
  return `
  margin-bottom: 6px;`;
});

export const DetailsFooterRoot = styled("div")(() => {
  return `
  display: flex;
  align-items:baseline;
  column-gap: 6px;
  justify-content: baseline;
  `;
});

export const Label = styled((props) => (
  <Typography variant='caption' {...props} />
))(({ theme }) => {
  const color = theme.palette.grey[700];

  return `
  margin-bottom: 6px;
  color: ${color};
  `;
});
