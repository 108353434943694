export const InitialState = {
  pageMeta: {
    isLoading: false,
    error: false,
    errorPayload: null,
  },
  primaryTableMeta: {
    data: [],
    meta: {},
    isLoading: false,
    error: false,
    errorPayload: null,
    paginationConfig: { pageSize: 20, pageIndex: 0 },
  },
  groupingTableMeta: {
    data: [],
    meta: {},
    isLoading: false,
    error: false,
    errorPayload: null,
    paginationConfig: { pageSize: 20, pageIndex: 0 },
  },
  saveQueryPayload: null,
  filters: [],
  primaryTableColumns: [],
  grouping: [],
  currentGroupingValue: null,
  assetOptionsMap: {},
  searchKeyword: "",
  assetDetailVisible: false,
  selectedAssetForDetails: null,
};
