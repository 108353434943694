import { useMemo } from "react";
import { CMST_Chip_Variants } from "./Chip/data";
import {
  IssuesSummaryContainer,
  OverviewLabel,
  PageContent,
  PageOverviewSection,
  SummaryCardLabel,
  SummaryCardText,
  OverviewText,
  OverviewChipsSection,
  JiraTicketSection,
  JiraTagsSection,
  OverviewSubHeading,
  TicketInfoSection,
  TicketInfoSectionRoot,
  ActionsContainer,
  ActionButtonsRoot,
  ActionButton,
  LongTextContainer,
  TicketDescriptionSection,
  TicketOverviewSectionRoot,
  TicketDescriptionRoot,
  Heading,
  TagsContainer,
  CommentsSection,
  CommentsListRoot,
} from "./styled";
import { CMSTChip } from "./Chip";
import { SecurityTasks } from "./SecurityTasks";
import ConditionComponent from "../../../../../utils/ConditionComponent";
import { CoreDataTypes } from "../../../../../core/data-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import htmlRenderer from "rehype-raw";
import { Tooltip, Typography } from "@mui/material";
import {
  MarkdownListItem,
  MarkdownPreComponent,
  MarkdownTable,
  MarkdownTableCell,
  MarkdownTableHead,
  MarkdownTableHeading,
} from "../../../../../components/botgpt/messageList/styled-components";
import { FieldCard } from "./SummaryCard";
import { SectionCard } from "./SectionCard";
import { DataCount } from "../../../../../components/DataCount";
import { TicketComment } from "./Comment";
import { checkValueExists } from "../../../../../utils/helperMethods/type-guards";

const checkClientValueExists = (val) =>
  checkValueExists(val) && val !== CoreDataTypes.N_A;

function createChipVariantsMap(profile) {
  return {
    workSuspensionRequired:
      profile.workSuspensionRequired.value === "Not Recommended"
        ? CMST_Chip_Variants.ERROR
        : CMST_Chip_Variants.SUCCESS,
    securityApprovalRequired: checkClientValueExists(
      profile.securityApprovalRequired.value
    )
      ? CMST_Chip_Variants.ERROR
      : CMST_Chip_Variants.SUCCESS,
    securityReviewRequired: checkClientValueExists(
      profile.securityReviewRequired.value
    )
      ? CMST_Chip_Variants.ERROR
      : CMST_Chip_Variants.SUCCESS,
  };
}

export const Content = ({ data: profile }) => {
  const chipVariantsMap = createChipVariantsMap(profile);

  const securityRisksNodes = useMemo(() => {
    const value = profile.securityRisks.value;
    if (!Array.isArray(value)) return value;
    return value.map((risk) => (
      <CMSTChip key={risk} label={risk} variant={CMST_Chip_Variants.ERROR} />
    ));
  }, [profile]);

  const jiraTicketTagsNodes = useMemo(() => {
    const tags = profile.jiraTicket.tags;
    const tagsToShow = tags?.slice(0, 3) ?? [];
    return tagsToShow.map((tag, index) => (
      <CMSTChip
        key={index}
        label={tag}
        variant={CMST_Chip_Variants.SECONDARY}
      />
    ));
  }, [profile]);

  const ticketTagRemainder = profile.jiraTicket.tags?.length - 3;
  const toolTipTags = profile.jiraTicket.tags?.slice(3).join(", ");

  const commentsNodes = profile.jiraTicket.comments.map((comment) => (
    <TicketComment key={comment.id} data={comment} />
  ));

  return (
    <PageContent className='scrollable'>
      <IssuesSummaryContainer>
        <FieldCard bg={"#4338CA20"}>
          <SummaryCardLabel color='#3730A3'>Project</SummaryCardLabel>
          <SummaryCardText color='#4338CA'>
            {profile.projectName}
          </SummaryCardText>
        </FieldCard>
        <FieldCard bg={"#E5E7EB"}>
          <SummaryCardLabel>Issue ID</SummaryCardLabel>
          <SummaryCardText>{profile.id}</SummaryCardText>
        </FieldCard>
        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.securityReviewRequired.reason}
        >
          <SummaryCardLabel>Security Review Required</SummaryCardLabel>
          <CMSTChip
            label={profile.securityReviewRequired.value}
            variant={chipVariantsMap.securityReviewRequired}
          />
        </FieldCard>
        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.securityApprovalRequired.reason}
        >
          <SummaryCardLabel>Security Approval Required</SummaryCardLabel>
          <CMSTChip
            label={profile.securityApprovalRequired.value}
            variant={chipVariantsMap.securityApprovalRequired}
          />
        </FieldCard>

        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.workSuspensionRequired.reason}
        >
          <SummaryCardLabel>Work Suspension</SummaryCardLabel>
          <CMSTChip
            label={profile.workSuspensionRequired.value}
            variant={chipVariantsMap.workSuspensionRequired}
          />
        </FieldCard>

        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.securityRelevanceScore.reason}
        >
          <SummaryCardLabel>Security Relevance Score</SummaryCardLabel>
          <CMSTChip
            label={profile.securityRelevanceScore.value}
            variant={CMST_Chip_Variants.SECONDARY}
          />
        </FieldCard>

        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.urgencyScore.reason}
        >
          <SummaryCardLabel>Urgency Score</SummaryCardLabel>
          <CMSTChip
            label={profile.urgencyScore.value}
            variant={CMST_Chip_Variants.WARN}
          />
        </FieldCard>
        <FieldCard borderColor={"#E5E7EB"} fieldInfo={profile.dataType.reason}>
          <SummaryCardLabel>Data Type</SummaryCardLabel>
          <CMSTChip
            label={profile.dataType.value}
            variant={CMST_Chip_Variants.SECONDARY}
          />
        </FieldCard>
        <FieldCard
          borderColor={"#E5E7EB"}
          fieldInfo={profile.changeType.reason}
        >
          <SummaryCardLabel>Change Type</SummaryCardLabel>
          <CMSTChip
            label={profile.changeType.value}
            variant={CMST_Chip_Variants.SECONDARY}
          />
        </FieldCard>
      </IssuesSummaryContainer>
      <PageOverviewSection>
        <SectionCard fieldInfo={profile.generalOverview.reason}>
          <OverviewLabel>General Overview</OverviewLabel>
          <LongTextContainer className='scrollable'>
            <OverviewText>{profile.generalOverview.value}</OverviewText>
          </LongTextContainer>
        </SectionCard>
        <SectionCard fieldInfo={profile.securityRationale.reason}>
          <OverviewLabel>Security Rationale</OverviewLabel>
          <LongTextContainer className='scrollable'>
            <OverviewText>{profile.securityRationale.value}</OverviewText>
          </LongTextContainer>
        </SectionCard>
        <SectionCard fieldInfo={profile.relatedAssets.reason}>
          <OverviewLabel>Related Assets</OverviewLabel>
          <ConditionComponent
            falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
            condition={!!profile.relatedAssets.value}
          >
            <OverviewChipsSection>
              {profile.relatedAssets.value}
            </OverviewChipsSection>
          </ConditionComponent>
        </SectionCard>
        <SectionCard fieldInfo={profile.securityRisks.reason}>
          <OverviewLabel>Security Risks</OverviewLabel>
          <ConditionComponent
            condition={securityRisksNodes.length}
            falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
          >
            <OverviewChipsSection>{securityRisksNodes}</OverviewChipsSection>
          </ConditionComponent>
        </SectionCard>
      </PageOverviewSection>
      <SecurityTasks data={profile.securityTasks.value} />
      <ActionsContainer>
        <OverviewLabel>Actions</OverviewLabel>
        <ActionButtonsRoot>
          <ActionButton disabled bgColor='#A855F7'>
            Submit an update to Jira
          </ActionButton>
          <ActionButton disabled bgColor='#22C55E'>
            Message reporter and Assignee on Slack
          </ActionButton>
          <ActionButton disabled bgColor='#3B82F6'>
            Email reporter and Assignee
          </ActionButton>
        </ActionButtonsRoot>
      </ActionsContainer>

      <JiraTicketSection>
        <OverviewLabel>Original Jira Ticket</OverviewLabel>
        <TicketInfoSectionRoot>
          <TicketInfoSection>
            <OverviewSubHeading>Reporter</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.reporter}</OverviewText>
          </TicketInfoSection>

          <TicketInfoSection>
            <OverviewSubHeading>Assignee (s)</OverviewSubHeading>
            <ConditionComponent
              condition={profile.jiraTicket.assignees.length}
              falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
            >
              <OverviewText>{profile.jiraTicket.assignees}</OverviewText>
            </ConditionComponent>
          </TicketInfoSection>
          <TicketInfoSection>
            <OverviewSubHeading>Status</OverviewSubHeading>
            <ConditionComponent
              condition={profile.jiraTicket.status !== CoreDataTypes.N_A}
              falsyNode={<OverviewText>n/a</OverviewText>}
            >
              <CMSTChip
                label={profile.jiraTicket.status}
                variant={CMST_Chip_Variants.SECONDARY}
              />
            </ConditionComponent>
          </TicketInfoSection>

          <TicketInfoSection>
            <OverviewSubHeading>Created At</OverviewSubHeading>
            <OverviewText>{profile.createdAt}</OverviewText>
          </TicketInfoSection>
          <TicketInfoSection>
            <OverviewSubHeading>Updated At</OverviewSubHeading>
            <OverviewText>{profile.updatedAt}</OverviewText>
          </TicketInfoSection>

          <JiraTagsSection>
            <OverviewSubHeading>Original Tags</OverviewSubHeading>
            <ConditionComponent
              condition={jiraTicketTagsNodes.length}
              falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
            >
              <TagsContainer>
                {jiraTicketTagsNodes}
                <ConditionComponent condition={ticketTagRemainder}>
                  <Tooltip placement='top' title={toolTipTags}>
                    <div>
                      <DataCount data={{ count: ticketTagRemainder }} />
                    </div>
                  </Tooltip>
                </ConditionComponent>
              </TagsContainer>
            </ConditionComponent>
          </JiraTagsSection>
        </TicketInfoSectionRoot>
        <TicketOverviewSectionRoot>
          <TicketInfoSection>
            <OverviewSubHeading>Summary</OverviewSubHeading>
            <OverviewText>{profile.jiraTicket.summary}</OverviewText>
          </TicketInfoSection>

          <TicketDescriptionRoot>
            <OverviewSubHeading>Description</OverviewSubHeading>
            <TicketDescriptionSection className='scrollable'>
              <ConditionComponent
                condition={profile.jiraTicket.description}
                falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[htmlRenderer]}
                  children={profile.jiraTicket.description}
                  components={{
                    p({ children }) {
                      return (
                        <Typography variant='body2'>{children}</Typography>
                      );
                    },
                    em({ children }) {
                      return <em className='important-text'>{children}</em>;
                    },
                    pre({ children }) {
                      return (
                        <MarkdownPreComponent>{children}</MarkdownPreComponent>
                      );
                    },
                    li({ children }) {
                      return <MarkdownListItem>{children}</MarkdownListItem>;
                    },
                    table: ({ children }) => {
                      return <MarkdownTable>{children}</MarkdownTable>;
                    },

                    td: ({ children }) => {
                      return <MarkdownTableCell>{children}</MarkdownTableCell>;
                    },
                    thead: ({ children }) => (
                      <MarkdownTableHead>{children}</MarkdownTableHead>
                    ),
                    th: ({ children }) => {
                      return (
                        <MarkdownTableHeading>{children}</MarkdownTableHeading>
                      );
                    },
                    h1({ children }) {
                      return <Heading>{children}</Heading>;
                    },
                    h2({ children }) {
                      return <Heading num={2}>{children}</Heading>;
                    },
                    h3({ children }) {
                      return <Heading num={3}>{children}</Heading>;
                    },
                    h4({ children }) {
                      return <Heading num={4}>{children}</Heading>;
                    },
                    h5({ children }) {
                      return <Heading num={5}>{children}</Heading>;
                    },
                    h6({ children }) {
                      return <Heading num={6}>{children}</Heading>;
                    },
                  }}
                />
              </ConditionComponent>
            </TicketDescriptionSection>
          </TicketDescriptionRoot>
        </TicketOverviewSectionRoot>
      </JiraTicketSection>
      <CommentsSection>
        <OverviewSubHeading>Comments</OverviewSubHeading>
        <ConditionComponent
          condition={profile.jiraTicket.comments.length}
          falsyNode={<OverviewText>{CoreDataTypes.N_A}</OverviewText>}
        >
          <CommentsListRoot className='scrollable'>
            {commentsNodes}
          </CommentsListRoot>
        </ConditionComponent>
      </CommentsSection>
    </PageContent>
  );
};
