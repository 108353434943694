const defaultGrouping = [
  {
    label: "sources",
    value: "sources",
    source: "platform",
    id: "options-platform.sources",
    checked: false,
  },
  {
    label: "common_fields.account_id",
    value: "common_fields.account_id",
    source: "platform",
    id: "options-platform.common_fields.account_id",
    checked: false,
  },
  {
    label: "types",
    value: "types",
    source: "platform",
    id: "options-platform.types",
    checked: false,
  },
];

export function getDefaultGraphData() {
  return {
    properties: defaultGrouping.map((group) => group.name),
    grouping: defaultGrouping,
  };
}
