import {
  Box,
  Button,
  DialogContent,
  InputBase,
  Typography,
  styled,
} from "@mui/material";
import MuiPersonIcon from "@mui/icons-material/Person";

export const IntegrationCardsContainer = styled("div")`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  box-sizing: border-box;
`;

export const IntegrationsHeader = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled((props) => (
  <Typography {...props} variant='h5' />
))`
  font-family: Roboto !important;
  font-weight: 700 !important;
`;

export const IntegrationLogo = styled("img")`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 4px;
`;

export const SubTitleText = styled((props) => (
  <Typography {...props} variant='body2' />
))`
  font-family: Roboto !important;
  flex: 1;
  max-width: 60%;
  color: #8f9699;
`;

export const SubTitleSection = styled("div")`
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  margin-top: 12px;
`;

export const SearchWrapper = styled("div")`
  position: relative;
  width: 250px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  column-gap: 8px;
  padding: 8px 12px !important;
  border: 1px solid #ccc;
  background-color: #cccccc10;
`;

export const Section = styled(Box)``;

export const SearchInput = styled(InputBase)`
  && {
    flex: 1;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const SectionHeading = styled(Box)`
  && {
    display: flex;
    margin-top: 24px;
    margin-bottom: 18px;
    align-items: center;
    column-gap: 12px;
  }
`;

export const SectionSubHeading = styled((props) => (
  <Typography {...props} variant='h6' />
))`
  font-family: Roboto !important;
  font-weight: 600;
`;

export const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ScrollableContainer = styled("div")`
  flex: 1;
  overflow-x: hidden;
  margin-top: 18px;
`;

export const PersonIcon = styled(MuiPersonIcon)`
  && {
    height: 16px;
    width: 16px;
  }
`;

export const Row = styled("div")`
  display: flex;
`;

export const DeleteDialogueContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

export const DeleteDialogTitle = styled((props) => (
  <Typography {...props} variant='body1' />
))`
  color: #000;
  font-weight: 500;
`;

export const DialogIntegrationContainer = styled("div")`
  && {
    display: flex;
    align-items: center;
    column-gap: 12px;
    background-color: #cccccc40 !important;
    padding: 12px;
    margin: 0 auto;
    border-radius: 4px;
    margin-top: 20px;
  }
`;

export const DialogFooter = styled("div")`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
`;
const ButtonBase = styled(Button)`
  && {
    text-transform: none;
    border-radius: 6px !important;
    width: 40% !important;
    border-bottom-color: none;
    :focus {
      outline: none;
    }
  }
`;

export const DeleteButton = styled(ButtonBase)`
  && {
    background-color: #f91c51;
    color: #fff !important;
    border: 1px solid #f91c51 !important;
  }
`;

export const CancelButton = styled(ButtonBase)`
  && {
    background-color: #cccccc30;
    border: 1px solid #cccccc70;
    color: #505050 !important;
  }
`;

export const ErrorMessage = styled(Typography)`
  color: #f91c51;
  text-align: center;
`;

export const EmptyDataSection = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const EmptySectionText = styled(Typography)`
  color: #8f9699;
  text-align: center;
  margin-top: 24px;
`;
