import { styled, Typography } from "@mui/material";

export const TableContainer = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 24px;
  `;
});

export const Header = styled("header")(() => {
  return `
  height: 42px;
  display: flex;
  align-items:center;
  `;
});

export const HeaderText = styled((props) => (
  <Typography variant='h6' {...props} />
))(() => {
  return `
  font-weight: 600;
  `;
});

export const TableRoot = styled("div")(() => {
  return `
  flex: 1;
  height: calc(100% - 30px);
  `;
});

export const TableActionsContainer = styled("div")(() => {
  return `
  margin-left: auto;
  `;
});
