import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import "./module.css";

function CardHeader({ logo, title }) {
  return (
    <Fragment>
      <img
        width='54px'
        style={{ objectFit: "contain", borderRadius: "8px" }}
        height='48px'
        src={logo}
        alt='aws'
      />
      <Typography
        className='cardTitle'
        sx={{
          textAlign: "left",
        }}
      >
        {title}
      </Typography>
    </Fragment>
  );
}

export default CardHeader;
