import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import googleWorkspaceLogo from "../../../assets/images/google-workspace.svg";

export const GoogleWorkspaceIntegrationCompleteContent = () => {
  return (
    <Box>
      <Stack direction="row" sx={{ alignItems: "flex-end", gap: "18.9px" }}>
        <Typography variant="h6">Integration Complete!</Typography>
      </Stack>
    </Box>
  );
};

export const GoogleWorkspaceIntegrationCompleteConfig = {
  logo: googleWorkspaceLogo,
  headerTitle: "Google Workspace Integration Complete",
}; 