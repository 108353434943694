import {
  DataTableSectionContent,
  GroupDataTableSectionContainer,
} from "./styled";

export const GroupingTableSection = ({ children }) => {
  return (
    <GroupDataTableSectionContainer>
      <DataTableSectionContent>{children}</DataTableSectionContent>
    </GroupDataTableSectionContainer>
  );
};
