const exists = (val) => {
  return ![undefined, null, NaN].includes(val);
};

const isPrimitive = (value) => {
  return typeof value !== "object";
};

export const DataTypeUtils = Object.freeze({
  isPrimitive,
  exists,
});
