import { DataGroupsSources } from "../../../components/Grouping/data";
import { createOptionId } from "../../../components/Grouping/utils/createOptionId";
import { DataFilterConjunctions } from "../../../core/asset-inventory/filterConjunctions";
import { getOperatorByValue } from "../../../core/data-filtering/getOperatorByValue";
import { identityFn } from "../../../utils/helperMethods/doNothing";
import { generateRandomStr } from "../../../utils/helperMethods/generateId";
import { getObjectValueByPath } from "../../../utils/helperMethods/getObjectValueByPath";

export abstract class BaseSaveQueryPayloadAdapter {
  protected transformField = identityFn;

  protected getObjectsValue = (...args: any) => getObjectValueByPath(...args);
  protected getFilterParts = (filter: any) => {
    const parts = filter.split(",");

    // Get the required parts
    const field = parts[0];
    const operator = parts.slice(1, 2).join(",");
    const values = parts.slice(2).join(",") ?? "";

    return { field, operator, values };
  };

  protected getAssetPathByFieldStr = (fieldStr: string) => {
    const source = this.getAssetSourceFromName(fieldStr);
    const path = `${source}.${fieldStr}`;
    return path;
  };

  protected getAssetComponents = (fieldStr: any, schema: any) => {
    const path = this.getAssetPathByFieldStr(fieldStr);
    const source = this.getAssetSourceFromName(fieldStr);
    const schemaValue = this.getObjectsValue(schema, path);
    return { source, schemaValue };
  };

  protected getFieldByFieldStr(
    fieldStr: string,
    source: string,
    schemaValue: any
  ) {
    const numOfValues = schemaValue.values?.length ?? 0;
    const label = `${fieldStr} (${numOfValues})`;

    const valueTypes = schemaValue.value_types;

    const field = {
      value: fieldStr,
      label,
      source,
      valueTypes,
    };
    return field;
  }

  protected getAssetSourceFromName(name: string) {
    const isPlatformSource =
      name.startsWith("common_fields") || !name.includes(".");
    if (isPlatformSource) return DataGroupsSources.PLATFORM;
    return name.split(".")[0];
  }

  protected transformColumnsToValidOptions = (columns: any[]) => {
    const applyTransformation = (col: any) => {
      const colSource = this.getAssetSourceFromName(col);
      const colPayload = {
        label: col,
        value: col,
        source: colSource,
        id: "",
      };
      colPayload.id = createOptionId(colPayload, "options");
      return colPayload;
    };

    return columns.map(applyTransformation);
  };

  private convertValuesStrToArr = (valuesStr: string) => {
    return valuesStr.split("|");
  };

  protected createFilterValuesFromValueStrings = (
    valuesStr: string,
    source: string
  ) => {
    const values = this.convertValuesStrToArr(valuesStr);
    return values.map((value) => {
      return {
        label: value,
        value,
        source,
        parentPayload: { field: value, source },
      };
    });
  };

  protected transformFiltersToValidOptions = (filters: any, schema: any) => {
    const applyTransformation = (filter: any) => {
      const {
        field: fieldStr,
        operator: operatorStr,
        values: valuesStr,
      } = this.getFilterParts(filter);
      const { schemaValue, source } = this.getAssetComponents(fieldStr, schema);

      if (!schemaValue) return null;

      const operator = getOperatorByValue(operatorStr);
      const rawField = this.getFieldByFieldStr(fieldStr, source, schemaValue);
      const values = this.createFilterValuesFromValueStrings(valuesStr, source);
      const field = this.transformField?.(rawField) ?? rawField;

      const payload = {
        operator,
        values,
        field,
        payload: {},
        conjunction: DataFilterConjunctions.AND,
        addToColumns: false,
        id: generateRandomStr(),
      };
      return payload;
    };

    let transformedFilters: any[] = [];
    filters.forEach((filter: unknown) => {
      const transformedFilter = applyTransformation(filter);
      if (transformedFilter) transformedFilters.push(transformedFilter);
    });
    return transformedFilters;
  };

  protected getGroupingParts = (group: string) => {
    const parts = group.split(",");
    let groupStr = parts[0];
    if (groupStr.startsWith("platform"))
      groupStr = groupStr.replace("platform.", "");
    const currentGroupingValueStr = parts.slice(1, 2).join(",");

    return {
      groupStr,
      currentGroupingValueStr,
    };
  };

  protected transformGroupToValidOption = (_group: any) => {
    const { groupStr, currentGroupingValueStr } = this.getGroupingParts(_group);
    const source = this.getAssetSourceFromName(groupStr);
    const group = {
      source,
      value: groupStr,
      label: groupStr,
    };

    const currentGroupingValue = {
      value: currentGroupingValueStr,
      group,
    };

    return {
      group,
      currentGroupingValue,
    };
  };

  protected transformGroupToCMSTOption(_group: string) {
    const { groupStr, currentGroupingValueStr } = this.getGroupingParts(_group);
    const source = this.getAssetSourceFromName(_group);

    const group = {
      source,
      value: groupStr,
      label: groupStr,
      id: "",
    };
    const id = createOptionId(group, "options");
    group.id = id;

    const option = {
      label: currentGroupingValueStr,
      value: currentGroupingValueStr,
    };

    return { group, option };
  }
}
