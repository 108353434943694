import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputBase,
  Typography,
  IconButton,
  Chip,
  InputAdornment,
} from "@mui/material";
import styles from "../styles.module.css";
import { MuiStyles } from "../styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { generateUid } from "../generateUID";
import { defaultArnRole } from "../../data";
import { useAwsCloudFormationIntegrationValueMutators } from "../../hooks/useAwsCloudFormationIntegrationValuesMutators";
import { useAwsCloudFormationIntegrationValues } from "../../hooks/useAwsCloudFormationIntegrationValues";
import ConditionComponent from "../../../../utils/ConditionComponent";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SearchIcon from '@mui/icons-material/Search';

function AccountInput({
  account,
  isGenerated,
  externalId,
  role,
  selfRole,
  handleAccountChange,
  handleExternalIdChange,
  handleSelfRoleChange,
}) {
  const { 
    setIntegrationName, 
    setArnRoles,
    setAccessKeyId,
    setSecretAccessKey,
  } = useAwsCloudFormationIntegrationValueMutators();
  
  const { 
    integrationName, 
    isManual, 
    arnRoles,
    accessKeyId,
    secretAccessKey,
  } = useAwsCloudFormationIntegrationValues();
  
  const arnInputRef = useRef(null);

  const [fileLoaded, setFileLoaded] = useState(false);

  // Add state for validation errors
  const [arnErrors, setArnErrors] = useState({});

  // ARN validation regex
  const arnRegex = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+$/;

  const validateArn = (arn) => {
    return arnRegex.test(arn);
  };

  const handleArnKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newArn = event.target.value.trim();
      
      // Check if we've reached the limit
      if (arnRoles.length >= 100) {
        setArnErrors(prev => ({
          ...prev,
          limit: 'Maximum limit of 100 ARNs reached'
        }));
        return;
      }

      if (newArn && !arnRoles.includes(newArn)) {
        if (validateArn(newArn)) {
          setArnRoles([...arnRoles, newArn]);
          if (arnInputRef.current) {
            arnInputRef.current.value = '';
          }
          // Clear any previous errors
          setArnErrors({});
        } else {
          setArnErrors(prev => ({
            ...prev,
            [newArn]: 'Invalid ARN format'
          }));
        }
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        const arns = text.split('\n')
          .map(line => line.trim())
          .filter(line => line && !arnRoles.includes(line));

        // Validate number of ARNs
        if (arnRoles.length + arns.length > 100) {
          setArnErrors(prev => ({
            ...prev,
            limit: `Cannot add ${arns.length} ARNs. Maximum limit is 100 (currently ${arnRoles.length})`
          }));
          return;
        }

        // Validate each ARN
        const validArns = [];
        const newErrors = {};
        
        arns.forEach(arn => {
          if (validateArn(arn)) {
            validArns.push(arn);
          } else {
            newErrors[arn] = 'Invalid ARN format';
          }
        });

        if (validArns.length > 0) {
          setArnRoles(prevArns => [...prevArns, ...validArns]);
          setFileLoaded(true);
          setTimeout(() => setFileLoaded(false), 2000);
        }

        if (Object.keys(newErrors).length > 0) {
          setArnErrors(prev => ({...prev, ...newErrors}));
        }
      };
      reader.readAsText(file);
    });
  }, [arnRoles, setArnRoles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt']
    },
    multiple: false
  });

  const removeArn = (arnToRemove) => {
    setArnRoles(arnRoles.filter(arn => arn !== arnToRemove));
  };

  const handleOnClickRegenerate = () => {
    handleExternalIdChange(generateUid());
  };

  const onChangeIntegrationName = (ev) => {
    setIntegrationName(ev.target.value);
  };

  const handleResetArns = () => {
    setArnRoles([]);
    setArnErrors({});
    if (arnInputRef.current) {
      arnInputRef.current.value = '';
    }
  };

  // Custom styles for the dropzone
  const dropzoneStyles = {
    ...MuiStyles.dropzone,
    ...(isDragActive ? MuiStyles.dropzoneActive : {}),
    ...(fileLoaded ? {
      border: '2px dashed #4caf50',
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
    } : {
      border: '2px dashed #ccc',
      backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
    }),
    padding: '20px',
    marginTop: '10px',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    '&:hover': {
      border: '2px dashed #666',
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  };

  // Custom styles for ARN chips
  const arnChipStyles = {
    margin: '4px',
    backgroundColor: '#f0f7ff',
    border: '1px solid #90caf9',
    borderRadius: '16px',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& .MuiChip-label': {
      color: '#0d47a1',
      fontSize: '0.875rem',
    },
    '& .MuiChip-deleteIcon': {
      color: '#1976d2',
      '&:hover': {
        color: '#d32f2f',
      },
    },
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#e3f2fd',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  // Filter ARNs based on search term
  const filteredArns = useMemo(() => {
    return arnRoles.filter(arn => 
      arn.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [arnRoles, searchTerm]);

  // Group errors by type
  const groupedErrors = useMemo(() => {
    const groups = {
      limit: [],
      format: []
    };
    
    Object.entries(arnErrors).forEach(([arn, error]) => {
      if (arn === 'limit') {
        groups.limit.push(error);
      } else {
        groups.format.push(arn);
      }
    });
    
    return groups;
  }, [arnErrors]);

  const [showInvalidArns, setShowInvalidArns] = useState(false);

  // Add this new style for invalid ARNs box
  const invalidArnsBoxStyle = {
    marginTop: '4px',
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '150px',
    overflowY: 'auto',
    backgroundColor: 'rgba(211, 47, 47, 0.05)',
    border: '1px solid #d32f2f',
    borderRadius: '4px',
    padding: '8px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d32f2f',
      borderRadius: '4px',
      '&:hover': {
        background: '#b71c1c',
      },
    },
  };

  // Add this new component for invalid ARNs display
  const InvalidArnsDisplay = () => {
    const invalidArns = groupedErrors.format;
    if (invalidArns.length === 0) return null;

    return (
      <Box sx={{ marginBottom: '8px' }}>
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: 500,
          }}
          onClick={() => setShowInvalidArns(!showInvalidArns)}
        >
          {`Invalid format for ${invalidArns.length} ARN${invalidArns.length > 1 ? 's' : ''}`}
          <ArrowForwardIosSharpIcon 
            sx={{ 
              fontSize: '12px',
              marginLeft: '4px',
              transform: showInvalidArns ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }} 
          />
        </Typography>
        {showInvalidArns && (
          <Box sx={invalidArnsBoxStyle}>
            {invalidArns.map((arn, index) => (
              <Typography
                key={index}
                sx={{
                  color: '#d32f2f',
                  fontSize: '0.75rem',
                  fontFamily: 'monospace',
                  padding: '2px 4px',
                  borderRadius: '2px',
                  '&:hover': {
                    backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  },
                }}
              >
                {arn}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={MuiStyles.inputsSection}>
      {/* Integration Name */}
      <Box sx={MuiStyles.inputGroup}>
        <Box sx={MuiStyles.fieldBlock}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              Integration Name *
            </Typography>
          </Box>
          <InputBase
            value={integrationName}
            onChange={onChangeIntegrationName}
            placeholder="AWS Adapter"
            className={styles.accountIdInput}
          />
        </Box>
      </Box>

      {/* Account ID */}
      <Box sx={MuiStyles.inputGroup}>
        <Box sx={MuiStyles.fieldBlock}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              Account ID *
            </Typography>
          </Box>
          <InputBase
            id="accountId"
            label="Account ID *"
            type="number"
            value={account}
            onChange={handleAccountChange}
            placeholder="123456789012"
            readOnly={isGenerated}
            inputProps={{ inputMode: "numeric" }}
            className={styles.accountIdInput}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={MuiStyles.label}>
            <Typography variant="caption" className={styles.awsAccIdText}>
              External ID *
            </Typography>
          </Box>
          <InputBase
            id="externalId"
            className={styles.accountIdInput}
            value={externalId}
            onChange={handleExternalIdChange}
            required
            fullWidth
            placeholder="5e9140cf-1ee7-4351-a3ec-28cf1e7e69b"
            inputProps={{ maxLength: 36 }}
          />
          <Button
            onClick={handleOnClickRegenerate}
            sx={MuiStyles.regenerateBtn}
            variant="contained"
          >
            Regenerate
          </Button>
        </Box>
      </Box>

      {/* Manual Mode Fields */}
      <ConditionComponent condition={isManual}>
        <Box sx={MuiStyles.inputGroup}>
          <Box sx={MuiStyles.fieldBlock} style={{ width: '100%' }}>
            {/* ARN Input with Search */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              marginBottom: '8px'
            }}>
              <Box sx={MuiStyles.label}>
                <Typography variant="caption" className={styles.awsAccIdText}>
                  ARN Roles * ({arnRoles.length}/100)
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                {arnRoles.length > 0 && (
                  <>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        size="small"
                        onClick={() => setShowSearch(!showSearch)}
                        sx={{
                          color: showSearch ? '#1976d2' : '#666',
                          zIndex: 1,
                          padding: '4px',
                        }}
                      >
                        <SearchIcon fontSize="small" />
                      </IconButton>
                      {showSearch && (
                        <InputBase
                          placeholder="Search ARNs..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          sx={{
                            marginLeft: '-40px', // Align with search icon
                            paddingLeft: '40px',
                            width: '250px', // Make search box bigger
                            '& input': {
                              borderRadius: '4px',
                              border: '1px solid #e0e0e0',
                              padding: '6px 12px',
                              fontSize: '0.875rem',
                              height: '24px',
                              '&:focus': {
                                borderColor: '#1976d2',
                                boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.1)',
                              },
                            },
                          }}
                        />
                      )}
                    </Box>
                    <Button
                      onClick={handleResetArns}
                      startIcon={<DeleteSweepIcon />}
                      sx={{
                        minWidth: 'auto',
                        padding: '4px 8px',
                        fontSize: '0.75rem',
                        color: '#d32f2f',
                        '&:hover': {
                          backgroundColor: 'rgba(211, 47, 47, 0.04)',
                        },
                      }}
                    >
                      Clear All
                    </Button>
                  </>
                )}
              </Box>
            </Box>

            {/* ARN Input */}
            <Box sx={{ position: 'relative', width: '100%', marginBottom: '8px' }}>
              <InputBase
                inputRef={arnInputRef}
                placeholder="Enter ARN and press Enter"
                onKeyPress={handleArnKeyPress}
                fullWidth
                sx={{
                  '& input': {
                    borderRadius: '4px',
                    border: '1px solid #e0e0e0',
                    padding: '8px 12px',
                    width: '100%',
                    '&:focus': {
                      borderColor: '#1976d2',
                      boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.1)',
                    },
                  },
                }}
              />
            </Box>

            {/* Error Messages - Compact Version */}
            {groupedErrors.limit.length > 0 && (
              <Typography
                sx={{
                  color: '#d32f2f',
                  fontSize: '0.75rem',
                  marginBottom: '4px',
                }}
              >
                {groupedErrors.limit[0]}
              </Typography>
            )}
            <InvalidArnsDisplay />

            {/* ARN Chips with Scrolling */}
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              padding: '8px',
              maxHeight: '200px',
              overflowY: 'auto',
              border: arnRoles.length ? '1px solid #e0e0e0' : 'none',
              borderRadius: '4px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
                '&:hover': {
                  background: '#666',
                },
              },
            }}>
              {filteredArns.map((arn, index) => (
                <Chip
                  key={index}
                  label={arn}
                  onDelete={() => removeArn(arn)}
                  sx={arnChipStyles}
                  deleteIcon={<CloseIcon style={{ fontSize: '16px' }} />}
                />
              ))}
              {filteredArns.length === 0 && arnRoles.length > 0 && (
                <Typography sx={{ color: '#666', padding: '8px' }}>
                  No ARNs match your search
                </Typography>
              )}
            </Box>

            {/* Dropzone */}
            <Box {...getRootProps()} sx={dropzoneStyles}>
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ 
                fontSize: 40, 
                color: fileLoaded ? '#4caf50' : isDragActive ? '#1976d2' : '#666',
                transition: 'color 0.3s ease',
              }} />
              <Typography sx={{
                color: fileLoaded ? '#4caf50' : isDragActive ? '#1976d2' : '#666',
                transition: 'color 0.3s ease',
              }}>
                {fileLoaded 
                  ? 'File loaded successfully!'
                  : isDragActive
                    ? 'Drop the ARNs file here...'
                    : 'Drag and drop a file with ARNs (one per line), or click to select'}
              </Typography>
              <Typography variant="caption" sx={{ color: '#666' }}>
                Maximum 100 ARNs allowed
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* AWS Credentials */}
        <Box sx={MuiStyles.inputGroup}>
          <Box sx={MuiStyles.fieldBlock}>
            <Box sx={MuiStyles.label}>
              <Typography variant="caption" className={styles.awsAccIdText}>
                Access Key ID
              </Typography>
            </Box>
            <InputBase
              value={accessKeyId}
              onChange={(e) => setAccessKeyId(e.target.value)}
              placeholder="AWS Access Key ID"
              className={styles.accountIdInput}
              type="password"
            />
          </Box>
          <Box sx={MuiStyles.fieldBlock}>
            <Box sx={MuiStyles.label}>
              <Typography variant="caption" className={styles.awsAccIdText}>
                Secret Access Key
              </Typography>
            </Box>
            <InputBase
              value={secretAccessKey}
              onChange={(e) => setSecretAccessKey(e.target.value)}
              placeholder="AWS Secret Access Key"
              className={styles.accountIdInput}
              type="password"
            />
          </Box>
        </Box>
      </ConditionComponent>

      {/* Advanced Section */}
      <Box sx={MuiStyles.advancedSection}>
        <Accordion disableGutters sx={MuiStyles.advancedAccordion}>
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon sx={MuiStyles.accordionSummaryIcon} />
            }
            sx={MuiStyles.advancedAccordionSummary}
          >
            Advanced
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={MuiStyles.fieldBlock}>
              <Typography variant="caption" className={styles.awsAccIdText}>
                Self Role
              </Typography>
              <InputBase
                id="selfRole"
                label="Self Role"
                value={selfRole}
                className={styles.accountIdInput}
                onChange={handleSelfRoleChange}
                placeholder="arn:aws:iam::111111111111:role/company-assume-role"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default AccountInput;
