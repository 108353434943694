import axios from "axios";
import { AssetPropertyType } from "../components/G6Graph/GraphGrouping/data";
import { AssetPropertyUtils } from "../containers/inventory/AssetsTable/utils/AssetPropertyUtils";
import { DataGroupsSources } from "../components/Grouping/data";
import { filtersToQueryParamTransformer } from "../infrastructure/api/utils/transformers/filtersToQueryParamTransformer";

const organization_id = localStorage.getItem("organization_id") || "";

// Create an axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "X-ORG-ID": organization_id,
  },
});

export const authenticateGithub = async (auth0Token, code) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`, // Send Auth0 token in headers for authentication
    },
  };

  // Send the GitHub code and Auth0 token to your backend
  const response = await api.post(
    "/connectors/github/oauth/callback",
    { code },
    config
  );
  return response;
};

export const checkOnboarding = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const response = await api.get(
    "/connectors/onboarding", // replace with actual endpoint
    config
  );

  return response.data.state; // Assume that the server will respond with { isOnboarded: boolean }
};

export const getAdapters = async ({ token, organization_id }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await api.get(
    `/adapters/?organization_uuid=${organization_id}&skip=0&limit=100`,
    config
  );

  return response.data; // Assume that the server will respond with { isOnboarded: boolean }
};

export const deleteAdapter = async ({ token, id, organization_id }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await api.delete(
    `/adapters/${id}?organization_uuid=${organization_id}&skip=0&limit=100`,
    config
  );

  return response.data; // Assume that the server will respond with { isOnboarded: boolean }
};

export const finishOnboarding = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const response = await api.put(
    "/connectors/onboarding/finish",
    {}, // This is the data object
    config
  );

  return response.data;
};

export const sendSlackNotification = async (payload) => {
  const webhookUrl =
    "https://hooks.slack.com/services/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";
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const response = await axios.post(
    webhookUrl,
    `payload=${JSON.stringify(payload)}`,
    config
  );

  return response;
};

export const sendAwsOnboarding = async (
  account,
  role,
  external_id,
  self_role,
  token,
  name,
  role_arns,
  accessKeyId,
  secretAccessKey,
  regions = []
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    account,
    external_id,
    name,
  };

  // Add optional fields
  if (self_role) data.self_role = self_role;
  if (role) data.aws_assume_role = role;
  if (role_arns?.length > 0) data.role_arns = role_arns;
  if (accessKeyId) data.access_key_id = accessKeyId;
  if (secretAccessKey) data.secret_access_key = secretAccessKey;
  if (regions.length > 0) data.regions = regions;

  const response = await api.post("/connectors/aws/connect", data, config);
  return response;
};

export const getCountermeasures = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const response = await api.get(
    "/countermeasures/list", // replace with actual endpoint
    config
  );

  return response.data; // Assume that the server will respond with { isOnboarded: boolean }
};

export const updateCounterMeasureStatus = async (auth0Token, id, status) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const response = await api.put(
    `/countermeasures/update_progress/${id}`, // Endpoint matching your backend
    { status }, // Sending the new status
    config
  );

  return response.data; // You may structure the response as needed
};

export const calculateFairRisk = async (data, auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.post(
      "/quantification/event_risk_calculator",
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const graphv2 = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const response = await api.get(
    "/graph/v2/all", // replace with actual endpoint
    config
  );

  return response.data; // Assume that the server will respond with { isOnboarded: boolean }
};

export const getIntegratedServices = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.get("/integrations/list", config);
    return response.data;
  } catch (error) {
    console.error("Error fetching integrated services", error);
    throw error;
  }
};

export const sendBitbucketOnboarding = async (
  label_name,
  username,
  app_token,
  domain,
  auth0Token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const data = {
    label_name,
    username,
    app_token,
    domain,
  };

  try {
    const response = await api.post(
      "/connectors/bitbucket/connect",
      data,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendJiraOnboarding = async (
  api_token,
  domain,
  email,
  name,
  auth0Token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const data = {
    api_token,
    domain,
    email,
    name,
  };

  try {
    const response = await api.post("/connectors/jira/connect", data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getConnectorsConnectionStatus = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.get("/connectors/connection_status", config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteIntegration = async (auth0Token, name) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.post(
      "/connectors/delete",
      { source: name },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addGithubApiAccessKey = async (
  auth0Token,
  key,
  integrationName
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.post(
      "/connectors/github/api_key",
      { access_key: key, name: integrationName },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const likeMessage = async (auth0Token, message_content) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const body = {
    message_content,
  };

  try {
    const response = await api.post(
      "feedback/like-message", // Use the consistent URL structure
      body,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error liking message:", error);
    throw error;
  }
};

export const dislikeMessage = async (auth0Token, message_content) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const body = {
    message_content,
  };

  try {
    const response = await api.post(
      "feedback/dislike-message", // Use the consistent URL structure
      body,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error disliking message:", error);
    throw error;
  }
};
export const fetchSemanticAnswer = async (auth0Token, question, history) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  const body = {
    question: question,
    history: history.map((item) => ({
      content: item.content,
      type: item.type,
    })),
  };

  return await api.post("query/query-semantic-answer", body, config);
};

export const getInventory = async (
  auth0Token,
  page,
  limit,
  assetTypes,
  searchQuery
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const apiPage = page + 1;
    let url = "/inventory/assets/v2?page=" + apiPage + "&limit=" + limit;

    const hasAssetTypes = assetTypes && assetTypes.length > 0;

    if (hasAssetTypes) {
      url += "&asset_type=" + assetTypes.join(",");
    }

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssetsCount = async (auth0Token, assetTypes, search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    let url = "/inventory/assets/count";
    let has_query = false;

    if (assetTypes) {
      url += "?asset_type=" + assetTypes.join(",");
      has_query = true;
    }

    if (search) {
      url += has_query ? "&" : "?";
      url += "search=" + search;
    }

    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssetInfo = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `/inventory/asset/v2/${id}`;
  const response = await api.get(url, config);
  return response.data.data;
};

export const getGraphAssetInfo = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `/inventory/asset/${id}`;
  const response = await api.get(url, config);
  return response.data;
};

export const getAssetTypes = async (auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };

  try {
    const response = await api.get("/inventory/types", config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssetFilterOptions = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await api.get("/inventory/filter-options", config);
  return response.data;
};

export const getAssetGroupOptions = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await api.get("/inventory/group-options/v2", config);
  return response.data.data;
};

const createGroupQueryString = (group) => {
  const { group: groupBy, value } = group;
  const source = groupBy?.source ?? DataGroupsSources.PLATFORM;
  const groupByValue = groupBy?.value ?? "";
  const str = `group=${source}.${groupByValue},${value}`;
  return str;
};

export const getGroupedAssets = async (token, payload) => {
  const { page = 0, limit = 0, group, value, propertyType } = payload;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const pageQuery = page + 1;

  let query = `page=${pageQuery}`;

  if (limit) query += `&limit=${limit}`;

  if (group) {
    let groupQueryStr = group;
    if (propertyType === AssetPropertyType.PLATFORM_COMMON_FIELD) {
      groupQueryStr =
        AssetPropertyUtils.createPropertyAsCommonPlatformField(group);
    } else if (propertyType === AssetPropertyType.USER) {
      groupQueryStr = AssetPropertyUtils.createPropertyAsCustomerField(group);
    }

    query += `&group=${groupQueryStr},${value}`;
  }

  const response = await api.get(`/inventory/assets/v2?${query}`, config);
  return response.data.data;
};

export const getInventoryAssetsByFilters = async (token, payload) => {
  const { filters, page, limit, group, searchKeyword } = payload;
  const filtersQueryStr = filtersToQueryParamTransformer(filters);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const pageQuery = page + 1;
  let query = `page=${pageQuery}`;
  if (limit) query += `&limit=${limit}`;
  if (filtersQueryStr) query += `&${filtersQueryStr}`;
  if (group && group.value) {
    const groupQuery = createGroupQueryString(group);
    query += `&${groupQuery}`;
  }
  if (searchKeyword) {
    query += `&search=${searchKeyword}`;
  }

  const response = await api.get(`/inventory/assets/v2?${query}`, config);
  return response.data;
};

export const addWizIntegration = async (token, payload) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await api.post(`/connectors/wiz/connect`, payload, config);
};

export const addHibobIntegration = async (token, payload) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await api.post(`/connectors/hibob/connect`, payload, config);
};

export const sendGoogleWorkspaceOnboarding = async (data, auth0Token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await api.post("/connectors/gsuite/connect", data, config);
    return response;
  } catch (error) {
    throw error;
  }
};
