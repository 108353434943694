import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { createUsername } from "../app/user-management/createUsername";
import { AppFeatures } from "../core/features";

function configureGraphVersion(payload) {
  const updatedPayload = { ...payload };
  const DEFAULT_VERSION = 1;
  updatedPayload.graphVersion = DEFAULT_VERSION;
  if (payload.privileges?.[AppFeatures.MONGO_GRAPH]) {
    updatedPayload.graphVersion = 2;
  }

  return updatedPayload;
}

export const useUserInfo = () => {
  const { user } = useAuth0();
  const data = useSelector((state) => state.user);
  const username = createUsername(user);

  const payload = configureGraphVersion(data);

  return { ...payload, username };
};
