import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
  Typography,
  Input as MuiInput,
} from "@mui/material";
import RootSelector from "react-select";

export const Root = styled(Dialog)(({ theme }) => {
  return `
  & .MuiDialog-paper {
    width: 550px;
    max-height: 600px;
    overflow-y: auto;
    background-color: ${theme.palette.background.paper};
    }
  `;
});

export const Title = styled(DialogTitle)(() => {
  return `
  
  `;
});

export const Content = styled(DialogContent)(() => {
  return `
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  `;
});

export const Footer = styled(DialogActions)(() => {
  return `
  
  `;
});

export const CancelButton = styled((props) => (
  <Button {...props} variant='outlined' />
))(() => {
  return ``;
});

export const SubmitButton = styled((props) => (
  <Button {...props} variant='contained' />
))(() => {
  return ``;
});

export const Input = styled(TextField)(() => {
  return `
  `;
});

export const Form = styled("form")(() => {
  return `
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  `;
});

export const Selector = styled((props) => {
  return (
    <RootSelector
      components={{
        IndicatorSeparator: null,
      }}
      {...props}
    />
  );
})(() => {
  return `
  font-size: 12px;
  `;
});

export const FormGroupRoot = styled("div")(() => {
  return `
  display: flex;
  flex-direction: column;
  `;
});

export const FormGroupLabel = styled((props) => (
  <Typography variant='caption' {...props} />
))(({ error, theme }) => {
  const color = error ? theme.palette.error.main : "#000";
  return `
  color: ${color};
  font-size: 12px;
  font-weight: 600;
  `;
});

export const FormInput = styled(MuiInput)(({ theme }) => {
  const { grey } = theme.palette;
  return `
  border: 1px solid ${grey[500]};
  font-size: 12px;
  padding: 0 8px;
  border-radius: 4px;
  `;
});

export const ConditionsRoot = styled(FormGroupRoot)(() => {
  return `
  `;
});

export const ConditionEntryRoot = styled("div")(() => {
  return `
  display: flex;
  column-gap: 24px;
  `;
});

export const ConditionSelector = styled(Selector)(() => {
  return `
  width: 200px;
  `;
});

export const ConditionThresholdInput = styled(FormInput)`
  width: 200px;
`;

export const FormText = styled((props) => (
  <Typography variant='body2' {...props} />
))(() => {
  return `
 && {
  font-size: 12px;
  font-weight: 400;
  color: #000 !important;
 }

  `;
});

export const ComingSoon = styled((props) => (
  <Typography variant='body2' children='(coming soon)' {...props} />
))(() => {
  return `
 && {
  font-size: 12px;
  font-weight: 600;
  color: #7CB9E8 !important;
  display: inline;
  font-style: italic;
  margin-left: 6px;
 }
  `;
});
