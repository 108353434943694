import { AssetInfoContainer, DrawerBody as Body } from "./styled";
import { DrawerHeader } from "./header";
import { RenderWithContext } from "../../../../utils/hocs/RenderWithContext";
import { AssetInfoProvider } from "./context/index";
import { DrawerBody } from "./DrawerBody";
import { useGetAssetDetailState } from "./hooks/useStateManager";
import ConditionComponent from "../../../../utils/ConditionComponent";
import { LoadingSection } from "../AssetDetailDrawer/EmptySections";
import { useAssetsTableMethods } from "../../../../containers/inventory/AssetsTable/hooks/useAssetsTableMethods";

const Component = ({ open, onClose }) => {
  const { data, loading, error } = useGetAssetDetailState();
  const { closeAssetDrawer } = useAssetsTableMethods();

  const handleOnClose = () => {
    try {
      onClose?.() ?? closeAssetDrawer?.();
    } catch (error) {}
  };

  return (
    <AssetInfoContainer anchor='right' open={open} onClose={handleOnClose}>
      <DrawerHeader actions={{ toggleDrawer: handleOnClose }} />

      <Body>
        <ConditionComponent condition={loading}>
          <LoadingSection />
        </ConditionComponent>
        <ConditionComponent condition={error || !data?.asset}>
          {error}
        </ConditionComponent>
        <ConditionComponent
          condition={!loading && !error && data?.asset && open}
        >
          <DrawerBody />
        </ConditionComponent>
      </Body>
    </AssetInfoContainer>
  );
};

export const AssetDetailsView = RenderWithContext(Component, AssetInfoProvider);
