import { DataGroupsSources } from "../../components/Grouping/data";
import { getValueByPath } from "../../containers/inventory/AssetsTable/utils/getGraphColumnValuesFromData";
import { DataFilterOperators } from "../../core/asset-inventory/filterOperators";
import { AppEnv } from "../../utils/AppEnv";

export function applyGraphDataFiltering(data, filters, showRelatedNodes) {
  if (!filters.filterGroups.length) return data;
  const filteredNodes = data.nodes.filter((node) =>
    nodeFilteringStrategy(node, filters.filterGroups)
  );
  let nodes = filteredNodes;
  if (showRelatedNodes) {
    const withConnectedNodes = getAllRelatedNodes(data, filteredNodes);
    nodes = withConnectedNodes;
  }
  const nodesIdMap = createNodesIdMap(nodes);
  const edges = data.edges.filter((edge) =>
    edgesFilteringStrategy(edge, nodesIdMap)
  );
  return { nodes, edges };
}

function getAllRelatedNodes(data, filteredNodes) {
  const { nodes, edges } = data;
  const adjacencyList = new Map();

  // Building adjacency list
  nodes.forEach((node) => adjacencyList.set(node.id, []));
  edges.forEach((edge) => {
    adjacencyList.get(edge.source)?.push(edge.target);
    adjacencyList.get(edge.target)?.push(edge.source);
  });

  const sources = [...filteredNodes];
  const visited = new Set();
  const allConnectedNodes = [];

  while (sources.length) {
    const currentNode = sources.shift();
    if (!visited.has(currentNode.id)) {
      allConnectedNodes.push(currentNode);
      visited.add(currentNode.id);
      adjacencyList.get(currentNode.id).forEach((neighborId) => {
        const neighborNode = nodes.find((node) => node.id === neighborId);
        if (neighborNode && !visited.has(neighborId)) {
          sources.push(neighborNode);
        }
      });
    }
  }

  return allConnectedNodes;
}

function nodeFilteringStrategy(node, filters) {
  return applyFiltersToNode(node, filters);
}

function edgesFilteringStrategy(edge, nodesIdMap) {
  const hasSource = nodesIdMap[edge.source];
  const hasTarget = nodesIdMap[edge.target];
  return hasSource && hasTarget;
}

function applyFiltersToNode(node, filters) {
  const passes = filters.some((filter) => {
    const passes = checkNodePassesFilter(node, filter);
    return passes;
  });
  return passes;
}

function createNodeValuePathFromField(field) {
  const { source, value } = field;
  if (source !== DataGroupsSources.PLATFORM)
    return `adapters.${source}.${value}`;
  return value;
}

function transformFilterValue(payload) {
  if (payload.length === 1) {
    return payload[0].value;
  }
  return null;
}

function checkNodePassesFilter(node, filter) {
  const { values: rawValue, field, operator } = filter;
  const operatorValue = operator.value;
  const filterValue = transformFilterValue(rawValue);

  const nodeValuePath = createNodeValuePathFromField(field);
  const nodeValue = getValueByPath(node, nodeValuePath);

  if (operatorValue === DataFilterOperators.IS.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((val) => val === filterValue);
    } else {
      return nodeValue === filterValue;
    }
  }

  if (operatorValue === DataFilterOperators.IS_NOT.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.every((val) => val !== filterValue);
    } else {
      return nodeValue !== filterValue;
    }
  }

  const nodeValueEmpty = [
    DataFilterOperators.IS_EMPTY.value,
    DataFilterOperators.DOES_NOT_EXIST.value,
  ].includes(operatorValue);

  if (nodeValueEmpty) {
    if (Array.isArray(nodeValue)) return !nodeValue.length;
    return !nodeValue;
  }

  const nodeValueExists = [
    DataFilterOperators.IS_NOT_EMPTY.value,
    DataFilterOperators.EXISTS.value,
  ].includes(operatorValue);

  if (nodeValueExists) {
    if (Array.isArray(nodeValue)) return nodeValue.length;
    return !!nodeValue;
  }

  if (operatorValue === DataFilterOperators.IS_ONE_OF.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        return rawValue.some(({ value }) => value === nVal);
      });
    }
    return rawValue.some((rVal) => rVal === nodeValue);
  }

  if (operatorValue === DataFilterOperators.IS_NOT_ONE_OF.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.every((nVal) => {
        return rawValue.every(({ value }) => value !== nVal);
      });
    }
    return rawValue.every((rVal) => rVal !== nodeValue);
  }

  if (operatorValue === DataFilterOperators.CONTAINS.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        return nVal.includes(filterValue);
      });
    }
    return nodeValue.includes(filterValue);
  }

  if (operatorValue === DataFilterOperators.DOES_NOT_CONTAIN.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.every((nVal) => {
        return !nVal.includes(filterValue);
      });
    }
    return !nodeValue.includes(filterValue);
  }

  if (operatorValue === DataFilterOperators.STARTS_WITH.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        const res = nVal.startsWith(filterValue);
        return res;
      });
    }
    return nodeValue.startsWith(filterValue);
  }

  if (operatorValue === DataFilterOperators.ENDS_WITH.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        return nVal.endsWith(filterValue);
      });
    }
    return nodeValue.endsWith(filterValue);
  }

  if (operatorValue === DataFilterOperators.LESS_THAN_OR_EQ.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        if (typeof nVal !== "number") return false;
        return nVal < filterValue || nVal === filterValue;
      });
    }
    return nodeValue < filterValue || nodeValue === filterValue;
  }

  if (operatorValue === DataFilterOperators.GREATER_THAN_OR_EQ.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        if (typeof nVal !== "number") return false;
        return nVal > filterValue || nVal === filterValue;
      });
    }
    return nodeValue > filterValue || nodeValue === filterValue;
  }

  if (operatorValue === DataFilterOperators.SMALLER_THAN.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        if (typeof nVal !== "number") return false;
        return nVal < filterValue;
      });
    }
    return nodeValue < filterValue;
  }

  if (operatorValue === DataFilterOperators.BIGGER_THAN.value) {
    if (Array.isArray(nodeValue)) {
      return nodeValue.some((nVal) => {
        if (typeof nVal !== "number") return false;
        return nVal > filterValue;
      });
    }
    return nodeValue > filterValue;
  }
}

function createNodesIdMap(nodes) {
  return nodes.reduce((store, node) => {
    store[node.id] = node;
    return store;
  }, {});
}

if (AppEnv.env === "test") {
  module.exports = {
    getAllRelatedNodes,
  };
}
