import { SystemInsights } from "../../../../utils/logger/SystemInsights";

const {
  AssetInventoryTableFixedColumns,
} = require("../../../../core/asset-inventory/data");

export const createSavedQueryViewPayload = (
  savedQueryPayload,
  assetsSchema,
  payloadAdapter
) => {
  SystemInsights.log("fn- createSavedQueryViewPayload - ", {
    savedQueryPayload,
    assetsSchema,
  });

  if (!payloadAdapter) throw new Error("No payload adapter found");

  let primaryTableColumns = AssetInventoryTableFixedColumns;
  let grouping = [],
    currentGroupingValue = null;

  const filters = payloadAdapter.transformFiltersToValidOptions(
    savedQueryPayload.filters,
    assetsSchema
  );
  if (savedQueryPayload.columns.length) {
    const columns = payloadAdapter.transformColumnsToValidOptions(
      savedQueryPayload.columns
    );
    if (columns.length) primaryTableColumns = columns;
  }
  if (savedQueryPayload.group) {
    const { currentGroupingValue: groupingValue, group } =
      payloadAdapter.transformGroupToValidOption(savedQueryPayload.group);
    grouping = [group];
    currentGroupingValue = groupingValue;
  }

  SystemInsights.log("fn- createSavedQueryViewPayload - END", {
    filters,
    primaryTableColumns,
    grouping,
    currentGroupingValue,
  });

  return {
    filters,
    grouping,
    currentGroupingValue,
    searchKeyword: savedQueryPayload.search ?? "",
    primaryTableColumns,
    paginationConfig: {
      pageIndex: 0,
      pageSize: 20,
    },
    saveQueryPayload: savedQueryPayload ?? {},
  };
};
