import React from "react";
import { Box } from "@mui/material";
import CardHeader from "./cardHeader";
import CardFooter from "./cardFooter";
import {
  ContentRoot,
  IntegrationBodyRoot,
  IntegrationCardContentRoot,
  IntegrationFooterRoot,
  IntegrationHeaderRoot,
  IntegrationsContainer,
  StepsRoot,
} from "../../../containers/googleWorkspaceIntegration/styled";

const IntegrationLayout = ({
  multisteps,
  headerLogo,
  headerTitle,
  content,
  footerButtons,
  contentRootProps = {},
}) => (
  <IntegrationsContainer>
    <StepsRoot>{multisteps}</StepsRoot>
    <IntegrationCardContentRoot {...contentRootProps}>
      <IntegrationHeaderRoot>
        <CardHeader logo={headerLogo} title={headerTitle} />
      </IntegrationHeaderRoot>
      <IntegrationBodyRoot className='scrollable'>
        <ContentRoot>{content}</ContentRoot>
      </IntegrationBodyRoot>
      <IntegrationFooterRoot>
        <CardFooter buttons={footerButtons} />
      </IntegrationFooterRoot>
    </IntegrationCardContentRoot>
  </IntegrationsContainer>
);

export default IntegrationLayout;
