import { useEffect } from "react";
import { getAssetName } from "../utils/getAssetName";
import { getIconURL } from "../utils/getIconURL";
import { applyGraphDataFiltering } from "../applyGraphDataFiltering";
import { GraphGroupingTarget, GraphLayoutType } from "../constants";
import { createCombosFromNodes } from "../utils/createGraphCombosFromNodes";
import { DataGroupsSources } from "../../../components/Grouping/data";

export function useInitGraph({
  data,
  filters,
  searchInput,
  showRelatedNodes,
  showAllEntities,
  groupingTarget,
  grouping,
  setLayoutType,
  setGraphData,
}) {
  useEffect(() => {
    if (!data) return;
    let nodes,
      edges = data.edges;

    const updatedNodes = data.nodes.map((node) => {
      const deploymentType = node.deployment || node.category;
      const label = getAssetName(node.name);
      return {
        ...node,
        name: label,
        label,
        fullName: node.name,
        iconURL: getIconURL(deploymentType),
      };
    });

    nodes = updatedNodes;

    if (filters.length) {
      const { edges: filteredEdges, nodes: filteredNodes } =
        applyGraphDataFiltering(
          { nodes: updatedNodes, edges: data.edges },
          {
            search: searchInput,
            filterGroups: filters,
          },
          showRelatedNodes
        );
      edges = filteredEdges;
      nodes = filteredNodes;
    }

    if (!showAllEntities) {
      const connectedNodeIds = new Set(
        data.edges.flatMap((edge) => [edge.source, edge.target])
      );
      nodes = nodes.filter((node) => connectedNodeIds.has(node.id));
    }

    if (groupingTarget === GraphGroupingTarget.ALL) {
      nodes = updatedNodes;
      edges = data.edges;
    }

    const graphData = { nodes, edges };
    if (grouping.length) {
      const comboProperties = createComboPropsFromGrouping(grouping);
      const combos = createCombosFromNodes(nodes, comboProperties);
      graphData.combos = combos;
      setLayoutType(GraphLayoutType.COMBO);
    } else {
      setLayoutType(GraphLayoutType.DEFAULT);
    }
    setGraphData(graphData);
  }, [
    data,
    filters,
    searchInput,
    showRelatedNodes,
    showAllEntities,
    groupingTarget,
    grouping,
  ]);
}

function createComboPropsFromGrouping(grouping) {
  return grouping.map((group) => {
    if (group.source !== DataGroupsSources.PLATFORM) {
      const { source, value } = group;
      return `adapters.${source}.${value}`;
    }
    return group.value;
  });
}
