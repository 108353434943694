import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import BPSettingSection from "../components/settings/BPSection";
import UserManagementSettingSection from "../components/settings/UserManagement";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FeatureService from "../services/feturesService";
import IntegrationsSection from "../components/settings/IntegrationsSection";
import { useUserInfo } from "../hooks/useAppUser";

function SettingsPage() {
  const userInfo = useUserInfo();

  // Render the rest of your component with the fetched processes
  return null;
  // <>
  //   <Container maxWidth={false}>
  //     <Grid
  //       container
  //       justifyContent='center'
  //       alignItems='flex-start'
  //       spacing={2}
  //     >
  //       {userInfo.privileges["BP_SETTINGS"] && (
  //         <Grid item xs={12}>
  //           <BPSettingSection />
  //         </Grid>
  //       )}
  //       {userInfo.privileges["USER_MGMT"] && (
  //         <Grid item xs={12}>
  //           <UserManagementSettingSection />
  //         </Grid>
  //       )}
  //       <Grid item xs={12}>
  //         <IntegrationsSection />
  //       </Grid>
  //     </Grid>
  //   </Container>
  // </>
}

export default SettingsPage;
