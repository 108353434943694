import { Fragment, useCallback, useEffect, useMemo } from "react";
import {
  DataSection,
  FieldLabel,
  NavContainer,
  NavItemContainer,
  RowContainer,
} from "./styled";
import { InfoValue } from "./Value";
import { useLocation, Switch, Route, useHistory } from "react-router-dom";
import { AppRoutingConfig } from "../../../../utils/routing/RouteConfig";
import { GeneralPage } from "./subPages/General";
import { RelationsPage } from "./subPages/Relations";
import { TrackingPage } from "./subPages/Tracking";
import { SecurityPage } from "./subPages/Security";
import { BusinessPage } from "./subPages/Business";
import { useGetAssetDetailState } from "./hooks/useStateManager";
import { selectAssetDetails } from "./subPages/utils/assetDetailsSelector";

const { inventory } = AppRoutingConfig;
const { AssetInfo: AssetInfoRouting } = inventory;

export const DrawerBody = () => {
  const { data: assetData } = useGetAssetDetailState();
  const location = useLocation();
  const history = useHistory();

  const details = useMemo(() => {
    return selectAssetDetails(assetData);
  }, [assetData]);

  const checkRouteIsActive = useCallback(
    (path) => {
      const endsWithPath = location.pathname?.endsWith(path);
      return endsWithPath;
    },
    [location]
  );

  const handleOnClickNavItem = (subPage) => {
    const path = createSubPageLink(details.id, subPage);
    history.push(path);
  };

  const assetSourcesNodes = useMemo(() => {
    return details.sources.map((value) => (
      <InfoValue
        containerProps={{ sx: { maxWidth: "30%", width: "20%" } }}
        key={value}
        options={{ copy: true }}
        data={{ value }}
      />
    ));
  }, [details.sources]);

  const assetNamesNodes = useMemo(() => {
    let styles = {};
    if (details.names.length > 1) styles.maxWidth = "50%";

    return details.names.map((value) => (
      <InfoValue
        containerProps={{ sx: styles }}
        key={value}
        options={{ copy: true }}
        data={{ value }}
      />
    ));
  }, [details.names]);

  useEffect(() => {
    if (assetData) {
      handleOnClickNavItem(AssetInfoRouting.GENERAL);
    }
  }, []);

  return (
    <Fragment>
      <DataSection>
        <FieldLabel>Asset Name</FieldLabel>
        <RowContainer wrap>{assetNamesNodes}</RowContainer>
      </DataSection>
      <DataSection>
        <FieldLabel>Source</FieldLabel>
        <RowContainer>{assetSourcesNodes}</RowContainer>
      </DataSection>
      <NavContainer>
        <NavItemContainer
          onClick={() => handleOnClickNavItem(AssetInfoRouting.GENERAL)}
          active={checkRouteIsActive(AssetInfoRouting.GENERAL)}
        >
          General
        </NavItemContainer>
        <NavItemContainer
          onClick={() => handleOnClickNavItem(AssetInfoRouting.RELATIONS)}
          active={checkRouteIsActive(AssetInfoRouting.RELATIONS)}
        >
          Relations
        </NavItemContainer>
        <NavItemContainer
          onClick={() => handleOnClickNavItem(AssetInfoRouting.TRACKING)}
          active={checkRouteIsActive(AssetInfoRouting.TRACKING)}
        >
          Tracking
        </NavItemContainer>
        <NavItemContainer
          onClick={() => handleOnClickNavItem(AssetInfoRouting.SECURITY)}
          active={checkRouteIsActive(AssetInfoRouting.SECURITY)}
        >
          Security
        </NavItemContainer>
        <NavItemContainer
          onClick={() => handleOnClickNavItem(AssetInfoRouting.BUSINESS)}
          active={checkRouteIsActive(AssetInfoRouting.BUSINESS)}
        >
          Business
        </NavItemContainer>
      </NavContainer>
      <Switch>
        <Route
          path={createSubPageLink(details.id, AssetInfoRouting.GENERAL)}
          exact
          component={GeneralPage}
        />
        <Route
          path={createSubPageLink(details.id, AssetInfoRouting.RELATIONS)}
          exact
          component={RelationsPage}
        />
        <Route
          path={createSubPageLink(details.id, AssetInfoRouting.TRACKING)}
          exact
          component={TrackingPage}
        />
        <Route
          path={createSubPageLink(details.id, AssetInfoRouting.SECURITY)}
          exact
          component={SecurityPage}
        />
        <Route
          path={createSubPageLink(details.id, AssetInfoRouting.BUSINESS)}
          exact
          component={BusinessPage}
        />
      </Switch>
    </Fragment>
  );
};

function createSubPageLink(id, subPage) {
  const root = window.location.pathname.split("/")[1];
  return `/${root}/asset-info/${id}/${subPage}`;
}
