import { capitalize } from "@mui/material";

const MAX_COLUMN_NAME = 15;

function shortenName(name) {
  const len = name.length;

  const prefix = name.slice(0, 7);
  const suffix = name.slice(len - 4, len);
  return `${prefix}...${suffix}`;
}

export function shortenColumnName(columnName) {
  if (!columnName) return "";
  let name = "";
  const isLonger = columnName.length >= MAX_COLUMN_NAME;

  if (!isLonger) name = columnName;
  else {
    name = shortenName(columnName);
  }
  return capitalize(name);
}
