import { createSlice } from "@reduxjs/toolkit";
import { CMSTProjectsPageInitialState } from "./initialState";
import { getCMSTProjectsPageData } from "./thunks/getCMSTProjectsPageData";

const slice = createSlice({
  name: "cmst-projects",
  initialState: CMSTProjectsPageInitialState,
  reducers: {
    updateSaveQueryPayload(state, action) {
      state.saveQueryPayload = {
        ...(state.saveQueryPayload ?? {}),
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getCMSTProjectsPageData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.data = null;
      state.isError = false;
      state.errorPayload = null;
      state.saveQueryPayload = null;
    });

    builder.addCase(getCMSTProjectsPageData.fulfilled, (state, { payload }) => {
      const { schema, summary, saveQueryPayload } = payload;
      state.schema = schema;
      state.summary = summary;
      state.saveQueryPayload = saveQueryPayload;
      state.isLoading = false;
      state.isError = false;
      state.errorPayload = null;
    });

    builder.addCase(getCMSTProjectsPageData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.data = null;
      state.isError = true;
      state.errorPayload = payload as any;
    });
  },
});
const reducer = slice.reducer;

const CMSTProjectsPageActions = slice.actions;

export { reducer, CMSTProjectsPageActions };
