import { createOptionId } from "../../../../components/Grouping/utils/createOptionId";
import { getOperatorByValue } from "../../../../core/data-filtering/getOperatorByValue";

const payloadEncoder = (payload) => {
  return btoa(JSON.stringify(payload));
};

const payloadDecoder = (encodedString) => {
  return atob(encodedString);
};

const applyFilterValueTransforms = ({ label, source, value }) => {
  return { label, source, value };
};

const sanitizeFilter = ({ operator, conjunction, field, values }) => {
  const valuesPayload = values.map(applyFilterValueTransforms);
  const operatorPayload = {
    value: operator.value,
  };
  const conjunctionPayload = {
    value: conjunction.value,
  };
  const fieldPayload = {
    value: field.value,
    source: field.source,
  };
  const payload = {
    operator: operatorPayload,
    field: fieldPayload,
    conjunction: conjunctionPayload,
    values: valuesPayload,
  };
  return payload;
};

const sanitizeGrouping = (grouping) => {
  const {
    group: { value, source },
    option,
  } = grouping;
  const groupPayload = {
    source,
    value,
    label: value,
  };
  return {
    group: groupPayload,
    option,
  };
};

const sanitizer = (payload) => {
  const updated = { ...payload };
  if (payload.filters?.length) {
    updated.filters = payload.filters.map(sanitizeFilter);
  }
  const isGrouping =
    (Array.isArray(payload.grouping) && payload.grouping.length) ||
    (!Array.isArray(payload.grouping) && payload.grouping);
  console.log("only", payload.grouping);
  if (isGrouping) {
    updated.grouping = sanitizeGrouping(payload.grouping);
  }
  return updated;
};

const encodeFiltersToUrl = (_payload, sanitize = true) => {
  let payload = _payload;
  if (sanitize) {
    payload = sanitizer(payload);
  }
  return payloadEncoder(payload);
};

const decodeGrouping = (grouping) => {
  const { group, option } = grouping;

  const groupPayload = {
    ...group,
    checked: false,
    disabled: false,
  };

  groupPayload.id = createOptionId(groupPayload, "options");

  return {
    option,
    group: groupPayload,
  };
};

const decodeUrlToFilters = (encodedStr) => {
  const decodedStr = payloadDecoder(encodedStr);

  const parsed = JSON.parse(decodedStr);
  const payload = {
    ...parsed,
  };
  if (parsed.filters) {
    const parsedFilters = parsed.filters.map((filter) => {
      if (filter.operator?.value) {
        const operator = getOperatorByValue(filter.operator.value);
        return { ...filter, operator };
      }
      return filter;
    });
    payload.filters = parsedFilters;
  }
  if (parsed.grouping) {
    const parsedGrouping = decodeGrouping(parsed.grouping);
    payload.grouping = parsedGrouping;
  }
  return payload;
};

export const InventoryFilterUrlParser = Object.freeze({
  encodeFiltersToUrl,
  decodeUrlToFilters,
});
