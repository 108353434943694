export function doNothing() {
  //
}

export function returnNull() {
  //
  return null;
}

export function identityFn(val) {
  //
  return val;
}
