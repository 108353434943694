import { Fragment, useState } from "react";
import { MenuContainer, MenuContentContainer } from "./styled";
import { FilterTriggerSelectorContainerRef } from "./data";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { MenuHeader } from "../../views/G6Graph/components/FilterSelector/MenuHeader";
import { EmptyFilters } from "../../views/G6Graph/components/FilterSelector/EmptyFilters";
import ConditionComponent from "../../utils/ConditionComponent";
import { DataFilteringFooter } from "./components/Footer";
import { useFilterGroupController } from "./hooks/useFilterGroupController";
import { useFilterGroupsNodes } from "./hooks/useFilterGroupNodes";
import { useFilterConjunctions } from "./hooks/useFilterConjunctions";
import { InventoryTableActionButton } from "../../ui/pages/inventory/components/ActionButton";
import { filterGroupValidator } from "../../core/data-filtering/filterGroupsValidator";
import { RenderWithContext } from "../../utils/hocs/RenderWithContext";
import { StateProvider } from "./state-management";
import { useGlobalState } from "./hooks/useGlobalState";

const Component = ({
  filters,
  onApplyFilters,
  columns,
  components = {},
  onOpen,
}) => {
  const { assetSchema } = useGlobalState();

  const [menuOpened, setMenuOpened] = useState(false);

  const conjunctions = useFilterConjunctions();
  const defaultFilterGroupValues = {
    conjunction: conjunctions[0],
    columns,
  };
  const {
    createFilterGroup,
    forceSetData,
    removeFilterGroup,
    reset: resetFilters,
    updateFilterGroup,
    localFilters,
  } = useFilterGroupController(defaultFilterGroupValues);

  const filterGroupNodes = useFilterGroupsNodes({
    data: localFilters,
    conjunctions,
    onDeleteFilterGroups: removeFilterGroup,
    assetSchema,
    updateFilterGroup,
  });

  const handleOnToggleMenu = (ev) => {
    setMenuOpened((p) => !p);
    if (!menuOpened) {
      onOpen?.();
      if (filters) {
        forceSetData(filters);
      }
    }
  };

  const handleOnClose = () => {
    setMenuOpened(false);
  };

  const handleOnClickApplyFilters = () => {
    const { data, errorsFound } = filterGroupValidator(localFilters);
    if (errorsFound) {
      forceSetData(data);
    } else {
      onApplyFilters(localFilters);
      handleOnClose();
    }
  };

  const customFooterOnApplyFilters = () => {
    const { data, errorsFound } = filterGroupValidator(localFilters);
    if (errorsFound) {
      forceSetData(data);
    } else {
      handleOnClose();
      return localFilters;
    }
  };

  return (
    <Fragment>
      <InventoryTableActionButton
        tooltipProps={{
          show: true,
          title: "Filter",
        }}
        actionCount={filters.length}
        ref={FilterTriggerSelectorContainerRef}
        onClick={handleOnToggleMenu}
        icon={<FilterAltIcon />}
      />
      <MenuContainer
        anchorEl={FilterTriggerSelectorContainerRef.current}
        open={menuOpened}
        onClose={handleOnClose}
        anchorReference='anchorEl'
        anchorOrigin={{
          horizontal: "left",
          vertical: 40,
        }}
      >
        <MenuHeader handleOnClose={handleOnClose} />

        <MenuContentContainer className='scrollable'>
          <ConditionComponent condition={!!localFilters.length}>
            {filterGroupNodes}
          </ConditionComponent>
          <ConditionComponent condition={!localFilters.length}>
            <EmptyFilters children={""} />
          </ConditionComponent>
        </MenuContentContainer>

        <ConditionComponent
          condition={!components.Footer}
          falsyNode={
            <components.Footer
              onClickAddFilter={createFilterGroup}
              onClickResetFilters={resetFilters}
              onClickApplyFilters={customFooterOnApplyFilters}
            />
          }
        >
          <DataFilteringFooter
            onClickAddFilter={createFilterGroup}
            onClickResetFilters={resetFilters}
            onClickApplyFilters={handleOnClickApplyFilters}
          />
        </ConditionComponent>
      </MenuContainer>
    </Fragment>
  );
};

export const DataFiltering = RenderWithContext(Component, StateProvider);
