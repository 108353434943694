import { useMaterialReactTable } from "material-react-table";
import { useColumns } from "./useColumns";
import { useGlobalState } from "./useGlobalState";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { checkTableIPaginationIsFirstRender } from "../../../../containers/inventory/AssetsTable/utils/checkTableIPaginationIsFirstRender";
import { usePaginationState } from "./usePaginationState";
import { useSavedQueryAlert } from "./useSavedQueryAlert";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import {
  AlertButton,
  DeleteButton,
  EditButton,
  RunButton,
  TableActionsRoot,
} from "../../../shared-component/TableActionButtons/styled";
import { useDeleteQuery } from "./useDeleteQuery";
import { useEditQuery } from "./useEditQuery";
import { EditQueryButtonRef } from "../ui/refs";
import { useRunQuery } from "../../inventory-queries/hooks/useRunQuery";
import { getQueryTableFromQuery } from "../utils/getQueryTableFromQuery";
import { showErrorAlert } from "../../../../app/alert/showAlert";
import { CMSTTableVariants } from "../../../../app/data/constants";

export function useTable() {
  const { openView } = useSavedQueryAlert();
  const { openView: openDeleteView } = useDeleteQuery();
  const columns = useColumns();
  const { openView: openEditActionView } = useEditQuery();

  const runQuery = useRunQuery();

  const {
    isLoading,
    paginationConfig: pagination,
    data = [],
    meta,
  } = useGlobalState();

  const setPagination = usePaginationState()[1];

  const handleOnPaginationChange = (setter) => {
    const newPagination = setter(pagination);
    const isFirstRender = checkTableIPaginationIsFirstRender(
      pagination,
      newPagination
    );
    if (isFirstRender) return;
    const payload = {
      pagination: newPagination,
    };
    setPagination(newPagination);
    fetch(payload);
  };

  const onClickRow = () => {};

  return useMaterialReactTable({
    columns,
    state: {
      isLoading,
      pagination,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        enableResizing: true,
        size: 200,
        maxSize: 200,
        muiTableHeadCellProps: {
          sx: {
            position: "sticky",
            right: 0,
          },
        },
        muiTableBodyCellProps: {
          sx: {
            position: "sticky",
            right: 0,
          },
        },
      },
    },
    renderRowActions({ row }) {
      const query = row.original;
      const handleOnAdd = () => {
        openView({ query_uuid: query.query_uuid, queryName: query.name });
      };

      const handleOnClickDelete = () => {
        const { query_uuid, name, table_name } = query;
        openDeleteView({ query_uuid, name, table_name });
      };

      const handleOnClickEdit = (ev) => {
        const anchor = ev.target;
        openEditActionView({ ...query, anchor });
      };

      const handleOnRun = () => {
        const table = getQueryTableFromQuery(query);
        if (!table) {
          const errorMessage =
            "Unable to open query. Query table origin not found.  Contact Support";
          return showErrorAlert(errorMessage);
        }
        runQuery({ ...query, table_name: table });
      };

      return (
        <TableActionsRoot>
          <RunButton onClick={handleOnRun}>Run</RunButton>
          <EditButton ref={EditQueryButtonRef} onClick={handleOnClickEdit}>
            Edit
          </EditButton>
          <DeleteButton onClick={handleOnClickDelete}>
            <DeleteRoundedIcon sx={{ fontSize: "18px", color: "#fff" }} />
          </DeleteButton>

          <AlertButton onClick={handleOnAdd}>
            <AddAlertIcon sx={{ fontSize: "18px" }} /> Alert
          </AlertButton>
        </TableActionsRoot>
      );
    },
    data,
    positionActionsColumn: "last",
    onPaginationChange: handleOnPaginationChange,
    rowCount: meta?.total ?? 0,
    enableRowActions: true,
    paginationDisplayMode: "pages",
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableBottomToolbar: true,
    enableTopToolbar: false,
    layoutMode: "grid",
    manualPagination: true,
    muiTableBodyRowProps({ row }) {
      return {
        onClick: (ev) => onClickRow(ev, row),
        sx: {
          cursor: "pointer",
        },
      };
    },
    muiTablePaperProps: {
      sx: {
        flex: 1,
        height: "100%",
      },
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        height: "calc(100% - 72px)",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        height: "40px",
      },
    },
  });
}
