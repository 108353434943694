import { Chip } from "../../../../../shared-component/Chip";
import { CMST_Chip_Variants } from "./data";
import { useIssueTheme } from "../../../hooks/useIssueTheme";
import { useMemo } from "react";
import { CoreDataTypes } from "../../../../../../core/data-types";

export const CMSTChip = ({
  variant = CMST_Chip_Variants.DEFAULT,
  ...props
}) => {
  const {
    palette: { background, text },
  } = useIssueTheme();

  const bgColor = useMemo(() => {
    if (props.label === CoreDataTypes.N_A) return background.default;
    return background[variant];
  }, [props.label, background, variant]);
  const textColor = useMemo(() => {
    if (props.label === CoreDataTypes.N_A) return text.default;
    return text[variant];
  }, [props.label, text, variant]);

  return (
    <Chip size='small' textColor={textColor} bgColor={bgColor} {...props} />
  );
};
